import { Box, Typography } from "@maysoft/common-component-react";

interface ContentBoxProps {
    children: React.ReactNode;
    title: React.ReactNode;
}
const ContentBox = ({ children, title }: ContentBoxProps) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            border="1px solid #d3d3d3"
            borderRadius="4px"
        >
            <Box
                display="flex"
                flexDirection="row"
                gap={1}
                px={2}
                py={1}
                borderBottom={children ? "1px solid #d3d3d3" : undefined}
                alignItems="center"
            >
                {typeof title === "string" ? (
                    <Typography variant="button" component="span">{title}</Typography>
                ) : title}
            </Box>
            {children && (
                <Box display="flex" flexDirection="column" gap={1} px={2} py={1}>
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default ContentBox;