import { Box, Typography } from "@maysoft/common-component-react";
import Error from "@mui/icons-material/Error";

interface ConfirmAllChangesPopupProps {
    note: string;
    renderContent: () => React.ReactNode;
}
const ConfirmAllChangesPopup: React.FC<ConfirmAllChangesPopupProps> = (props) => {
    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {props.renderContent()}
            <Box display="flex" flexDirection="row" alignItems="center">
                <Error color="error" sx={{
                    fontSize: 14
                }} />
                <Typography variant="button" color="error" ml={0.5}>
                    {props.note}
                </Typography>
            </Box> 
        </Box>
    );
}

export default ConfirmAllChangesPopup;