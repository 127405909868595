

import { useEffect, useState } from "react";
import { FormField } from "@maysoft/common-component-react";
import { ModalSearchPartner, PartnerType } from "@maysoft/sale-common-react";

import Strings from "constants/strings";

import { Mode } from "constants/enum";
import { IErrorDetail, IDataDetail } from ".";
import Helpers from "commons/helpers";



interface IProps {
    mode: Mode;
    dataMap?: Map<string, any>;

    data: IDataDetail;
    setData: React.Dispatch<React.SetStateAction<IDataDetail>>

    error: IErrorDetail;
    setError: React.Dispatch<React.SetStateAction<IErrorDetail>>
}

const InputAddSupplier = (props: IProps) => {

    const [openSupplier, setOpenSupplier] = useState(false);
    const [supplierSearchText, setSupplierSearchText] = useState("");

    useEffect(() => {
        if (props.data?.id) {
            const primaryUser = props.dataMap?.get(props.data?.supplierId);
            if (!Helpers.isNullOrEmpty(primaryUser)) {
                const newValue = primaryUser?.phoneNumber ? `${primaryUser?.name} - ${primaryUser?.phoneNumber}` : `${primaryUser?.name}`;
                setSupplierSearchText(newValue);
            }
        }
    }, [props.data?.id]);

    return (
        <>
            <FormField
                mode={props.mode}
                contentEditable={false}
                value={supplierSearchText}
                label={Strings.BOOKING.AGENT}
                placeholder={Strings.BOOKING.SELECT_OR_ADD_AGENT}
                onClick={() => { setOpenSupplier(true); }}
            />

            <ModalSearchPartner
                isSingle
                isAddNewPartner
                open={openSupplier}
                partnerType={PartnerType.Supplier}
                titlePartner={Strings.BOOKING.AGENT}
                partnerIds={props.data?.supplierId ? [props.data?.supplierId] : []}

                onClose={() => { setOpenSupplier(false); }}
                onAction={(data) => {
                    if (!data || !data[0]) {
                        return;
                    } else {
                        const primaryUser = data[0]?.partnerUsers?.find((pu) => pu.primaryUser === 1) || {};
                        setSupplierSearchText(primaryUser?.phoneNumber ? `${primaryUser?.name} - ${primaryUser?.phoneNumber}` : `${primaryUser?.name}`);

                        props.setData(prev => ({ ...prev, supplierId: data[0]?.id }));

                        props.setError(prev => ({ ...prev, supplierId: undefined }));

                        setOpenSupplier(false);
                    }
                }}
            />
        </>
    );
};

export default InputAddSupplier;
