import { Box, Typography } from "@maysoft/common-component-react";
import { BookingHelpers } from "commons/bookingHelpers";
import { ICodename } from "commons/interfaces";
import { IBookingDetailAmendHistorySectionProps, IBookingDetailAmend } from "components/Booking/BookingDetailContainer/interfaces";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import OperationHistorySectionTitle from "../components/OperationHistorySectionTitle";
import OperationHistoryOrderDataText from "../components/OperationHistoryOrderDataText";
import OperationHistoryBookingText from "../components/OperationHistoryBookingText";
import { BookingDetailAmendType } from "constants/enum";

const AdditionServicesAmendHistory = ({ index, details, userMap, createTime, currency, amount, itemCodenames, createUser, orderCode, paymentMethod }: IBookingDetailAmendHistorySectionProps & { itemCodenames: ICodename[] }) => {
    const detailsViewModel = details.reduce((p, detail) => {
        const referenceId = detail.referenceId;
        if (!p[referenceId]) {
            p[referenceId] = {
                referenceBooking: detail.referenceBooking,
                amendDetails: { },
            };
        }
        detail.amendExtraInfoParsed.users.forEach(u => {
            const userId = u.id;
            if (!p[referenceId].amendDetails[userId]) {
                p[referenceId].amendDetails[userId] = [];
            }

            p[detail.referenceId].amendDetails[userId].push(detail);
        });
        return p;
    }, {} as Record<string, {
        referenceBooking: IBookingDetail;
        amendDetails: Record<string, IBookingDetailAmend[]>
    }>);

    const renderTransferFlightItem = (title: string, value: string) => {
        return value && <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="caption" component="span">{title}</Typography>
            <Typography variant="button" component="span" textTransform="none" fontWeight="medium">{value}</Typography>
        </Box>
    }

    return (
        <Box display="flex" flexDirection="column" p={2} gap={1}>
            <OperationHistorySectionTitle
                title="Dịch vụ bổ sung"
                fullName={createUser?.fullName}
                updateTime={createTime}
                index={index}
            />
            <Box px={2} display="flex" flexDirection="column" gap={2}>
                <OperationHistoryOrderDataText
                    currency={currency}
                    amount={amount}
                    paymentMethod={paymentMethod}
                    orderCode={orderCode}
                    amendType={BookingDetailAmendType.AdditionService}
                />

                {Object.values(detailsViewModel).map(({ amendDetails: detailsByUserId, referenceBooking }) => {
                    return Object.entries(detailsByUserId).map(([userId, amendDetails]) => {
                        const { departDate, departPlaceCity, departPlace, arrivalPlaceCity, arrivalPlace, reservationCode } = BookingHelpers.getFlightExtraInfo(referenceBooking, userId);
                        return (
                            <Box display="flex" flexDirection="column" gap={1} key={userId}>
                                <OperationHistoryBookingText 
                                    arrivalPlace={arrivalPlaceCity || arrivalPlace}
                                    departPlace={departPlaceCity || departPlace}
                                    departTime={departDate}
                                    reservationCode={reservationCode}
                                />

                                <Box display="grid" sx={{
                                    gridTemplateColumns: "repeat(5, 1fr)", 
                                    gridTemplateRows: "1fr auto",
                                    maxWidth: "1000px",
                                    "@media (max-width: 900px)": {
                                        gridTemplateColumns: "repeat(3, 1fr)", 
                                    },
                                    "@media (max-width: 600px)": {
                                        gridTemplateColumns: "repeat(2, 1fr)", 
                                    },
                                }} gap={2}>
                                    <Typography variant="button" fontWeight="bold"
                                        sx={{
                                            gridRow: "1/-1",
                                        }} 
                                    >{userMap[userId].fullName}</Typography>  
                                    
                                    {amendDetails.map((amendDetail) => {
                                        return <div key={amendDetail.id}>
                                            {renderTransferFlightItem(itemCodenames.find(c => c.code === amendDetail.itemId)?.name, amendDetail.description)}
                                        </div>
                                    })}
                                </Box>
                            </Box>
                        )
                    })
                })}
            </Box>
        </Box> 
    )
}

export default AdditionServicesAmendHistory;