import moment from "moment-timezone";
import { Card, Grid } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";

import { Gender } from "constants/enum";
import { ICodename } from "commons/interfaces";



const CardListMemberInfo = ({ dataUserProfile }: { dataUserProfile: Map<string, any> }) => {

    const genderList: ICodename[] = [
        { code: Gender.Male, name: Strings.Common.MALE },
        { code: Gender.Female, name: Strings.Common.FEMALE },
    ];

    return (
        <>
            <Card>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{Strings.BOOKING.MEMBER_INFO_TITLE}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                Array.from(dataUserProfile.values()).map((item: any) => (
                                    <Box key={item.id} marginBottom={3}>
                                        <Grid container spacing={1} alignItems="start">
                                            <Grid item xs={12} md={3}>
                                                <Box display="grid">
                                                    <Typography variant="button" fontWeight="bold">
                                                        {item?.fullName || "-"}
                                                    </Typography>
                                                    <Typography variant="caption" color="secondary">
                                                        {Strings.BOOKING.MEMBER_INFO_ADULT}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={9}>
                                                <Grid container spacing={1} alignItems="start">
                                                    <Grid item xs={6} sm={4} md={4}>
                                                        <Box display="grid">
                                                            <Typography variant="caption" color="secondary">
                                                                {Strings.Common.DATE_OF_BIRTH}
                                                            </Typography>
                                                            <Typography variant="button">
                                                                {moment(Helpers.getDateValue(item?.dateOfBirth)).format("DD/MM/YYYY")}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} md={4}>
                                                        <Box display="grid">
                                                            <Typography variant="caption" color="secondary">
                                                                {Strings.Common.GENDER}
                                                            </Typography>
                                                            <Typography variant="button">
                                                                {genderList.find(el => el.code === item?.gender)?.name}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} md={4}>
                                                        <Box display="grid">
                                                            <Typography variant="caption" color="secondary">
                                                                {Strings.BOOKING.MEMBER_INFO_NATIONALITY}
                                                            </Typography>
                                                            <Typography variant="button">
                                                                {item?.nationality}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} md={4}>
                                                        <Box display="grid">
                                                            <Typography variant="caption" color="secondary">
                                                                {Strings.BOOKING.MEMBER_INFO_PASSPORT}
                                                            </Typography>
                                                            <Typography variant="button">
                                                                {item?.passportNo}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} md={4}>
                                                        <Box display="grid">
                                                            <Typography variant="caption" color="secondary">
                                                                {Strings.BOOKING.MEMBER_INFO_EXPIRED_AT}
                                                            </Typography>
                                                            <Typography variant="button">
                                                                {moment(Helpers.getDateValue(item?.passportExpiredDate)).format("DD/MM/YYYY")}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sm={4} md={4}>
                                                        <Box display="grid">
                                                            <Typography variant="caption" color="secondary">
                                                                {Strings.BOOKING.MEMBER_INFO_ISSUED_AT}
                                                            </Typography>
                                                            <Typography variant="button">
                                                                {item?.passportIssuedPlace}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </>
    );
};

export default CardListMemberInfo;