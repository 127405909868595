import { useMemo } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import { PaymentStatus } from "constants/enum";
import { ICodename } from "commons/interfaces";



const CardPaymentOrderBooking = (props: {
    amount: number,
    currency: string,
    serviceFee: number,
    paymentFee: number,
    paymentMethod: number,
    paymentStatus: number,
    serviceFeeTax: number,
    paymentTypeList: ICodename[]
}) => {

    const isRefunded = useMemo(() => (
        [PaymentStatus.Refunded, PaymentStatus.WaitingRefund].includes(props?.paymentStatus)
    ), [props?.paymentStatus]);

    return (
        <Box sx={{
            gap: 1,
            display: "grid",
            padding: "16px",
            borderRadius: "16px",
            border: "1.5px solid #F0F0F0",
        }}>
            <Typography variant="h6">
                {"Thông tin thanh toán"}
            </Typography>
            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Tổng tiền vé:"}
                    </Typography>
                }
                value={
                    <Typography variant="button" color={isRefunded ? "error" : "dark"}>
                        {isRefunded ? "- " : ""}
                        {`${Helpers.formatCurrency((props?.amount || 0) - (props?.serviceFee || 0) - (props?.paymentFee || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Phí dịch vụ:"}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency((props?.serviceFee || 0) - (props?.serviceFeeTax || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Thuế (phí dịch vụ):"}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency(props?.serviceFeeTax || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`Tổng cộng:`}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold" color={isRefunded ? "error" : "dark"}>
                        {isRefunded ? "- " : ""}
                        {`${Helpers.formatCurrency((props?.amount || 0) - (props?.paymentFee || 0))} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Phí tiện ích:"}
                    </Typography>
                }
                value={
                    <Typography variant="button">
                        {`${Helpers.formatCurrency(props?.paymentFee || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {"Tổng thanh toán:"}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold">
                        {`${Helpers.formatCurrency(props?.amount || 0)} `}
                        {props?.currency}
                    </Typography>
                }
            />

            <BoxTitleValue
                title={
                    <Typography variant="button">
                        {`Phương thức thanh toán:`}
                    </Typography>
                }
                value={
                    <Typography variant="button" fontWeight="bold">
                        {props?.paymentTypeList.find(el => el.code === props?.paymentMethod)?.name || "-"}
                    </Typography>
                }
            />
        </Box>
    )
};

export default CardPaymentOrderBooking;

const BoxTitleValue = (props: {
    title: JSX.Element,
    value: JSX.Element,
}) => (
    <Box sx={{
        gap: 1,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "end",
        justifyContent: "space-between",
    }}>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.title}
        </Box>
        <Box sx={{ gap: 1, display: "grid" }}>
            {props.value}
        </Box>
    </Box>
)