import { Divider } from "@mui/material";
import { Box } from "@maysoft/common-component-react";
import { ICodename } from "commons/interfaces";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailPopupUser, IService } from "../../../interfaces";
import UserAccordionForAdditionalServices from "../UserAccordionForAdditionalServices";
import Strings from "constants/strings";

const EditModeFlightBookingDetailAdditionalServicesPopup = ({
    renderSummary,
    renderPaymentContent,
    onAddService,
    onRemoveService,
    onUpdateService,
    users,
    errorsByUserId,
    serviceCodenames,
    servicesByUserIdMap = { },
}: {
    renderSummary: () => React.ReactNode;
    renderPaymentContent: ({ title }: { title: string }) => React.ReactNode;
    onAddService?: (user: IBookingDetailPopupUser, detail: IBookingDetail) => void;
    onRemoveService?: (service: IService, user: IBookingDetailPopupUser, detail: IBookingDetail) => void;
    onUpdateService?: (newService: IService, keyChanged: keyof IService) => void;
    users: IBookingDetailPopupUser[];
    errorsByUserId: Partial<Record<string, Partial<Record<string, string>>>>;
    serviceCodenames: ICodename[];
    servicesByUserIdMap: Record<string, IService[]>;
}) => {
    return (
        <Box display="flex" gap={2} flexDirection="column">
            {users.map((user) => {
                return (
                    <UserAccordionForAdditionalServices
                        key={user.id}
                        data={user}
                        errors={errorsByUserId[user.id]}
                        services={servicesByUserIdMap[user.id]}
                        serviceCodenames={serviceCodenames}
                        onAddService={onAddService}
                        onRemoveService={onRemoveService}
                        onUpdateService={onUpdateService}
                    />
                );
            })}

            {renderPaymentContent({ title: Strings.Common.PAYMENT_TYPE })}

            <Divider />
            {renderSummary()}
        </Box>
    )
}

export default EditModeFlightBookingDetailAdditionalServicesPopup;