import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { FlightTakeoff } from "@mui/icons-material";
import { Box, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import CardTimeLineFlight from "./cardTimeLineFlight";
import useDataRequestBooking, { IBookingDetail, IBookingDetailFlightExtraInfo } from "../../useDataRequestBooking.hook";
import { BookingHelpers } from "commons/bookingHelpers";

const CardItemFlightInfo = ({
    data,
    dataUserProfile,
    isShowReservationCode,
}: {
    data: IBookingDetail,
    isShowReservationCode?: boolean,
    dataUserProfile: Map<string, any>,
}) => {
    const {
        language,
    } = useCommonComponentContext();

    const {
        getDateNameFormat,
    } = useDataRequestBooking();

    const [reservationCode, setReservationCode] = useState("");
    const [ETickets, setETickets] = useState<{ pax_id: number, ticket_number: number, pax_number: number }[]>([]);

    const extraInfo: IBookingDetailFlightExtraInfo | undefined = useMemo(() => BookingHelpers.getBookingDetailsWithExtraInfoParsed([data])[0].extraInfoParsed, [data]);

    const valueHandBaggage = useMemo(() => {
        const item = extraInfo?.FreeBaggage?.find(el => el.IsHandBaggage);
        if (item) {
            return `${item.Quantity} kiện ${item.Name}`;
        } else {
            return "-"
        }
    }, [extraInfo?.FreeBaggage]);

    const valueSignedBaggage = useMemo(() => {
        const item = extraInfo?.FreeBaggage?.find(el => !el.IsHandBaggage);
        if (item) {
            return `${item.Quantity} kiện ${item.Name}`;
        } else {
            return "-"
        }
    }, [extraInfo?.FreeBaggage]);

    const valueNumber = useMemo(() => isShowReservationCode ? 3 : 4, [isShowReservationCode]);

    useEffect(() => {
        if (
            extraInfo &&
            !Helpers.isNullOrEmpty(extraInfo?.IssueTicketInfo)
        ) {
            const issueTicketInfo = Helpers.converStringToJson(extraInfo.IssueTicketInfo);

            const newTemp = issueTicketInfo?.Booking?.Itineraries?.[0];

            !Helpers.isNullOrEmpty(newTemp?.e_tickets) &&
                setETickets(newTemp?.e_tickets || []);

            !Helpers.isNullOrEmpty(newTemp?.reservation_code) &&
                setReservationCode(newTemp?.reservation_code);
        }
    }, [extraInfo, extraInfo?.IssueTicketInfo])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Box sx={{
                    borderRadius: "4px",
                    padding: "12px 16px",
                    backgroundColor: "#EBF0FF",
                }}>
                    <Box sx={{
                        gap: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "end",
                    }}>
                        <Typography variant="h5">
                            {`${extraInfo?.DepartPlaceObj?.City} ${extraInfo?.DepartPlaceObj?.Country}`}
                        </Typography>
                        <FlightTakeoff />
                        <Typography variant="h5">
                            {`${extraInfo?.ArrivalPlaceObj?.City} ${extraInfo?.ArrivalPlaceObj?.Country}`}
                        </Typography>
                    </Box>

                    <Typography variant="button" fontWeight="bold">
                        {`${getDateNameFormat(extraInfo?.DepartDate * 1000)}`}
                    </Typography>

                    {isShowReservationCode &&
                        <Box>
                            <Box sx={{
                                padding: "4px 12px",
                                borderRadius: "8px",
                                display: "inline-flex",
                                backgroundColor: "#FFFFFF",
                            }}>
                                <Typography variant="button">
                                    {"Mã đặt chỗ: "}
                                    <Typography variant="button" fontWeight="bold">
                                        {reservationCode}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} >
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "end",
                }}>
                    <Box
                        component={"img"}
                        src={extraInfo?.CarrierMarketingObj?.LogoUrl}
                        sx={{
                            width: "21px",
                            height: "21px",
                            borderRadius: "50%",
                            objectFit: "contain",
                            border: "1px #c3c3c3 solid"
                        }}
                    ></Box>
                    <Typography variant="button">{(extraInfo?.CarrierMarketingObj?.Name as any)?.[language]}</Typography>
                    <Typography variant="button">{" - "}</Typography>
                    <Typography variant="button">
                        {[extraInfo?.CarrierMarketingObj?.Code, extraInfo?.FlightNumber].join("")}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <CardTimeLineFlight
                    data={extraInfo}
                    language={language}
                />
            </Grid>

            <Grid item xs={12}>
                {
                    Array.from(dataUserProfile.values()).map((item: any, index: number) => (
                        <Box marginBottom={3} key={item.id}>
                            <Grid container spacing={1} alignItems="start">
                                <Grid item xs={12} md={3}>
                                    <Box display="grid">
                                        <Typography variant="button" fontWeight="bold">
                                            {item.fullName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={1} alignItems="start">
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {"Hành lý xách tay"}
                                                </Typography>
                                                <Typography variant="button">
                                                    {valueHandBaggage}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {"Hành lý ký gửi"}
                                                </Typography>
                                                <Typography variant="button">
                                                    {valueSignedBaggage}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {"Số ghế"}
                                                </Typography>
                                                <Typography variant="button">
                                                    {"-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {isShowReservationCode &&
                                            <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                                <Box display="grid">
                                                    <Typography variant="caption" color="secondary">
                                                        {"Số vé"}
                                                    </Typography>
                                                    <Typography variant="button">
                                                        {ETickets[index]?.ticket_number || "-"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
            </Grid>
        </Grid>
    );
};

export default CardItemFlightInfo;