import moment from "moment";
import { useMemo, useState } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import useDataOrderBooking from "../useDataOrderBooking.hook";

import { IOrderDetailExtends } from ".";
import { OrderType, PaymentStatus } from "constants/enum";
import { IOrderDetail } from "services/sale/order.service";



const CardItemOrderBooking = (props: {
    currency: string;
    orderType: number;
    paymentStatus: number;
    organizationId: string;
    orderDetails: IOrderDetail[];
    dataMapExtraInfoBooking: Map<string, string>;
    dataMapOrderDetail: Map<string, IOrderDetailExtends>;
}) => {
    const isRefunded = useMemo(() => (
        [PaymentStatus.Refunded, PaymentStatus.WaitingRefund].includes(props?.paymentStatus)
    ), [props?.paymentStatus]);

    const renderItemRow = (item: IOrderDetail, index: number) => {
        let totalAmount = (item?.amount || 0);

        if (props?.orderType !== OrderType.AdditionService) {
            totalAmount = totalAmount + (item?.fee || 0);
        };

        const itemOrderDetailParent = props.dataMapOrderDetail.get(item.referenceId);

        return (
            <Box key={item.id} sx={{
                gap: 1,
                display: "grid",
                alignItems: "end",
                gridTemplateColumns: "1fr 50px 150px",
            }}>
                <RenderFlightInfo
                    index={index}
                    orderType={props?.orderType}
                    organizationId={props.organizationId}
                    extraInformation={item?.extraInformation}

                    orderTypeParent={itemOrderDetailParent?.orderType}
                    extraInformationParent={itemOrderDetailParent?.extraInformation}

                    extraInfoBooking={props.dataMapExtraInfoBooking.get(item?.externalId)}
                    extraInfoBookingParent={props.dataMapExtraInfoBooking.get(itemOrderDetailParent?.externalId)}
                />
                <Typography variant="button">
                    {`x ${Helpers.formatCurrency(item?.quantity || 0)}`}
                </Typography>
                <Box textAlign={"end"}>
                    <Typography variant="button" color={isRefunded ? "error" : "dark"}>
                        {`${isRefunded ? "-" : ""} ${Helpers.formatCurrency(totalAmount)} ${item?.currency || props.currency}`}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{
            gap: 1,
            display: "grid",
            padding: "16px",
            borderRadius: "16px",
            border: "1.5px solid #F0F0F0",
        }}>
            <Box gap={0.5} display={"grid"}>
                <Typography variant="h6">
                    {"Thông tin đơn hàng"}
                </Typography>
            </Box>
            <Box gap={2} marginTop={1} display={"grid"}>
                {[...props?.orderDetails || []].map((item, index) => (renderItemRow(item, index)))}
            </Box>
        </Box>
    );
};

export default CardItemOrderBooking;

const getTextDateTimeFlight = (valueTime: any) => {
    const day = Strings.Common.DAY.toLocaleLowerCase();
    const time = moment(Helpers.getDateValue(valueTime)).format("HH:mm");
    const date = moment(Helpers.getDateValue(valueTime)).format("DD/MM/YYYY");

    return `${time} ${day} ${date}`;
};

const getDataFlightInfo = (type: number, extraInfo: any) => {
    if (type === OrderType.Transfer) {
        const flight = extraInfo?.users?.[0]?.flight;

        return {
            cabinClass: flight?.cabinClass || "",
            departTime: flight?.departTime,
            arrivalTime: flight?.arrivalTime,
            carrierName: flight?.airlineObj?.name || "",
            reservationCode: flight?.reservationCode || "",
            departPlaceCode: flight?.departPlaceObj?.code || "",
            arrivalPlaceCode: flight?.arrivalPlaceObj?.code || "",
        };
    } else {
        return {
            reservationCode: "",
            departTime: extraInfo?.DepartDate,
            arrivalTime: extraInfo?.ArrivalDate,
            cabinClass: extraInfo?.CabinClass || "",
            departPlaceCode: extraInfo?.DepartPlaceObj?.Code || "",
            arrivalPlaceCode: extraInfo?.ArrivalPlaceObj?.Code || "",
            carrierName: extraInfo?.CarrierMarketingObj?.Name?.["vi"] || "",
        };
    };
};

const RenderFlightInfo = ({
    index,
    orderType,
    organizationId,
    orderTypeParent,
    extraInformation,
    extraInfoBooking,
    extraInformationParent,
    extraInfoBookingParent,
}: {
    index: number,
    orderType: number,
    organizationId: string,
    orderTypeParent: number,
    extraInformation: string,
    extraInfoBooking: string,
    extraInformationParent?: string,
    extraInfoBookingParent?: string,
}) => {
    const { getDataMapUserByIds } = useDataOrderBooking();

    const [ticketNumber, setTicketNumber] = useState("");
    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());

    const dataExtraInfo = Helpers.converStringToJson(extraInformation);
    const dataExtraInfoParent = Helpers.converStringToJson(extraInformationParent);

    const dataFlightInfo = getDataFlightInfo(orderType, dataExtraInfo);
    const dataFlightParentInfo = getDataFlightInfo(orderTypeParent, dataExtraInfoParent);

    useMemo(() => {
        if (orderType === OrderType.PartialRefund) {

            (async () => {
                let newDataUser = new Map();

                newDataUser = await getDataMapUserByIds({
                    orgId: organizationId,
                    newDataUser: newDataUser,
                    ids: [...dataExtraInfo?.users || []].map(el => el.id),
                });

                setDataMapUser(newDataUser);
            })()

            const temp = Helpers.converStringToJson(extraInfoBookingParent);

            if (orderTypeParent === OrderType.Transfer) {
                setTicketNumber(temp?.users?.[0]?.flight?.ticketNumber);
            } else {
                const leg = temp?.Leg;
                const issueTicketInfo = Helpers.converStringToJson(temp?.IssueTicketInfo);
                const itineraries = [...issueTicketInfo?.Booking?.Itineraries || []];
                const itemItinerarieByLeg = itineraries.find(el => el.leg === leg);
                const eTicket = itemItinerarieByLeg?.e_tickets?.[0];

                setTicketNumber(eTicket?.ticket_number || "");
            }
        } else {
            setTicketNumber("");
        }
    }, [orderType, orderTypeParent, extraInfoBookingParent]);

    // let textHandBaggage = "";
    // let textSignedBaggage = "";

    // const valueHandBaggage = dataExtraInfo?.FreeBaggage?.find((el: any) => el.IsHandBaggage)?.Name;
    // if (!Helpers.isNullOrEmpty(valueHandBaggage)) {
    //     textHandBaggage = `${"Hành lý xách tay"}: ${valueHandBaggage}`;
    // }

    // const valueSignedBaggage = dataExtraInfo?.FreeBaggage?.find((el: any) => !el.IsHandBaggage)?.Name;
    // if (!Helpers.isNullOrEmpty(valueSignedBaggage)) {
    //     textSignedBaggage = `${"Hành lý ký gửi"}: ${valueSignedBaggage}`;
    // };

    const BoxDefault = () => (
        <Box gap={0.5} display={"grid"}>
            <Typography variant="button" sx={{ marginBottom: 0.5 }}>
                {"Mã đặt chỗ: "}
                {dataFlightInfo?.reservationCode}
            </Typography>

            <Typography variant="button">
                {`Chuyến bay ${dataFlightInfo?.departPlaceCode} - ${dataFlightInfo?.arrivalPlaceCode}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{`Hãng ${dataFlightInfo?.carrierName}`}
                &nbsp;-&nbsp;{`Hạng ${dataFlightInfo?.cabinClass}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{"Khởi hành - Hạ cánh:"}&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.departTime)}
                &ensp;-&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.arrivalTime)}
            </Typography>

            {/* {textHandBaggage &&
                    <Typography variant="button">
                        -&nbsp;{textHandBaggage}
                    </Typography>
                }
                {textSignedBaggage &&
                    <Typography variant="button">
                        -&nbsp;{textSignedBaggage}
                    </Typography>
                } */}
        </Box>
    );

    const BoxAdditionService = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {"Dịch vụ bổ sung"}
                    </Typography>
                    <Typography variant="button">
                        {"Mã đặt chỗ: "}
                        {dataFlightParentInfo?.reservationCode}
                    </Typography>
                </Box>
            }
            <Typography variant="button">
                {`Chuyến bay ${dataFlightParentInfo?.departPlaceCode} - ${dataFlightParentInfo?.arrivalPlaceCode}`}
            </Typography>
            <Typography variant="button">
                &ensp;&#8226;&ensp;{dataExtraInfo?.itemName?.["vi"]}
            </Typography>
        </Box>
    );

    const BoxPartialRefund = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {"Hoàn vé"}
                    </Typography>
                    <Typography variant="button">
                        {"Mã đặt chỗ: "}
                        {dataFlightParentInfo?.reservationCode}
                    </Typography>
                </Box>
            }
            <Typography variant="button">
                {`${index + 1}. ${dataMapUser.get(dataExtraInfo?.users?.[0]?.id)}`}
            </Typography>
            <Typography variant="button">
                {`Chuyến bay ${dataFlightParentInfo?.departPlaceCode} - ${dataFlightParentInfo?.arrivalPlaceCode}`}
                &ensp;&#8226;&ensp;
                <Typography variant="button" fontWeight="bold">
                    {"Số vé: "}
                    <Typography variant="button">{ticketNumber}</Typography>
                </Typography>
            </Typography>
        </Box>
    );

    const BoxTransfer = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {"Thay đổi chuyến bay"}
                    </Typography>
                    <Typography variant="button">
                        {"Mã đặt chỗ: "}
                        {dataFlightInfo?.reservationCode}
                    </Typography>
                </Box>
            }

            <Typography variant="button">
                {"Chuyến bay mới: "}
                {`Chuyến bay ${dataFlightInfo?.departPlaceCode} - ${dataFlightInfo?.arrivalPlaceCode}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{`Hãng ${dataFlightInfo?.carrierName}`}
                &nbsp;-&nbsp;{`Hạng ${dataFlightInfo?.cabinClass}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{"Khởi hành - Hạ cánh:"}&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.departTime)}
                &ensp;-&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.arrivalTime)}
            </Typography>
        </Box>
    );

    switch (orderType) {
        case OrderType.AdditionService:
            return <BoxAdditionService />;
        case OrderType.PartialRefund:
            return <BoxPartialRefund />;
        case OrderType.Transfer:
            return <BoxTransfer />;
        default:
            return <BoxDefault />;
    };
};