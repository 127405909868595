import { PaymentType } from "constants/enum";

const usePaymentMethodCodenames = () => {
    return [
        {
            code: PaymentType.Debt,
            name: "Công nợ",
        },
        {
            code: PaymentType.Cash,
            name: "Trực tiếp",
        },
    ];
};


export default usePaymentMethodCodenames;