import { HiddenFeeListContainer } from "@maysoft/sale-common-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Constants from "constants/index";
import Screens from "constants/screens";
import useModule from "hooks/useModule";

import { DashboardLayout } from "layout";

const HiddenFeeScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.HIDDEN_FEE_LIST,
        },
        keyOfStrings: "HIDDEN_FEE",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <HiddenFeeListContainer {...moduleProps} />
        </DashboardLayout>
    );
};

export default HiddenFeeScreen;
