import { Box } from "@maysoft/common-component-react";
import DeeptechAccordion from "../../components/DeeptechAccordion";
import TextWithInputPrice from "../../components/TextWithInputPrice";
import TextWithPrice from "../../components/TextWithPrice";
import TransferFlightItem from "../../components/TransferFlightPopupItem";
import { IBookingDetailPopupUser, ITransferBookingDetailViewModel, ITransferBookingDetailSubOrderViewModel } from "components/Booking/BookingDetailContainer/interfaces";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { ICodename } from "commons/interfaces";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";

interface IEditModeFlightBookingDetailTransferFlightPopupProps {
    data: IDetailBooking;
    handleChangeValue: (newTransferRequest: ITransferBookingDetailViewModel, key: keyof ITransferBookingDetailViewModel) => void;

    users: IBookingDetailPopupUser[];
    airlines: ICodename[];
    cabinClassCodenames: ICodename[];

    amount: number;
    currency: string;
    totalFee: number;
    transferRequests: ITransferBookingDetailViewModel[];
    transferRequestErrors: Record<string, {
        [key: string]: string;
    }>;

    subOrderData: ITransferBookingDetailSubOrderViewModel;
    subOrderErrors: Record<string, string>;
    setSubOrderData:  React.Dispatch<React.SetStateAction<ITransferBookingDetailSubOrderViewModel>>;

    isLoadingServiceFee: boolean;
    renderPaymentContent: (props: { title: string }) => JSX.Element;
}

const EditModeFlightBookingDetailTransferFlightPopup = ({
    data,
    handleChangeValue,

    users,
    airlines,
    cabinClassCodenames,

    isLoadingServiceFee,
    renderPaymentContent,

    subOrderData,
    setSubOrderData,
    subOrderErrors,

    amount,
    currency,
    totalFee,
    transferRequests,
    transferRequestErrors,
}: IEditModeFlightBookingDetailTransferFlightPopupProps) => {
    const { getBookingDetailsByUserId } = useAddMoreBookingServiceContext();
    return <Box display="flex" flexDirection="column" gap={2}>
            {users.map((user) => (
                <DeeptechAccordion
                    key={user.id}
                    data={user}
                    renderContent={() => {
                        return (
                            <>
                                {getBookingDetailsByUserId(user.id).map((bookingDetailItem) => {
                                    const data = transferRequests.find(x => x.bookingDetailId === bookingDetailItem.id && x.userId === user.id);
                                    const error = transferRequestErrors?.[data?._id];
                                    return <TransferFlightItem
                                        key={`${user.id}_${bookingDetailItem.id}`}
                                        data={data}
                                        onChange={handleChangeValue}
                                        user={user}
                                        error={error}
                                        airlines={airlines}
                                        detail={bookingDetailItem}
                                        cabinClasses={cabinClassCodenames}
                                    />
                                })}
                            </>
                        );
                    }}
                />
            ))}
            {renderPaymentContent({ title: Strings.Common.PAYMENT_TYPE })}

            <Box display="flex" flexDirection="column" gap={1}>
                <TextWithInputPrice
                    label={Strings.BOOKING.TRANSFER_POPUP_FLIGHT_CHANGE_FEE}
                    placeholder={Strings.BOOKING.TRANSFER_POPUP_FLIGHT_CHANGE_FEE_PLACEHOLDER}
                    currency={currency}
                    titleFontWeight="bold"
                    onChangeValue={(value) => setSubOrderData(d => ({...d, transferFee: value}))}
                    value={subOrderData.transferFee}
                    errorMessage={subOrderErrors?.transferFee}
                />
                <TextWithInputPrice
                    label={Strings.BOOKING.TRANSFER_POPUP_FARE_DIFFERENT}
                    placeholder={Strings.BOOKING.TRANSFER_POPUP_FARE_DIFFERENT_PLACEHOLDER}
                    currency={currency}
                    titleFontWeight="bold"
                    onChangeValue={(value) => setSubOrderData(d => ({...d, differentAmount: value}))}
                    value={subOrderData.differentAmount}
                    errorMessage={subOrderErrors?.differentAmount}
                />
                <TextWithInputPrice
                    label={Strings.BOOKING.TRANSFER_POPUP_NOSHOW_FEE}
                    placeholder={Strings.BOOKING.TRANSFER_POPUP_NOSHOW_FEE_PLACEHOLDER}
                    currency={currency}
                    titleFontWeight="bold"
                    onChangeValue={(value) => setSubOrderData(d => ({...d, noshowFee: value}))}
                    value={subOrderData.noshowFee}
                    errorMessage={subOrderErrors?.noshowFee}
                />
                <TextWithPrice
                    text={Strings.Common.SERVICE_FEE}
                    currency={currency}
                    titleFontWeight="bold"
                    price={totalFee}
                    loading={isLoadingServiceFee}
                />
                <TextWithPrice
                    fontWeight="medium"
                    text={Strings.BOOKING.POPUP_TOTAL}
                    currency={currency}
                    color="warning"
                    price={amount}
                    loading={isLoadingServiceFee}
                />
            </Box>
        </Box>
}

export default EditModeFlightBookingDetailTransferFlightPopup;