import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "constants/index";
import Screens from "constants/screens";
import RequestBookingModule from "components/Booking";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setListPathName } from "store/slice/titleRoute.slice";
import { ItineraryType, Mode, SupplierCode } from "constants/enum";



const HotelBookingScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const amend = searchParams.get("amend");
    const isAmendAction = parseInt(amend) === 1;

    const idDetail: string = searchParams.get("id");
    const modeList = (Screens.HOTEL_BOOKING_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemP = listPathName.find(el => el.pathName === Screens.HOTEL_BOOKING_LIST);

    const [model, setModel] = useState<{ mode: Mode; title: string; route: any[] }>({
        mode: Mode.View,
        title: Strings.BOOKING.TITLE_DETAIL,
        route: [
            { title: Strings.BOOKING.TITLE_MENU_HOTEL, route: Screens.HOTEL_BOOKING_LIST },
            { title: Strings.Common.DETAIL, route: "" },
        ],
    });

    const [requestData] = useState<any>({
        totalCount: itemP?.totalCount,
        buyer: searchParams.get("buyer"),
        seller: searchParams.get("seller"),
        searchText: searchParams.get("searchText"),
        confirmStatus: searchParams.get("confirmStatus"),
        type: Helpers.isNullOrEmpty(searchParams.get("type")) ? undefined : Number(searchParams.get("type")),

        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    useEffect(() => {
        if (modeList) {
            return;
        } else {
            if (Helpers.isNullOrEmpty(idDetail)) {
                handleChangeMode(Mode.Create);
            } else {
                handleChangeMode(pramsMode || Mode.Update);
            }
        }
    }, [modeList, pramsMode, idDetail]);

    const handleGoBack = () => {
        if (isAmendAction) {
            navigate(Screens.AMEND_BOOKING_LIST);
            return;
        }
        const itemP = listPathName.find((el) => el.pathName === Screens.HOTEL_BOOKING_LIST);
        navigate(Screens.HOTEL_BOOKING_LIST + (itemP ? itemP.query : ""));
    };

    const handleChangeMode = (valueMode: Mode) => {
        if (valueMode === Mode.Create) {
            setModel({
                mode: Mode.View,
                title: Strings.BOOKING.TITLE_CREATE,
                route: [
                    { title: Strings.BOOKING.TITLE_MENU_HOTEL, route: Screens.HOTEL_BOOKING_LIST },
                    { title: Strings.Common.ADD_NEW, route: "" },
                ],
            });
            navigate(Screens.HOTEL_BOOKING_CREATE, { replace: true });
        }
        if (valueMode === Mode.Update) {
            setModel({
                mode: Mode.Update,
                title: Strings.BOOKING.TITLE_UPDATE,
                route: [
                    { title: Strings.BOOKING.TITLE_MENU_HOTEL, route: Screens.HOTEL_BOOKING_LIST },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            });
            navigate(Screens.HOTEL_BOOKING_EDIT + `?id=${idDetail}&mode=${pramsMode}`, { replace: true });
        }
        if (valueMode === Mode.View) {
            setModel({
                mode: Mode.View,
                title: Strings.BOOKING.TITLE_DETAIL,
                route: [
                    { title: Strings.BOOKING.TITLE_MENU_HOTEL, route: Screens.HOTEL_BOOKING_LIST },
                    { title: Strings.Common.DETAIL, route: "" },
                ],
            });
            navigate(Screens.HOTEL_BOOKING_VIEW + `?id=${idDetail}&mode=${pramsMode}`, { replace: true });
        }
    }

    return (
        <DashboardLayout
            isPermission={true}
            title={modeList ? Strings.BOOKING.TITLE_LIST : model.title}
            route={modeList ? [{ title: Strings.BOOKING.TITLE_MENU_HOTEL, route: "" }] : model.route}
        >

            <RequestBookingModule
                keyModuleContainer={{
                    list: { pathName: Screens.HOTEL_BOOKING_LIST },
                    detail: { pathName: Screens.HOTEL_BOOKING_VIEW },
                    update: { pathName: Screens.HOTEL_BOOKING_EDIT },
                    create: { pathName: Screens.HOTEL_BOOKING_CREATE },
                }}

                hidenActionCreate
                hidenActionUpdate

                type={ItineraryType.Hotel}
                supplierCode={SupplierCode.Deeptech}

                requestGetPaged={requestData}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.HOTEL_BOOKING_LIST, query, totalCount }));
                    navigate(Screens.HOTEL_BOOKING_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    if (data.id) {
                        if (data.mode === Mode.View) {
                            navigate(Screens.HOTEL_BOOKING_VIEW + `?id=${data?.id}&mode=${data.mode}`)
                        } else {
                            navigate(Screens.HOTEL_BOOKING_EDIT + `?id=${data?.id}&mode=${data.mode}`)
                        }
                    } else {
                        navigate(Screens.HOTEL_BOOKING_CREATE)
                    }
                }}

                mode={model.mode}
                idDetail={idDetail}
                onGoBack={() => handleGoBack()}
                onChangeMode={(mode: Mode) => { handleChangeMode(mode) }}
            />
        </DashboardLayout>
    );
};

export default HotelBookingScreen;
