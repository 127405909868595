import moment from "moment";
import { useMemo } from "react";
import { Grid, } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import useDataOrderBooking from "../useDataOrderBooking.hook";



const CardBasicOrderBooking = (props: {
    orderCode: string;
    orderDate: string;
    bookingCode: string;
    paymentStatus: number;
    orderDetailType: number;
}) => {
    const {
        listOrderDetailType,
        getDataPaymentStatusByOrderBooking,
    } = useDataOrderBooking();

    const newOrderDate = useMemo(() => (Helpers.getDateValue(props.orderDate)), [props.orderDate]);
    const newStatus = useMemo(() => (getDataPaymentStatusByOrderBooking(props?.paymentStatus)), [props.paymentStatus, getDataPaymentStatusByOrderBooking]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={7}>
                    <Box gap={1} display={"grid"}>
                        <Typography variant="button" fontWeight="bold">
                            {"Mã đơn hàng: "}
                            {props?.orderCode || "-"}
                        </Typography>
                        <Typography variant="button">
                            {"Dịch vụ: "}
                            {listOrderDetailType?.find(el => el.code === props.orderDetailType)?.name || "-"}
                        </Typography>
                        {!Helpers.isNullOrEmpty(props.bookingCode) &&
                            <Typography variant="button">
                                {"Booking Code: "}
                                {props.bookingCode || ""}
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={5}>
                    <Box gap={1} display={"grid"}>
                        <Typography variant="button">
                            {"Thời gian tạo: "}
                            {newOrderDate ? moment(newOrderDate).format("HH:mm:ss DD/MM/YYYY") : "-"}
                        </Typography>
                        <Typography variant="button">
                            {"Trạng thái: "}
                            <Typography variant="button" color={newStatus.color as any}>
                                {newStatus?.title || "-"}
                            </Typography>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
};

export default CardBasicOrderBooking;