import { IGetPaged, IServiceFee } from "commons/interfaces";
import Constants from "../../constants";
import SaleService from "./sale.service";
import { PaymentType } from "constants/enum";

export interface IRequestGetPagedServiceFeeSetting {
    organizationId: string;
    searchText?: string;
    startTime?: number;
    targetType?: number;
    pageNumber: number;
    pageSize: number;
    orderBy?: string;
    totalCount?: number;
}

export interface IRequestUpdateServiceFeeSetting {
    serviceCode: string;
    organizationId: string;
    description: string;
    targetType: number;
    targetId?: string;
    startTime: number;
    endTime: number;
    serviceFeeCriteriaRequest: IServiceFeeCriteriaRequest[];
    id: string;
}

export interface IServiceFeeCriteriaRequest {
    settingId: string;
    criteriaType: number;
    minimumExpense: number;
    minimumQuantity: number;
    amount: number;
    quantity: number;
}

export type IRequestCreateServiceFeeSetting = Omit<IRequestUpdateServiceFeeSetting, "id">
export interface ICalculateServiceFeeRequest {
  organizationId: string
  bookingDetailRequest: ICalculateServiceFeeBookingDetailRequest[]
  paymentMethod: PaymentType;
}
export interface ICalculateServiceFeeBookingDetailRequest {
    bookingId: string;
    bookingDetailId: string
    itemId?: string
    quantity: number
    amount: number
    unitPrice: number
    feeCode?: string
    extraInformation?: string
    isInternational: boolean;
}
export interface ICalculateServiceFeeResponse {
    bookingId: string
    bookingDetailId: string
    itemId: string
    quantity: number
    amount: number
    unitPrice: number
    feeCode: string
    fee: number; // already include feeTax
    feeTax: number;
}
class ServiceFeeSetting extends SaleService {
    public getPaged = async (data: IRequestGetPagedServiceFeeSetting): Promise<IGetPaged<IServiceFee>> => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.SERVICE_FEE_SETTING.GET_PAGED}`,
            query: data,
        });
        return result.data.result;
    };
    public create = async (data: IRequestCreateServiceFeeSetting) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.SERVICE_FEE_SETTING.CREATE}`,
            data,
        });
        return result.data.result;
    };
    public update = async (data: IRequestUpdateServiceFeeSetting) => {
        const result = await this.api.put({
            path: `${Constants.ApiPath.SERVICE_FEE_SETTING.UPDATE}`,
            data,
        });
        return result.data.result;
    };
    public calculateServiceFeeExtra = async (data: ICalculateServiceFeeRequest): Promise<ICalculateServiceFeeResponse[]> => {
        const result = await this.api.post({
            path: Constants.ApiPath.SERVICE_FEE_SETTING.CALCULATE_SERVICE_FEE_EXTRA,
            data,
        })
        return result.data.result;
    }
}

export default ServiceFeeSetting;
