import { Box, FormField, Typography } from "@maysoft/common-component-react";

const TextWithInputPrice = ({
    label,
    placeholder,
    errorMessage,
    value = 0,
    loading = false,
    currency,
    onChangeValue,
    titleFontWeight,
    showDivider,
}: {
    label: string;
    placeholder?: string;
    errorMessage?: string;
    value?: number;
    loading?: boolean;
    currency: string;
    titleFontWeight?: "regular" | "medium" | "bold";
    showDivider?: boolean;
    onChangeValue: (value: number) => void;
}) => {
    return (
        <Box
            display="grid"
            gap={3}
            gridTemplateColumns="1fr clamp(200px, 60%, 300px)"
            flexWrap="wrap"
        >
            <Typography variant="button">{label}</Typography>
            <FormField
                variant="outlined"
                fullWidth={true}
                placeholder={placeholder}
                isMoney
                unit={currency}
                sx={{ ml: "auto" }}
                value={value}
                disabled={loading}
                error={!!errorMessage}
                errorMessage={errorMessage}
                onChangeValue={(value: string) =>
                    onChangeValue(value ? parseInt(value) : 0)
                }
            />
        </Box>
    );
};

export default TextWithInputPrice;
