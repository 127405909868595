import { Search } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { Card, CircularProgress, Grid } from "@mui/material";
import { Box, FormField, Modal, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import CardItemRoom from "./CardItemRoom";
import useDataRequestBooking from "../useDataRequestBooking.hook";
import { IRequestGetDetail, IRoom } from "services/booking/propertyContentService";

import { IDataDetail } from ".";
import { Mode } from "constants/enum";



const CardListItemRoom = ({
    mode,
    dataDetail,
    dataOccupancyRoom,

    dataSelectRooms,
    setDataSelectRooms,
}: {
    mode: Mode;
    dataOccupancyRoom: any;
    dataDetail: IDataDetail;

    dataSelectRooms: IRoom[];
    setDataSelectRooms: React.Dispatch<React.SetStateAction<IRoom[]>>;
}) => {

    const {
        language,
    } = useCommonComponentContext();

    return (
        <Card>
            <Box p={2}>
                <Typography variant="h5">{"Thông tin phòng"}</Typography>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <ModalAddRoom
                                isSingle
                                disabled={mode === Mode.View}
                                selectIds={dataSelectRooms?.map(el => el.referenceId)}

                                dataRequest={{
                                    language: language === "vi" ? "vi-VN" : "en-EN",
                                    occupancy: dataOccupancyRoom || [],
                                    propertyId: dataDetail?.externalId,
                                    checkinTime: Number(dataDetail?.startTime || 0),
                                    checkoutTime: Number(dataDetail?.endTime || 0),
                                }}

                                onClose={() => { }}
                                onAction={(newData) => {
                                    setDataSelectRooms(newData);
                                }}
                            />
                        </Grid>
                        {([...dataSelectRooms || []].length > 0)
                            ? [...dataSelectRooms || []].map(item => (
                                <Grid item xs={12}>
                                    <CardItemRoom
                                        disable
                                        isSelected
                                        item={item}
                                        toDate={Number(dataDetail?.endTime || 0)}
                                        fromDate={Number(dataDetail?.startTime || 0)}
                                        onReserve={(newItem) => { }}
                                    />
                                </Grid>
                            ))
                            : (
                                <Grid mt={1} item xs={12} md={12} textAlign="center">
                                    <Typography variant="button" color="secondary" >
                                        {"Chưa có phòng"}
                                    </Typography>
                                </Grid>
                            )}
                    </Grid>
                </Box>
            </Box>
        </Card>
    );
};

export default CardListItemRoom;

export const ModalAddRoom = ({
    isSingle,
    disabled,
    selectIds,
    dataRequest,

    onClose,
    onAction,
}: {
    disabled?: boolean;
    isSingle?: boolean;
    selectIds?: string[];
    dataRequest: IRequestGetDetail;

    onClose: () => void;
    onAction: (data: IRoom[]) => void;
}) => {
    const height = "70vh";

    const { onError } = useCommonComponentContext();
    const { getDataRoomByManufacturer } = useDataRequestBooking();

    const [visibled, setVisibled] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [dataRows, setDataRows] = useState<IRoom[]>([]);
    const [dataSelect, setDataSelect] = useState<IRoom[]>([]);

    const textButtonAction = useMemo(() => {
        if (isSingle) {
            return Strings.Common.SELECT;
        } else {
            return `${Strings.Common.SELECT} ${(dataSelect.length > 0) ? dataSelect.length : ""}`;
        }
    }, [isSingle, dataSelect.length]);

    useEffect(() => {
        if (visibled) {
            getListRoomByManufacturer(dataRequest)
        }
    }, [visibled, dataRequest]);

    const getListRoomByManufacturer = async (req: IRequestGetDetail) => {
        try {
            setLoading(true);

            const result = await getDataRoomByManufacturer(req, selectIds);

            setDataRows(result.listRooms);
            setDataSelect(result.listRoomsSelected);

        } catch (error) {
            Helpers.handleException(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectedById = (item: IRoom) => {
        let newData = [...dataSelect || []];

        const indexTemp = dataSelect?.findIndex(el => el.referenceId === item.referenceId);

        if (isSingle) {
            if (indexTemp === -1) {
                newData = [item];
            } else {
                newData = [];
            }
        } else {

            if (indexTemp === -1) {
                newData.push(item);
            } else {
                newData.splice(indexTemp, 1);
            }
        }

        setDataSelect(newData);

        onAction(newData);
        setVisibled(false);
    }

    return (
        <>
            <FormField
                value=""
                focused={false}
                variant="outlined"
                disabled={disabled}
                placeholder={"Chọn phòng để đặt"}
                onClick={() => {
                    let check = true;
                    if (Helpers.isNullOrEmpty(dataRequest?.propertyId)) {
                        check = false;
                        onError("Vui lòng chọn khách sạn", "warning");
                    }
                    if (Helpers.isNullOrEmpty(dataRequest?.checkinTime)) {
                        check = false;
                        onError("Vui lòng chọn thời gian nhận phòng", "warning");
                    }
                    if (Helpers.isNullOrEmpty(dataRequest?.checkoutTime)) {
                        check = false;
                        onError("Vui lòng chọn thời gian trả phòng", "warning");
                    }
                    if (dataRequest?.occupancy?.length === 0) {
                        check = false;
                        onError("Vui lòng chọn số lượng người", "warning");
                    }

                    if (check) {
                        setVisibled(true);
                    }
                }}
                InputProps={{ startAdornment: <Search color="secondary" /> }}
            />

            <>
                <Modal
                    fullWidth
                    maxWidth="lg"
                    visible={visibled}
                    hasActionButton={false}
                    buttonAction={textButtonAction}
                    title="Danh sách phòng của khách sạn"
                    onClose={() => {
                        onClose();
                        setVisibled(false);
                    }}
                    onClickCloseIcon={() => {
                        onClose();
                        setVisibled(false);
                    }}
                    onAction={() => {
                        onAction(dataSelect);
                        setVisibled(false);
                    }}
                >
                    <Box sx={{
                        height: height,
                        overflow: "auto",
                    }}>
                        {loading
                            ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: height ? height : "auto",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )
                            : ((dataRows.length === 0)
                                ? (
                                    <Box sx={{ textAlign: "center" }}>
                                        <Typography variant="button" color="secondary">
                                            {Strings.Common.NO_DATA}
                                        </Typography>
                                    </Box>
                                )
                                : (
                                    <Grid container spacing={2}>
                                        {
                                            dataRows?.map((item, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <CardItemRoom
                                                        item={item}
                                                        toDate={Number(dataRequest?.checkoutTime || 0)}
                                                        fromDate={Number(dataRequest?.checkinTime || 0)}

                                                        isSelected={selectIds.includes(item.referenceId)}

                                                        onReserve={(newItem) => {
                                                            handleSelectedById(newItem);
                                                        }}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                )
                            )
                        }
                    </Box>
                </Modal>
            </>
        </>
    );
};
