import Helpers from "commons/helpers";
import Strings from "constants/strings";
import UserService from "services/identity/user.service";
import useDataRequestBooking from "components/Booking/useDataRequestBooking.hook";

import { ICodename } from "commons/interfaces";
import { ConfirmStatus, OrderDetailType, OrderStatus, PaymentStatus, PaymentType, Status } from "constants/enum";
import moment from "moment";



const useDataOrderBooking = () => {

    const { paymentStatusList } = useDataRequestBooking();

    const listOrderDetailType: ICodename[] = [
        { code: OrderDetailType.Hotel, name: "Khách sạn", },
        { code: OrderDetailType.Flight, name: "Chuyến bay", },
    ];

    const listOrderStatus: ICodename[] = [
        { code: OrderStatus.Cancel, name: "Đã hủy", },
        { code: OrderStatus.Pending, name: "Chờ xử lý", },
        { code: OrderStatus.New, name: "Mới", },
        { code: OrderStatus.Confirm, name: "Đã xác nhận", },
        { code: OrderStatus.Editing, name: "Đã chỉnh sửa", },
        { code: OrderStatus.PaymentConfirm, name: "Đã thanh toán", },
        { code: OrderStatus.DebtConfirm, name: "Xác nhận nợ", },
        { code: OrderStatus.ExchangeRequest, name: "Yêu cầu đổi trả", },
        { code: OrderStatus.ExchangeCancel, name: "Hủy bỏ đổi trả", },
        { code: OrderStatus.Completed, name: "Hoàn thành", },
    ];

    const tempFilter = [...paymentStatusList || []].filter(el => (
        (el.code !== PaymentStatus.Issued)
        && (el.code !== PaymentStatus.WaitingConfirm)
    ));
    const paymentStatusListExtend: ICodename[] = [
        ...tempFilter || [],
        {
            code: PaymentStatus.Completed,
            name: Strings.STATUS.PAYMENT.Completed,
        },
    ];

    const paymentTypeList: ICodename[] = [
        {
            code: PaymentType.Cash,
            name: "Tiền mặt",
        },
        {
            code: PaymentType.Banking,
            name: "Chuyển khoảng",
        },
        {
            code: PaymentType.Paypal,
            name: "Paypal",
        },
        {
            code: PaymentType.VnPay,
            name: "VnPay",
        },
        {
            code: PaymentType.Mobile,
            name: "Mobile",
        },
        {
            code: PaymentType.Debt,
            name: "Quỹ xuất vé",
        },
        {
            code: PaymentType.NinePayCollection,
            name: "QR code",
        },
        {
            code: PaymentType.NinePayATM,
            name: "Thẻ nội địa",
        },
        {
            code: PaymentType.NinePayCredit,
            name: "Thẻ quốc tế tại VN",
        },
        {
            code: PaymentType.NinePayCreditInternational,
            name: "Thẻ quốc tế ngoài VN",
        },
    ];

    // Trạng thái thanh toán cho api Order_Booking
    const getDataPaymentStatusByOrderBooking = (paymentStatus: number) => {
        let newData: any = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };

        switch (paymentStatus) {
            case PaymentStatus.Cancel:
                newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
                break;

            case PaymentStatus.Pending:
                newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                break;
            case PaymentStatus.New:
                newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                break;
            case PaymentStatus.WaitingConfirm:
                newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                break;

            case PaymentStatus.Completed:
                newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                break;

            case PaymentStatus.Failed:
                newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Failed };
                break;

            case PaymentStatus.Issued:
                newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                break;

            case PaymentStatus.WaitingRefund:
                newData = { icon: "currency_exchange", color: "info", title: Strings.STATUS.PAYMENT.WaitingRefund };
                break;

            case PaymentStatus.RefundProcessing:
                newData = { icon: "currency_exchange", color: "info", title: Strings.STATUS.PAYMENT.WaitingRefund };
                break;

            case PaymentStatus.Refunded:
                newData = { icon: "currency_exchange", color: "error", title: Strings.STATUS.PAYMENT.Refunded };
                break;

            default:
                newData = { icon: "", color: "secondary", title: "Không xác định" };
                break;
        };

        return newData;
    };

    // Trạng thái thanh toán cho api Booking
    const getDataPaymentStatusByBooking = ({
        status,
        paymentStatus,
        confirmStatus,
        lastTicketDate,
        reservationCode,
        itineraryDetailConfirmStatus,
    }: {
        status?: number,
        paymentStatus: number,
        confirmStatus?: number,
        lastTicketDate?: number,
        reservationCode?: string,
        itineraryDetailConfirmStatus: number,
    }) => {
        let newData: any = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };

        if ((paymentStatus === PaymentStatus.Completed) && (confirmStatus === ConfirmStatus.Completed)) {
            newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
        } else if ((paymentStatus === PaymentStatus.Completed) && Helpers.isNullOrEmpty(reservationCode)) {
            newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
        } else if (
            (
                status === Status.Cancel &&
                (
                    paymentStatus === PaymentStatus.New ||
                    paymentStatus === PaymentStatus.Processing
                )
            )
            || itineraryDetailConfirmStatus === ConfirmStatus.Rejected
        ) {
            newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
        } else if (paymentStatus === PaymentStatus.New && (lastTicketDate < moment().unix())) {
            newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
        } else {
            switch (paymentStatus) {
                case PaymentStatus.Cancel:
                    newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
                    break;

                case PaymentStatus.Pending:
                    newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                    break;
                case PaymentStatus.New:
                    newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                    break;
                case PaymentStatus.WaitingConfirm:
                    newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                    break;

                case PaymentStatus.Completed:
                    newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                    break;

                case PaymentStatus.Failed:
                    newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Failed };
                    break;

                case PaymentStatus.Issued:
                    newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                    break;

                case PaymentStatus.WaitingRefund:
                    newData = { icon: "currency_exchange", color: "info", title: Strings.STATUS.PAYMENT.WaitingRefund };
                    break;

                case PaymentStatus.RefundProcessing:
                    newData = { icon: "currency_exchange", color: "info", title: Strings.STATUS.PAYMENT.WaitingRefund };
                    break;

                case PaymentStatus.Refunded:
                    newData = { icon: "currency_exchange", color: "error", title: Strings.STATUS.PAYMENT.Refunded };
                    break;

                default:
                    newData = { icon: "", color: "secondary", title: "Không xác định" };
                    break;
            };
        }

        return newData;
    };

    const getDataMapUserByIds = async ({ ids, orgId, newDataUser }: {
        ids: string[], orgId: string, newDataUser: Map<string, string>,
    }) => {
        try {

            const newIds = ids.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);

            const result = await new UserService().getPaged(Helpers.handleFormatParams({
                pageNumber: 1,
                pageSize: newIds.length,
                selectedIds: newIds,
                organizationId: orgId,
            }));

            [...result.selectedItems || []].forEach(el => {
                let fullName = el.fullName || el.userName;

                if (!Helpers.isNullOrEmpty(el.organizationUserProfile?.firstName)
                    || !Helpers.isNullOrEmpty(el.organizationUserProfile?.lastName)) {
                    fullName = `${el.organizationUserProfile?.lastName || ""} ${el.organizationUserProfile?.firstName || ""}`;
                };

                newDataUser.set(el.id, fullName);
            });

            return newDataUser;

        } catch (error) { return newDataUser; }
    };

    return {
        paymentTypeList,
        listOrderStatus,
        listOrderDetailType,
        paymentStatusListExtend,
        getDataMapUserByIds,
        getDataPaymentStatusByBooking,
        getDataPaymentStatusByOrderBooking,
    };
};

export default useDataOrderBooking;