import { KeyModuleScreen } from "@maysoft/common-component-react";
import { useEffect, useState } from "react";

import RequestBookingDetailContainer from "./BookingDetailContainer";
import AmendBookingListScreen from "./Amend/list";
import { ItineraryType } from "constants/enum";


const AmendBookingModule = (props: {
    title?: string;
    idDetail?: string;

    onGoBack: () => void;
    onNavigate: (data?: {
        id?: string;
        mode?: number;
    }) => void;

    keyModuleContainer: {
        [key in KeyModuleScreen]?: {
            pathName: string;
        };
    };
}) => {
    const currentPath = window.location.pathname || "";

    const [idDetail, setIdDetail] = useState<string | undefined>(undefined);

    useEffect(() => { setIdDetail(props.idDetail); }, [props.idDetail]);

    const renderDetailComponent = () => {
        if (!idDetail) return null;
        return <RequestBookingDetailContainer
            idDetail={idDetail}
            onGoBack={() => {
                setIdDetail(undefined);
                props.onGoBack();
            }}
        />;
    } 
    const listContainers: { [key in KeyModuleScreen]?: JSX.Element; } = {
        "list": (
            <AmendBookingListScreen
                onNavigate={(data) => {
                    setIdDetail(data.id);
                    props.onNavigate(data);
                }}
            />
        ),
        "detail": renderDetailComponent(),
    };

    const renderContainerView = () => {
        const itemFind = Object.keys(props.keyModuleContainer).find((key) => (
            (props.keyModuleContainer as any)[key]?.pathName === currentPath)
        );
        return (listContainers as any)[itemFind];
    };

    return <>{renderContainerView()}</>;
};

export default AmendBookingModule;
