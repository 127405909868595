import { useMemo } from "react";
import { Box, Button, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import { IRoom } from "services/booking/propertyContentService";
import useDataRequestBooking from "components/Booking/useDataRequestBooking.hook";



const BoxSummaryPrice = ({
    item,
    onReserve,
    numberOfNights,
    hidenButtonBooking,
}: {
    item: IRoom;
    numberOfNights: number;
    hidenButtonBooking?: boolean;
    onReserve: (item: IRoom) => void;
}) => {

    const { getPriceTotalByKey } = useDataRequestBooking();

    const rate = useMemo(() => (
        item.rates?.find((el) => el.referenceId === item.selectedRate)
    ), [item.rates, item?.selectedRate]);

    const exclusive = useMemo(() => getPriceTotalByKey(rate?.prices?.[0], "exclusive"), [rate]);
    const inclusive = useMemo(() => getPriceTotalByKey(rate?.prices?.[0], "inclusive"), [rate]);
    const strikethrough = useMemo(() => getPriceTotalByKey(rate?.prices?.[0], "strikethrough"), [rate]);
    const inclusiveStrikethrough = useMemo(() => getPriceTotalByKey(rate?.prices?.[0], "inclusiveStrikethrough"), [rate]);

    const percent = useMemo(() => 100 - (exclusive?.value / strikethrough?.value) * 100, [exclusive?.value, strikethrough?.value]);
    const exclusivePerNight = useMemo(() => Math.round(exclusive?.value / numberOfNights), [exclusive?.value, numberOfNights]);
    const strikethroughPerNight = useMemo(() => Math.round(strikethrough?.value / numberOfNights), [strikethrough?.value, numberOfNights]);

    if (Helpers.isNullOrEmpty(rate) || (item.rates?.length === 0))
        return (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <Typography variant="button" fontWeight="bold" color="error">
                    {"Hết phòng trống"}
                </Typography>
            </Box>
        );

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{
                gap: 1,
                width: {
                    xs: "100%",
                    sm: "100%",
                    md: "50%",
                    lg: "50%",
                    xl: "50%",
                    xxl: "50%",
                }
            }} >
                {percent > 0 &&
                    <Typography sx={{
                        py: "4px",
                        px: "12px",
                        fontWeight: 600,
                        borderRadius: 6,
                        color: "#ffffff",
                        fontSize: "1rem",
                        width: "max-content",
                        backgroundColor: "#4CAF50"
                    }} >
                        {`${Math.round(percent)}% off`}
                    </Typography>
                }

                <Box gap={1} display="flex" alignItems="center">
                    {!Helpers.isNullOrEmpty(exclusive) && (
                        <Typography variant="button" fontWeight="bold">
                            {`${exclusive?.currency} ${Helpers.formatCurrency(exclusivePerNight)}`}
                        </Typography>
                    )}
                    {!Helpers.isNullOrEmpty(strikethrough) && strikethrough?.value !== 0 && (
                        <Typography variant="button" sx={{ textDecoration: "line-through" }} >
                            {`${strikethrough?.currency} ${Helpers.formatCurrency(strikethroughPerNight)}`}
                        </Typography>
                    )}
                </Box>

                {!Helpers.isNullOrEmpty(inclusive) && (
                    <Box gap={1} display="flex" alignItems="center">
                        <Typography variant="button">
                            {`${inclusive.currency} ${Helpers.formatCurrency(inclusive.value)}`}
                        </Typography>
                        <Typography variant="button" color="secondary">
                            {"(Đã bao gồm thuế và phí)"}
                        </Typography>
                    </Box>
                )}
            </Box>
            {!hidenButtonBooking &&
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: {
                        xs: "100%",
                        sm: "100%",
                        md: "50%",
                        lg: "50%",
                        xl: "50%",
                        xxl: "50%",
                    }
                }} >
                    <Typography color="error" variant="button" fontWeight="bold">
                        {`Còn lại ${rate?.available || 0} phòng`}
                    </Typography>
                    <Button
                        color="info"
                        variant="outlined"
                        onClick={(e) => {
                            e.stopPropagation();
                            onReserve(item);
                        }}
                    >
                        {"Đặt phòng"}
                    </Button>
                </Box>
            }
        </Box>
    );
};

export default BoxSummaryPrice;