import moment from "moment";
import React, { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { Icon, SvgIconTypeMap, Tooltip } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Box, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import BoxRefundable from "./boxRefundable";
import BoxSummaryPrice from "./boxSummaryPrice";

import { IMultiLanguageContent } from "commons/interfaces";
import { IAttribute, IRoom } from "services/booking/propertyContentService";



export enum AttributeCodes {
    "partner_booking_category" = "partner_booking_category",
    "partner_booking_rank" = "partner_booking_rank",
    "partner_booking_business_model_expedia_collect" = "partner_booking_business_model_expedia_collect", // Thanh toán ngay lúc đặt hàng
    "partner_booking_business_model_property_collect" = "partner_booking_business_model_property_collect", // Thanh toán tại khách sạn
    "partner_booking_ratings_property_rating" = "partner_booking_ratings_property_rating", // Đánh giá (sao),
    "partner_booking_checkin_begin_time" = "partner_booking_checkin_begin_time", // Thời gian bắt đầu
    "partner_booking_checkin_end_time" = "partner_booking_checkin_end_time", // Thời gian kêt thúc checkin
    "partner_booking_checkin_instructions" = "partner_booking_checkin_instructions", // Hướng dẫn nhận phòng
    "partner_booking_checkin_special_instructions" = "partner_booking_checkin_special_instructions", //Hướng dẫn đặc biệt khi nhận phòng
    "partner_booking_checkin_min_age" = "partner_booking_checkin_min_age", // Độ tuổi tối thiểu để nhận phòng
    "partner_booking_checkout_time" = "partner_booking_checkout_time", // Thời gian trả phòng
    "partner_booking_fee_mandatory" = "partner_booking_fee_mandatory", // Phí bắt buộc
    "partner_booking_fee_optional" = "partner_booking_fee_optional", // Phí tùy chọn
    "partner_booking_policies_know_before_go" = "partner_booking_policies_know_before_go", // Điều bạn cần biết trước khi đặt phòng (policy)
    "partner_booking_attribute_pets" = "partner_booking_attribute_pets", // Các quy định về thú cưng
    "partner_booking_attribute_general" = "partner_booking_attribute_general", // Quy định chung
    "partner_booking_amenities_property" = "partner_booking_amenities_property", // Tiện nghi chung
    "partner_booking_onsite_payment_currency" = "partner_booking_onsite_payment_currency", // Đơn vị tiền tệ thanh toán
    "partner_booking_onsite_payment_type" = "partner_booking_onsite_payment_type", // Phương thức thanh toán trực tiếp
    "partner_booking_description_dining" = "partner_booking_description_dining", // Mô tả phòng ăn,
    "partner_booking_description_room" = "partner_booking_description_room", // Mô tả tiện ích phòng ở,
    "partner_booking_description_attraction" = "partner_booking_description_attraction", // Các điểm than quan lân cận
    "partner_booking_description_location" = "partner_booking_description_location", // Vị trí
    "partner_booking_description_amenity" = "partner_booking_description_amenity", // Tiện ích chung
    "partner_booking_description_renovation" = "partner_booking_description_renovation", // Cải tạo gần đây
    "partner_booking_description_national_rating" = "partner_booking_description_national_rating", // Xếp hạng quốc gia
    "partner_booking_description_business_amenities" = "partner_booking_description_business_amenities", // Tiện tích dành riêng
    "partner_booking_description_headline" = "partner_booking_description_headline", // Mô tả tóm tắt
    "partner_booking_description_general" = "partner_booking_description_general", // Mô tả chung
    "partner_booking_statistics" = "partner_booking_statistics", // Thống kê
    "partner_booking_iata_airport_code" = "partner_booking_iata_airport_code", // Nã IATA của sân bay
    "partner_booking_partner_booking_themes" = "partner_booking_partner_booking_themes", // Chủ đề
    "partner_booking_all_inclusive_all_rate_plans" = "partner_booking_all_inclusive_all_rate_plans", // Tất cả loại giá đều cung cấp dịch vụ trọn gói
    "partner_booking_all_inclusive_some_rate_plans" = "partner_booking_all_inclusive_some_rate_plans", // Một số loại giá có cung cấp dịch vụ trọn gói
    "partner_booking_all_inclusive_details" = "partner_booking_all_inclusive_details", // Thông tin chi tiết về tiện nghi và dịch vụ trong giá trọn gói
    "partner_booking_spoken_languages" = "partner_booking_spoken_languages", // Ngôn ngữ
    "partner_booking_partner_booking_multi_unit" = "partner_booking_partner_booking_multi_unit", // Nhiều chi nhánh (khác chủ),

    "product_booking_description_overview" = "product_booking_description_overview", // Mô tả tổng quan
    "product_booking_amenities_rooms" = "product_booking_amenities_rooms", // Tiện ích phòng
    "product_booking_bed_groups" = "product_booking_bed_groups", // Loại giường
    "product_booking_occupancy_max_allowed_total" = "product_booking_occupancy_max_allowed_total", // Số người tối đa
    "product_booking_occupancy_max_allowed_children" = "product_booking_occupancy_max_allowed_children", // Tối đa số trẻ em
    "product_booking_occupancy_max_allowed_adults" = "product_booking_occupancy_max_allowed_adults", // Tối đa số người lớn
    "product_booking_occupancy_age_categories_Adult" = "product_booking_occupancy_age_categories_Adult", // Tuổi người lớn
    "product_booking_occupancy_age_categories_ChildAgeA" = "product_booking_occupancy_age_categories_ChildAgeA", // Tuổi trẻ em
    "product_booking_occupancy_age_categories_Infant" = "product_booking_occupancy_age_categories_Infant", // Tuổi trẻ sơ sinh
    "product_booking_area_square_meters" = "product_booking_area_square_meters", // Diện tích phòng tính theo mét vuông
    "product_booking_area_square_feet" = "product_booking_area_square_feet", //Diện tích phòng tính theo thước vuông
    "product_booking_room_views" = "product_booking_room_views", // Hướng phòng

    "partner_booking_ratings_guess_count" = "partner_booking_ratings_guess_count", // số lượng khách đánh giá
    "partner_booking_ratings_guess_overall" = "partner_booking_ratings_guess_overall", // Đánh giá tổng quan
    "partner_booking_ratings_guess_cleanliness" = "partner_booking_ratings_guess_cleanliness", // Đánh giá mức độ sạch sẽ
    "partner_booking_ratings_guess_service" = "partner_booking_ratings_guess_service", // Đánh giá chất lượng dịch vụ
    "partner_booking_ratings_guess_comfort" = "partner_booking_ratings_guess_comfort", // Đánh giá mức độ thoải mái
    "partner_booking_ratings_guess_condition" = "partner_booking_ratings_guess_condition", // Đánh giá cơ sở vật chất
    "partner_booking_ratings_guess_location" = "partner_booking_ratings_guess_location", // Đánh giá vị trí
    "partner_booking_ratings_guess_neighborhood" = "partner_booking_ratings_guess_neighborhood", // Đánh giá khu vực
    "partner_booking_ratings_guess_amenities" = "partner_booking_ratings_guess_amenities", // Đánh giá tiện nghi
    "partner_booking_ratings_guess_recommendation" = "partner_booking_ratings_guess_recommendation", // Tỷ lệ gợi ý
};

export type IAttributeByRoom = {
    iconName: string;
    value: IMultiLanguageContent;
    Icon: OverridableComponent<SvgIconTypeMap>;
};

export interface IOptionalAttributes {
    wifi: boolean;
    parking: boolean;
    reserve: boolean;
};

interface IProps {
    item: IRoom;
    toDate?: number;
    disable?: boolean;
    fromDate?: number;
    isSelected?: boolean;
    optionalAttributes?: IOptionalAttributes;
    onReserve?: (item: IRoom) => void;
}

const CardItemRoom: React.FC<IProps> = ({
    item,
    toDate,
    fromDate,
    disable,
    isSelected,
    optionalAttributes,
    onReserve,
}: IProps) => {

    const {
        language,
    } = useCommonComponentContext();

    const numberOfNights = useMemo(() => (
        Number(moment(Number(toDate || 0) * 1000).diff(Number(fromDate || 0) * 1000, "days") || 1)
    ),
        [toDate, fromDate]
    );

    const getAttributeValue = (attributes: IAttribute[], attributeCode: AttributeCodes) => {
        return (attributes || []).find((attribute) => attribute.attributeCode === attributeCode)?.attributeValue || "";
    };

    const attributes = useMemo(() => {
        const result = [
            {
                iconName: "aspect_ratio",
                value: (language === "vi")
                    ? `${getAttributeValue(item.attributes, AttributeCodes.product_booking_area_square_meters)} mét vuông`
                    : `${getAttributeValue(item.attributes, AttributeCodes.product_booking_area_square_feet)} sq ft`,
            },
            {
                iconName: "people",
                value: `${getAttributeValue(item.attributes, AttributeCodes.product_booking_occupancy_max_allowed_total)} khách`,
            },
            {
                iconName: "hotel",
                value: getAttributeValue(item.attributes, AttributeCodes.product_booking_bed_groups),
            },
        ];

        if (optionalAttributes?.reserve) {
            result.splice(0, 0, {
                iconName: "check",
                value: "Đặt trước, thanh toán sau",
            });
        }

        if (optionalAttributes?.wifi) {
            result.splice(1, 0, {
                iconName: "wifi",
                value: "Wifi miễn phí",
            });
        }

        if (optionalAttributes?.parking) {
            result.splice(1, 0, {
                iconName: "local_parking",
                value: "Bãi đỗ xe tự phục vụ miễn phí",
            });
        }

        if (getAttributeValue(item.attributes, AttributeCodes.product_booking_room_views)) {
            result.splice(1, 0, {
                iconName: "location_city",
                value: getAttributeValue(item.attributes, AttributeCodes.product_booking_room_views),
            });
        }

        return result;
    }, [item, language, optionalAttributes]);

    return (
        <Box sx={(theme: Theme) => {
            const { functions: { rgba }, palette: { info } } = theme;
            return {
                display: "flex",
                filter: "brightness(1)",
                flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                    xxl: "row",
                },
                borderRadius: 2,
                transition: ".3s ease-in",
                border: "1px solid #c3c3c3",
                backgroundColor: isSelected ? rgba(info.main, 0.1) : "transparent",
                "&:hover": {
                    cursor: (disable || (item.rates?.length === 0)) ? "default" : "pointer",
                    border: `1px solid ${(disable || (item.rates?.length === 0)) ? "#c3c3c3" : info.main}`,
                    backgroundColor: (disable || (item.rates?.length === 0)) ? "transparent" : rgba(info.main, 0.1),
                },
            }
        }}>
            {/* IMG Room */}
            <Box sx={(theme: Theme) => {
                return {
                    p: 2,
                    width: {
                        xs: "100%",
                        sm: "100%",
                        md: "35%",
                        lg: "35%",
                        xl: "35%",
                        xxl: "35%",
                    },
                    "& img": {
                        width: "100%",
                        height: {
                            xs: "255px",
                            sm: "255px",
                            md: "100%",
                            lg: "100%",
                            xl: "100%",
                            xxl: "100%",
                        },
                        borderRadius: 2,
                        objectFit: "cover",
                        transition: "transform 0.3s ease-in-out",
                    },
                }
            }}>
                <img
                    src={item.photos[0]?.photoUrl}
                />
            </Box>

            <Box sx={{
                p: 2,
                width: {
                    xs: "100%",
                    sm: "100%",
                    md: "65%",
                    lg: "65%",
                    xl: "65%",
                    xxl: "65%",
                },
                display: "flex",
                flexDirection: "column",
            }}>
                <Box sx={{ gap: 1 }}>
                    {/* Type Name */}
                    <Tooltip arrow title={item.name || ""}>
                        <Typography sx={{
                            overflow: "hidden",
                            display: "-webkit-box !important",
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: "1",
                            fontSize: "1.4rem",
                            fontWeight: "600",
                            width: "90%",
                        }}>{item.name}</Typography>
                    </Tooltip>
                    {/* Time Booking */}
                    {!Helpers.isNullOrEmpty(toDate) && !Helpers.isNullOrEmpty(fromDate) && (
                        <Typography variant="button" color="secondary">
                            {
                                [
                                    Helpers.formatDate(Number(fromDate) * 1000),
                                    Helpers.formatDate(Number(toDate) * 1000)
                                ].join(" - ")
                            }
                        </Typography>
                    )}

                    {/* Characteristic Attribute */}
                    {(attributes || []).length >= 1 && (
                        <Box gap={1}>
                            {attributes?.map((att, index) => (
                                <Box sx={{
                                    gap: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}>
                                    <Icon>{att.iconName}</Icon>
                                    <Typography variant="button" >{att.value}</Typography>
                                </Box>
                            ))}
                        </Box>
                    )}
                    <BoxRefundable item={item} />
                </Box>
                <Box sx={{
                    width: "100%",
                    marginTop: "8px",
                    paddingTop: "8px",
                    borderTop: "1px #c3c3c3 solid",
                }}>
                    <BoxSummaryPrice
                        item={item}
                        numberOfNights={numberOfNights}
                        onReserve={(value) => onReserve && onReserve(value)}
                        hidenButtonBooking={disable || (item.rates?.length === 0)}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default CardItemRoom;



