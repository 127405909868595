import { Box, Modal, Typography } from "@maysoft/common-component-react";
import { useCallback, useMemo, useState } from "react";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailPopupUser } from "../interfaces";
import ConfirmModeFlightBookingDetailRefundPopupContent from "./containers/Refund/ConfirmModeFlightBookingDetailRefundPopupContent";
import EditModeFlightBookingDetailRefundPopupContent from "./containers/Refund/EditModeFlightBookingDetailRefundPopupContent";
import useLoadBookingDetailServiceFee from "./hooks/useLoadServiceFee";
import useBookingDetailSubmit, { IUseBookingDetailPopupSubmitProps } from "./hooks/useBookingDetailSubmit";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import Strings from "constants/strings";
import { PaymentType } from "constants/enum";

interface BookingDetailRefundPopupProps extends Pick<IUseBookingDetailPopupSubmitProps, "onCompleted"> {
    visible: boolean;
    onClose: () => void;
}
const BookingDetailRefundPopup = (props: BookingDetailRefundPopupProps) => {
    const [mode, setMode] = useState<"edit" | "confirm">("edit");
    const { currency, data, getBookingDetailsByUserId, isUserBookingDetailRefunded, users } = useAddMoreBookingServiceContext();

    const { loading: isSubmitLoading, onRefundSubmit, renderLoadingContent, renderPaymentContent, commonProperties: { paymentType } } = useBookingDetailSubmit({ onCompleted: props.onCompleted, data });
    const { totalFee, loading: isLoadingServiceFee, serviceFeeByBookingDetailId, checked, onCalculateServiceFeeForRefund, onRecalculateServiceFeeByPaymentMethod } = useLoadBookingDetailServiceFee(data);
    const [toConfirmStepError, setToConfirmStepError] = useState<string | null>(null);
    const [amount, setAmount] = useState<number>(0);
    const refundAmount = amount - totalFee;

    const checkedGroupByUserId = useMemo(() => {
        return checked.reduce((prev, c) => {
            if (!prev[c.userId]) {
                prev[c.userId] = {
                    ...(users.find((u) => u.id === c.userId) ?? {}),
                    details: [],
                };
            }
            prev[c.userId].details.push(
                getBookingDetailsByUserId(c.userId).find(
                    (d) => d.id === c.detailId
                ) as IBookingDetail
            );
            return prev;
        }, {} as Record<string, { details: IBookingDetail[] } & Partial<IBookingDetailPopupUser>>);
    }, [checked, getBookingDetailsByUserId, users]);

    const renderPaymentContentAndRecalculateServiceFee = useCallback(({ title }: { title: string }) => {
        return renderPaymentContent({
            title,
            onChangePaymentType: (paymentType: PaymentType) => {
                onRecalculateServiceFeeByPaymentMethod(paymentType);
            }
        });
    }, [onRecalculateServiceFeeByPaymentMethod, renderPaymentContent])
    const renderContent = () => {
        const submitLoadingShown = isSubmitLoading;

        const editModeShown = !submitLoadingShown && mode === "edit";

        const confirmModeShown = !submitLoadingShown && mode === "confirm";
        return <Box>
            <Box sx={{
                display: { xs: submitLoadingShown ? "block" : "none" },
            }}>
                {renderLoadingContent()}
            </Box>
            <Box sx={{
                display: { xs: editModeShown ? "block" : "none" },
            }}>
                <EditModeFlightBookingDetailRefundPopupContent
                    users={users}
                    onChangeAmount={setAmount}
                    amount={amount}
                    refundAmount={refundAmount}
                    currency={currency}
                    serviceFeeByBookingDetailId={serviceFeeByBookingDetailId}
                    isUserBookingRefunded={isUserBookingDetailRefunded}
                    isLoadingServiceFee={isLoadingServiceFee}
                    checked={checked}
                    renderPaymentContent={renderPaymentContentAndRecalculateServiceFee}
                    onCheck={(checked, user, detail) => onCalculateServiceFeeForRefund(checked, user, detail, paymentType)}
                />
                {toConfirmStepError &&
                    <Typography color="error" variant="button">
                        {toConfirmStepError}
                    </Typography>
                }
            </Box>
            <Box sx={{
                display: { xs: confirmModeShown ? "block" : "none" },
            }}>
                <ConfirmModeFlightBookingDetailRefundPopupContent
                    checkedGroupByUserId={checkedGroupByUserId}
                    refundAmount={refundAmount}
                />
            </Box>
        </Box>
    };
    const getActions = () => {
        if (isSubmitLoading) {
            return {
                hasActionButton: false,
                title: Strings.Common.PROCESSING,
            };
        }
        if (mode === "edit") {
            return {
                onAction: () => {
                    if (checked.length === 0) {
                        setToConfirmStepError(Strings.BOOKING.REFUND_POPUP_ERROR_NO_ITEM_SELECTED);
                    } else {
                        setMode("confirm");
                        setToConfirmStepError(null);
                    }
                },
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                title: Strings.BOOKING.REFUND,
                onClickCloseIcon: props.onClose,
            };
        }

        if (mode === "confirm") {
            return {
                onAction: () => onRefundSubmit({
                    selectedItems: checked,
                    serviceFeeByBookingDetailId,
                    amount,
                    refundAmount,
                }),
                hasActionButton: true,
                buttonAction: Strings.Common.CONFIRM,
                closeButton: Strings.BOOKING.POPUP_GO_BACK_BUTTON,
                onClose: () => setMode("edit"),
                title: Strings.BOOKING.REFUND_POPUP_CONFIRM_TITLE,
                onClickCloseIcon: props.onClose,
            };
        }

        return {};
    };
    return (
        <Modal
            {...getActions()}
            fullWidth
            maxWidth="md"
            visible={props.visible}
        >
            {renderContent()}
        </Modal>
    );
};

export default BookingDetailRefundPopup;