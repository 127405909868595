import Helpers from "commons/helpers";
import Constants from "constants/index";
import AdministrativeDivisionService from "services/common/administrativeDivision.service";

const administrativeDivisionService = new AdministrativeDivisionService();
const useAdministrativeDivision = () => {
    const getCountries = async () => {
        const countryList = (() => {
            const value = sessionStorage.getItem(Constants.StorageKeys.COUNTRY_LIST)
            if (!Helpers.isNullOrEmpty(value) && value !== "undefined" && value !== "null") {
                return JSON.parse(value);
            } else {
                return undefined;
            }
        })();

        if (countryList) {
            return Promise.resolve(countryList);
        } else {
            return await administrativeDivisionService.getCountries({ type: 1 }).then(value => {
                sessionStorage.setItem(Constants.StorageKeys.COUNTRY_LIST, JSON.stringify(value));
                return value;
            })
        }
    }
    return { getCountries }
}

export default useAdministrativeDivision;