const BOOKING_API_URL = process.env.REACT_APP_BOOKING_API_URL || "";
const SALE_API_URL = process.env.REACT_APP_SALE_API_URL || "";
const ASSET_API_URL = process.env.REACT_APP_ASSET_API_URL || "";
const COMMON_API_URL = process.env.REACT_APP_COMMON_API_URL || "";
const MESSAGE_API_URL = process.env.REACT_APP_MESSAGE_API_URL || "";
const IDENTITY_URL = process.env.REACT_APP_IDENTITY_URL || "";
const IDENTITY_API_URL = `${IDENTITY_URL}/api`;

export { SALE_API_URL, ASSET_API_URL, COMMON_API_URL, MESSAGE_API_URL, IDENTITY_URL, IDENTITY_API_URL, BOOKING_API_URL };

const Constants = {
    Api: {
        TIMEOUT: 60 * 1000,
    },

    /**
     * Return code from Api
     */
    ApiCode: {
        // Code from server api
        SUCCESS: 200,
        BAD_REQUEST: 400,
        TOKEN_EXPRIED: 401,
        NOT_PERMISSION: 403,
        NOT_FOUND: 404,
        INTERNAL_SERVER_ERROR: 500,

        // Code from local app
        INTERNAL_SERVER: "INTERNAL_SERVER",
        UNKNOWN_NETWORK: "UNKNOWN_NETWORK",
        CONNECTION_TIMEOUT: "CONNECTION_TIMEOUT",
        INVALID_USERNAME_OR_PASSWORD: "invalid_username_or_password",
        INVALID_REQUEST: "invalid request",
    },

    IdentityPath: {
        REDIRECT_URI: "/auth/callback",
        POST_LOGOUT_REDIRECT_URL: "/",
    },

    /**
     * Setting path for Api
     */
    ApiPath: {
        /**
         * Api path IDENTITY
         */

        // auth
        ACCOUNT: {
            SIGNIN: "/Account/SignIn",
            SIGNUP: "/Account/SignUp",
            LOGOUT: "/Account/Logout",
            REFRESH_TOKEN: "Account/RefreshToken",
            RESSET_PASSWORD: "/Account/ResetPassword",
            FORGOT_PASSWORD: "/Account/ForgotPassword",
            RESET_PASSWORD: "/Account/ResetPassword",
        },

        // Userdevice
        USER_DEVICE: {
            GET_DEFAULT: "/Userdevice/GetDefault",
            UPDATE: "/Userdevice/Update",
        },

        SERVICE_ORGANIZATION: {
            ADD: "/ServiceOrganization/Add",
        },

        // notification
        NOTIFICATION: {
            GET_PAGED: "/Message/GetPaged",
            READ_MESSAGE: "/Message/ReadMessage",
            DETAIL_MESSAGE: "/Message/DetailMessage",
            READ_ALL_MESSAGE: "/Message/ReadAllMessage",
            DELETE_ALL_MESSAGE: "/Message/DeleteAllMessage",
        },

        /**
         * Api path COMMON
         */
        //Upload File
        UPLOAD_FILE: "/file/upload",
        GET_FILE: "/file",

        ASSET: {
            GET_ASSET_INFO: "/GetAssetInfo",
            GET_ASSET_ACCESS_URL: "/GetAccessUrl",
            GET_ASSET_DOWNLOAD_URL: "/GetDownloadUrl",
            GET_EXTERNAL_URL_IDS: "/GetExternalUrlIds",
            CREATE_URL_ASSET: "/CreateUrlAsset",
            SET_ACTIVE_BY_ID: "/SetActiveById",
            SET_DELETED_BY_ID: "/SetDeletedById",
        },

        INVITATION: {
            GET_ALL: "/Invitation/GetAll",
            ACCEPT: "/Invitation/Accept",
            DECLINE: "/Invitation/Decline",
            CREATE: "/Invitation/create",
            DELETE: "/Invitation/delete",
            RESEND: "/Invitation/Resend",
            GET_PAGED: "/Invitation/GetPaged",
            QUICK_INVITE: "/Invitation/QuickInvite",
        },

        RESOURCE: {
            GET_ALL: "/Resource/GetAll",
            GET_PAGED_SERVICE: "/Resource/GetPagedService",
            GET_SERVICE: "/Resource/GetService",
            GET_PAGED: "/Resource/GetPaged",
            GET_DETAIL: "/Resource/Detail",
            CREATE: "/Resource/Create",
            UPDATE: "/Resource/Update",
            DELETE: "/Resource/Delete",
            GET_DEFAULT_RESOURCE_DETAIL: "/Resource/GetDefaultResourceDetail",
        },

        ATTRIBUTE: {
            GET_PAGED: "/Attribute/GetPaged",
            CREATE: "/Attribute/Create",
            UPDATE: "/Attribute/Update",
            DELETE: "/Attribute/Delete",
            DETAIL: "/Attribute/Detail",
            DETAIL_BY_CODE: "/Attribute/GetByCode",
            GET_ALL: "/Attribute/GetAll",
        },

        // USER
        USER: {
            USER_INFO: "/User/getUserInfo",
            GET_PAGED: "/User/GetPaged",
            CREATE: "/User/Create",
            UPDATE: "/User/Update",
            DETAIL: "/User/Detail",
            BLOCK: "/User/Block",
            DELETE: "/User/Delete",
            EXPORT: "User/ExportData",
            EXPORT_DATA: "/User/ExportData",
            GET_MANAGE_USER: "/User/GetManageUser",
        },

        // ORGANIZATION
        ORGANIZATION: {
            GET_ALL: "/Organization/GetAll",
            GET_PAGED: "/Organization/GetPaged",
            GET_DETAIL_BY_ID: "/Organization/Detail",
            CREATE: "/Organization/Create",
            UPDATE: "/Organization/Update",
            DELETE: "/Organization/Delete",
            OWNED_ORGANIZATION: "/Organization/OwnedOrganization",
            GET_ORGANIZATION_BY_IDS: "/Organization/GetByIds",
        },

        // PROFILE
        PROFILE: {
            USER_INFO: "Profile/UserInfo",
            USER_ORGANIZATION: "Profile/UserOrganization",
            USER_AUTHORIZATION: "Profile/UserAuthorization",
            CHANGE_PASSWORD: "Profile/ChangePassword",
            CHANGE_PHONE_NUMBER: "Profile/ChangePhoneNumber",
            GET_CHANGE_PHONE_NUMBER_OTP: "Profile/GetChangePhoneNumberOTP",
            UPDATE_PROFILE: "Profile/UpdateProfile",
            UPDATE_AVATAR: "Profile/ChangeAvatar",
            GET_CHANGE_EMAIL_OTP: "Profile/GetChangeEmailOTP",
            CHANGE_EMAIL: "Profile/ChangeEmail",
            USER_SUMMARY: "Profile/UserSummary",
            UPDATE_USER_PROFILE: "Profile/UpdateUserProfile",
        },

        ROLE: {
            GET_ALL: "Role/GetAll",
        },

        CODENAME: {
            GET_BY_GROUP: "/Codename/GetByGroup",
            DELETE: "/Codename/Delete",
            GET_DETAIL: "/Codename/Detail",
            CREATE: "/Codename/Create",
            UPDATE: "/Codename/Update",
        },

        SYSTEM_SETTING: {
            UPDATE: "/SystemSetting/Update",
            GET_SYSTEM_SETTING: "/SystemSetting/GetSystemSetting",
            GET_BY_SETTING_CODE: "/SystemSetting/GetBySettingCode",
            GET_BY_LIST_SETTING_CODES: "/SystemSetting/GetBySettingCodes",
        },

        //ITEM
        ITEM: {
            GET_PAGED: "/Item/GetPaged",
            GET_ALL: "/Item/GetAll",
            CREATE: "/Item/Create",
            CREATE_SERVICE: "/Item/CreateService",
            CREATE_MEMBERSHIP: "/Item/CreateMembership",
            UPDATE: "/Item/Update",
            UPDATE_SERVICE: "/Item/UpdateService",
            UPDATE_MEMBERSHIP: "/Item/UpdateMembership",
            DELETE: "/Item/Delete",
            DETAIL: "/Item/Detail",
        },

        EXCHANGE_RATE: {
            GET_SUPPORT_CURRENCY: "/ExchangeRate/GetSupportCurrency",
            GET_CURRENCY_FORMAT: "/ExchangeRate/GetCurrencyFormat",
        },

        //Product
        PRODUCT: {
            GET_PAGED: "/Product/GetPaged",
            GET_ALL: "/Product/GetAll",
            CREATE: "/Product/Create",
            UPDATE: "/Product/Update",
            DELETE: "/Product/Delete",
            DELETES: "/Product/DeleteMulti",
            DETAIL: "/Product/Detail",
        },

        PRODUCT_TEMPLATE: {
            GET_PAGED: "/ProductTemplate/GetPaged",
            GET_ALL: "/ProductTemplate/GetAll",
            CREATE: "/ProductTemplate/Create",
            UPDATE: "/ProductTemplate/Update",
            DELETE: "/ProductTemplate/Delete",
            DELETES: "/ProductTemplate/DeleteMulti",
            DETAIL: "/ProductTemplate/Detail",
        },

        // Service
        SERVICE: {
            GET_ALL: "/Service/GetAll",
            GET_PAGED: "/Service/GetPaged",
            GET_DETAIL: "/Service/Detail",
            CREATE: "/Service/Create",
            UPDATE: "/Service/Update",
            DELETE: "/Service/Delete",
        },

        // Tenant
        TENANT: {
            GET_ALL: "/Tenant/GetAll",
        },

        //Order
        ORDER: {
            GET_PAGED: "/Order/GetPaged",
            CONFIRM_ORDER: "/Order/Confirm",
            COMPLETE_ORDER: "/Order/Complete",
            CALC_PRICE: "/Order/Pricing",
            DETAIL: "/Order/Detail",
            UPDATE: "/Order/Update",
            CANCEL: "/Order/Cancel",
            REFUND: "/Order/Refund",
            EXPORT_CSV: "/Order/ExportCsv",
            GET_RESPONSE_BY_ORDER_IDS: "/Order/GetResponseByOrderIds",
            GET_DETAIL_ORDER_INCLUDE_SUB_ORDER: "/Order/DetailOrderIncludeSubOrder",
        },

        LOYALTY: {
            CREATE: "/Loyalty/Create",
            UPDATE: "/Loyalty/Update",
            GET_DEFAULT: "/Loyalty/GetDefault",
            GET_ALL_LOYALTY_CLASS: "/Loyalty/GetAllLoyaltyClass",
        },

        //PARTNER
        PARTNER: {
            GET_PAGED: "/Partner/GetPaged",
            GET_ALL: "/Partner/GetAll",
            DETAIL: "/Partner/Detail",
            CREATE: "/Partner/Create",
            UPDATE: "/Partner/Update",
            UPDATE_STATUS: "/Partner/UpdateStatus",
        },

        //Tag
        TAG: {
            GET_PAGED: "/Tag/GetPaged",
            GET_ALL: "/Tag/GetAll",
        },

        //promotion
        PROMOTION: {
            CREATE: "/Promotion/Create",
            CREATE_VOUCHER: "/Promotion/CreateVoucher",
            UPDATE: "/Promotion/Update",
            UPDATE_VOUCHER: "/Promotion/UpdateVoucher",
            DELETE: "/Promotion/DeletePromotion",
            DELETE_VOUCHER_CODE: "/Promotion/DeleteVoucherCode",
            GET_PAGED: "/Promotion/GetPaged",
            GET_DETAIL: "/Promotion/Detail",
            SYNC_VOUCHER_CODE: "/Promotion/SyncVoucherCode",
            ISSUE_VOUCHER: "/Promotion/IssueVoucher",
            CANCEL_VOUCHER: "/Promotion/CancelVoucherIssue",
            GET_PAGED_VOUCHER_CODE: "Promotion/GetPagedVoucherCode",
            GET_PAGED_VALID_CUSTOMER: "/Promotion/GetPagedValidCustomer",
            COUNT_VOUCHER_CODE_ISSUED: "Promotion/CountVoucherCodeIssued",
            CANCEL_PROMOTION: "/Promotion/CancelPromotion",
            DELETE_PROMOTION: "/Promotion/DeletePromotion",
            GET_BY_CONDITION: "/Promotion/GetByCondition",
            GET_BY_CUSTOMER: "/Promotion/GetVoucherByCustomer",
        },

        CASH_BOOK: {
            GET_PAGED: "/CashBook/GetPaged",
            GET_PAGED_V2: "/CashBook/GetPagedV2",

            CREATE_PAYMENT: "/CashBook/CreatePaymentVoucher",
            UPDATE_PAYMENT: "/CashBook/UpdatePaymentVoucher",
            COMPLETE_PAYMENT: "/CashBook/CompletePaymentVoucher",
            CANCEL_PAYMENT: "/CashBook/CancelPaymentVoucher",
            DETAIL_PAYMENT: "/CashBook/DetailPaymentVoucher",

            CREATE_RECEIVE: "/CashBook/CreateReceiveVoucher",
            UPDATE_RECEIVE: "/CashBook/UpdateReceiveVoucher",
            COMPLETE_RECEIVE: "/CashBook/CompleteReceiveVoucher",
            CANCEL_RECEIVE: "/CashBook/CancelReceiveVoucher",
            DETAIL_RECEIVE: "/CashBook/DetailReceiveVoucher",
            EXPORT: "/CashBook/Export",
            EXPORT_V2: "/CashBook/ExportV2",
            DETAIL: "/CashBook/Detail",
        },

        // USER_CONTACT
        USER_CONTACT: {
            GET_DEFAULT: "/UserContact/GetDefault",
            CREATE: "/UserContact/Create",
            UPDATE: "/UserContact/Update",
        },

        // Address
        ADDRESS: {
            CREATE: "/Address/Create",
            UPDATE: "/Address/Update",
            DELETE: "/Address/Delete",
            DETAIL: "/Address/Detail",
            ADDRESS_BY_IDS: "/Address/GetByIds",
        },

        // Administrative Division
        ADMINISTRATIVE_DIVISION: {
            GET_COUNTRIES: "AdministrativeDivision/GetAll",
            GET_PAGED: "/AdministrativeDivision/GetPaged",
            GET_CITY: "/AdministrativeDivision/Cities",
            GET_LIST_BY_PARENT_ID: "/AdministrativeDivision/GetListByParentId",
            GET_DETAIL: "/AdministrativeDivision/Detail",
            DELETE: "/AdministrativeDivision/Delete",
            CREATE: "AdministrativeDivision/Create",
            UPDATE: "AdministrativeDivision/Update",
        },

        // codename
        CODE_NAME: {
            GET_BY_GROUP: "/Codename/GetByGroup",
        },
        TRANSPORT_HUB: {
            GET_PAGED: "/TransportHub/GetPaged",
        },

        TICKET_FUND: {
            EXPORT: "/TicketFund/Export",
        },

        BOOKING: {
            GET_PAGED: "/Booking/GetPaged",
            GET_PAGEDV2: "/Booking/GetPagedV2",
            CREATE: "/Booking/Create",
            UPDATE: "/Booking/Update",
            DELETE: "/Booking/Delete",
            DETAIL: "/Booking/Detail",
            CANCEL: "/Booking/Cancel",
            CONFIRM: "/Booking/Confirm",
            EXPORT_CSV: "/Booking/ExportCsv",
            DETAIL_BY_ORDER_ID: "/Booking/DetailByOrderId",
            CREATE_SUBORDER: "/Booking/CreateSubOrder",
            GET_ISSUED_BY_TARGET_CODE: "/Booking/GetIssuedByTargetCode",
        },

        SERVICE_FEE_SETTING: {
            GET_PAGED: "/ServiceFeeSetting/GetPaged",
            CREATE: "/ServiceFeeSetting/Create",
            UPDATE: "/ServiceFeeSetting/Update",
            DELETE: "/ServiceFeeSetting/Delete",
            DETAIL: "/ServiceFeeSetting/Detail",
            CALCULATE_SERVICE_FEE: "/ServiceFeeSetting/CalculateServiceFee",
            CALCULATE_SERVICE_FEE_EXTRA: "/ServiceFeeSetting/CalculateServiceFeeExtra",
        },
    },

    /**
     * Styles for app.
     *
     * Color refer
     * @see https://www.rapidtables.com/web/color/index.html
     * @see https://www.w3schools.com/w3css/w3css_colors.asp
     */
    Styles: {
        // =====================================================================
        // Common color
        // =====================================================================
        BLUE_COLOR: "#3b82f6",
        RED_COLOR: "#dc2626",
        GRAY_COLOR: "#808080",
        BLACK_COLOR: "#000000",
        GREEN_COLOR: "#008000",
        WHITE_COLOR: "#FFFFFF",
        PRIMARY_COLOR: "#222224",
        SECONDARY_COLOR: "#7b809a",
        OCEAN_BLUE_COLOR: "#174291",
        LIGHT_BLUE_COLOR: "#12B7BC",
        LIGHT_GRAY_COLOR: "#e5e7eb",
        BRIGHT_OCEAN_BLUE_COLOR: "#1F5DC6",
        PRIMARY_DARK_COLOR: "rgb(13, 142, 147)",

        // New - Analysis - Processing - Processed - Cancelled - Close
        STATUS_COLOR: ["#27AE60", "#FEC600", "#24EBC7", "#00AFF0", "#D3D3D3", "#CED4DA"],

        // =====================================================================
        // Console log style
        // Color refer at: https://www.w3schools.com/w3css/w3css_colors.asp
        // =====================================================================
        CONSOLE_LOG_DONE_ERROR: "border: 2px solid #F44336; color: #000000", // Red
        CONSOLE_LOG_DONE_SUCCESS: "border: 2px solid #4CAF50; color: #000000", // Green
        CONSOLE_LOG_ERROR: "background: #F44336; color: #FFFFFF", // Red
        CONSOLE_LOG_NOTICE: "background: #FF9800; color: #000000; font-size: x-large", // Orange
        CONSOLE_LOG_PREPARE: "border: 2px solid #2196F3; color: #000000", // Blue
        CONSOLE_LOG_START: "background: #2196F3; color: #FFFFFF", // Blue
        CONSOLE_LOG_SUCCESS: "background: #4CAF50; color: #FFFFFF", // Green

        // =====================================================================
        // Common size
        // =====================================================================
        AVATAR_SIZE: "80px",
        DEFAULT_FONTSIZE: "16px",
        DEFAULT_SPACING: "24px",

        // =====================================================================
        // Font size
        // =====================================================================
        FONT_SIZE_SMALL: 13,
        FONT_SIZE_DEFAULT: 15,
        FONT_SIZE_MEDIUM: 18,
        FONT_SIZE_LARGE: 24,
        FONT_SIZE_XLARGE: 27,
        FONT_SIZE_XXLARGE: 32,

        BOX_SHADOW: 3,
        BOX_BORDER_RADIUS: 6,

        COLOR_LAYOUT: {
            BACKGROUND_COLOR_ROOT: "#f0f2f5",
            COLOR_TEXT_ITEM_MENU: "#ffffff",
            COLOR_ITEM_MENU_ACTIVE: "#0f2c60",
            COLOR_ITEM_MENU_ACTIVE_PARENT: "#163978",
        },
    },

    /**
     * Regex Expression
     */
    RegExp: {
        /** https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript */
        EMAIL_ADDRESS: new RegExp(
            `/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@` +
            `((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`
        ),
        NEW_EMAIL_ADDRESS: new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
        /** https://gist.github.com/HarishChaudhari/0dd5514ce430991a1b1b8fa04e8b72a4 */
        ID_CARD: new RegExp(/^[0-9]{8,12}$/),
        PHONE_NUMBER: new RegExp(/^0([135789]{1})([0-9]{8})$/),
        SPECIAL_CHARACTERS: new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
        PASSWORD: new RegExp(`/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/`),
    },

    /**
     * Storage keys
     */
    StorageKeys: {
        LANGUAGE: "LANGUAGE",
        USER_INFO: "USER_INFO",
        TOKEN_INFO: "TOKEN_INFO",
        ORGANIZATION_DATA: "ORGANIZATION_DATA",
        RESOURCE_CODE: "RESOURCE_CODE",
        RESOURCE_MENU: "RESOURCE_MENU",
        // SETTING_CODE_SALE_ATTRIBUTE: "SETTING_CODE_SALE_ATTRIBUTE",
        DEVICE_ID: "DEVICE_ID",
        LOGIN_ID: "LOGIN_ID",
        DEVICE_INFO: "DEVICE_INFO",
        TARGET_SCREEN: "TARGET_SCREEN",
        ORGANIZATION_ID: "ORGANIZATION_ID",
        ACCESS_TOKEN: "ACCESS_TOKEN",
        PROVINCE_LIST: "PROVINCE_LIST",
        COUNTRY_LIST: "COUNTRY_LIST",
        AIRLINES: "AIRLINES",
        FROM: "FROM",
    },

    EventName: {
        TOKEN_EXPIRED: "TOKEN_EXPIRED",
        NOT_PERMISSION: "NOT_PERMISSION",
        NOT_FOUND: "NOT_FOUND",
        PROFILE_NOT_FOUND: "PROFILE_NOT_FOUND",
        LANGUAGE_CHANGE: "LANGUAGE_CHANGE",
        CHOOSE_ORGANIZATION: "CHOOSE_ORGANIZATION",
    },

    /**
     * Cloud Storage config
     */
    CloudStorage: {
        AVATAR_FOLDER: "avatar",
        TEMP_FOLDER: "temp",
        ORGANIZATION_IMAGE_FOLDER: "org_image",
        EDITOR_CONTENT_FOLDER: "editor_content",
        SALE_IMAGE_FOLDER: "sale_image",
    },

    /**
     * Cookie keys
     */
    CookieNames: {
        LANGUAGE: "lang",
        SESSION_ID: "sessionId",
    },

    /**
     * Row per paged
     */
    ROW_PER_PAGE: 20,
    ROW_PER_PAGE_5: 5,
    ROW_PER_PAGE_10: 10,
    ROW_PER_PAGE_20: 20,
    ROW_PER_PAGE_25: 25,
    ROW_PER_PAGE_50: 50,
    ROW_PER_PAGE_100: 100,
    ROW_PER_PAGE_OPTIONS: [20, 50, 100],
    ROW_PER_PAGE_OPTIONS_1: [10, 25, 50],
    ROW_PER_PAGE_OPTIONS_2: [5, 10, 20],

    /**
     * format datetime
     */
    DateFormat: {
        DDMM: "DD/MM",
        MMYYYY: "MM/YYYY",
        DDMMYYYY: "DD/MM/YYYY",
        DDMMYYYY_HHMM: "DD/MM/YYYY HH:mm",
        HHMM_DDMMYYYY: "HH:mm DD/MM/YYYY",
        HHMM: "HH:mm",
        YYYY: "YYYY",
        YYYYMM: "YYYY/MM",
        YYYYMMDD: "YYYY/MM/DD",
        YYYYMMDD_HHMM: "YYYY/MM/DD HH:mm",
        DDDD: "dddd",
    },

    MomentWeekDay: {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
    },

    DefaultLanguage: "vi",
    LanguageContent: {
        VI: "vi",
        EN: "en",
    },

    COCCOC_BRAND_NAME: "CocCoc",

    /**
     * Resources
     */
    ResourceMenu: {
        DASHBOARD: "booking/dashboard",

        ROLE: "booking/role",
        GROUP: "booking/group",
        STAFF: "booking/staff",
        ORGANIZATION: "booking/organization",

        CUSTOMER: "booking/customer",
        LOYALTY_CUSTOMER: "booking/loyalty-member",

        AMEND: "booking/bookingAmend",
        BOOKING_HOTEL: "booking/bookingHotel",
        BOOKING_FLIGHT: "booking/bookingFlight",

        ORDER: "booking/order",

        CASHBOOK: "booking/cashbook",

        CODENAME: "booking/codename",
        ATTRIBUTE: "booking/attribute",
        SERVICE_FEE: "booking/serviceFee",

        PAYMENT_ACCOUNT: "booking/paymentAccount",
        TICKET_FUND_REQUEST: "booking/ticketfundRequest",
        TICKET_FUND_APPROVAL: "booking/ticketfindApproval",
    },

    ResourceUri: {
        ROLE: "identity/role",
        STAFF: "bookingAdmin/staff",
        GROUP: "bookingAdmin/group",
        ORGANIZATION: "bookingAdmin/organization",

        DASHBOARD: "booking/dashboard",
        ORDER: "sale/order",
        PARTNER: "sale/partner",
        CASHBOOK: "sale/cashbook",
        ATTRIBUTE: "sale/attribute",
        CODENAME: "common/codename",
        SERVICE_FEE: "sale/service-fee",
        PAYMENT_ACCOUNT: "sale/paymentAccount",
        LOYALTY_CUSTOMER: "sale/loyalty_customer",
        AMEND: "booking/amend",
        BOOKING: "booking/booking",
        BOOKING_HOTEL: "booking/bookingHotel",
        BOOKING_FLIGHT: "booking/bookingFlight",
        TICKET_FUND_REQUEST: "sale/ticketfundRequest",
        TICKET_FIND_APPROVAL: "sale/ticketfindApproval",
    },

    // System Setting Code
    CLIENT_ID: process.env.REACT_APP_DEFAULT_CLIENT_ID as string,

    TENANT_ID: "0",
    // TENANT_CODE: "000000",
    TENANT_CODE: "MTRAVEL",
    SERVICE_CODE: "00000C",
    SECRET_PASS: "C3K2khw@",
    ORGANIZATION_ID_ADMIN_SERVICE: "0",

    SETTING_BRAND_CODE: "0001",
    SETTING_ORIGIN_CODE: "0002",
    SETTING_PEROID_CODE: "0003",

    CODE_LOYALTY_CLASS_MEMBER: "000000",
    MAX_VALUE_LONG: "9223372036854775807",
    SETTING_CODE_SALE_ATTRIBUTE: "SETTING_CODE_SALE_ATTRIBUTE",

    CURRENCY_DEFAULT: "USD",
    PRODUCT_TEMPLATE_CODE: "D00000041908",

    SaleAdminPath: {
        REDIRECT_URI: "/auth/callback",
        POST_LOGOUT_REDIRECT_URL: "/",
    },

    SKU: {
        MAX_LENGTH: 16,
        CLIENT_MEMBERSHIP: "CM",
        CLIENT_SERVICE_COMBO: "CSC",
    },
};

export default Constants;
