import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ModuleAttribute } from "@maysoft/sale-common-react";

import Screens from "constants/screens";
import Constants from "../../constants";
import useModule from "hooks/useModule";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { RoleHelpers } from "@maysoft/common-component-react";

const AttributeScreen = () => {
    const [searchParams] = useSearchParams();
    const userProfile = useSelector((state: RootState) => state.userInfo.userProfile);

    const [request] = useState<any>({
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.ATTRIBUTE_LIST,
            detail: Screens.ATTRIBUTE_EDIT,
        },
        keyOfStrings: "ATTRIBUTE",
    });

    return (
        <DashboardLayout
            isPermission
            isRequiredOrganization={!RoleHelpers.isSuperAdmin(userProfile?.roleType, Constants.SERVICE_CODE)}
            {...{ title, route }}
        >
            <ModuleAttribute {...moduleProps} />
        </DashboardLayout>
    );
};

export default AttributeScreen;
