import APIAccessor from "../apiProcessor";
import { BOOKING_API_URL } from "constants/index";

class BookingService {
    protected api: APIAccessor;

    constructor() {
        this.api = new APIAccessor(BOOKING_API_URL);
    }
}

export default BookingService;
