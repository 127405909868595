import Helpers from "commons/helpers";
import Constants from "../../constants";
import BaseService from "./base.service";

// Danh Mục
class CodenameService extends BaseService {
    async getByGroup(group: string) {
        const result = await this.api.get({
            path: Constants.ApiPath.CODE_NAME.GET_BY_GROUP.concat(`?${Helpers.handleFormatParams({
                group,
                serviceCode: Constants.SERVICE_CODE
            })}`),
        });
        return result.data.result;
    }
}

export default CodenameService;
