import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Screens from "constants/screens";
import Strings from "constants/strings";
import { DashboardLayout } from "layout";

const DashboardScreen = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === "/") {
            navigate(Screens.DASHBOARD, { replace: true });
        }
    }, []);

    return (
        <DashboardLayout
            isPermission
            title={Strings.Home.TITLE}
            route={[{ title: Strings.Home.TITLE, route: "" }]}
        >
            <></>
        </DashboardLayout>
    );
};

export default DashboardScreen;
