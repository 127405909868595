

import { useEffect, useState } from "react";
import { FormField } from "@maysoft/common-component-react";
import { ModalSearchPartner, PartnerType } from "@maysoft/sale-common-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";


import { Mode } from "constants/enum";
import { IErrorDetail, IDataDetail } from ".";


interface IProps {
    mode: Mode;
    dataMap?: Map<string, any>;

    data: IDataDetail;
    setData: React.Dispatch<React.SetStateAction<IDataDetail>>

    error: IErrorDetail;
    setError: React.Dispatch<React.SetStateAction<IErrorDetail>>
}

const InputAddCustomer = (props: IProps) => {

    const [openCustomer, setOpenCustomer] = useState(false);
    const [buyerSearchText, setBuyerSearchText] = useState("");

    useEffect(() => {
        if (props.data?.buyer?.id) {
            const newValue = props.data?.buyer?.phoneNumber
                ? `${props.data?.buyer?.name} - ${props.data?.buyer?.phoneNumber}`
                : `${props.data?.buyer?.name}`;
            setBuyerSearchText(newValue);
        }
    }, [props.data?.buyer?.id]);

    return (
        <>
            <FormField
                required
                mode={props.mode}
                contentEditable={false}
                value={buyerSearchText}
                errorMessage={props.error?.buyer}
                label={Strings.BOOKING.CUSTOMER}
                disabled={!Helpers.isNullOrEmpty(props.data?.id)}
                placeholder={Strings.BOOKING.SELECT_OR_ADD_CUSTOMER}
                onClick={() => {
                    setOpenCustomer(true)
                }}
            />

            <ModalSearchPartner
                isSingle
                isAddNewPartner
                open={openCustomer}
                partnerType={PartnerType.Customer}
                partnerIds={props.data?.buyer?.id ? [props.data?.buyer?.id] : []}
                onClose={() => setOpenCustomer(false)}
                onAction={(data) => {
                    if (!data || !data[0]) {
                        return;
                    } else {
                        const primaryUser = data[0]?.partnerUsers?.find((pu) => pu.primaryUser === 1) || {};

                        setBuyerSearchText(primaryUser?.phoneNumber ? `${primaryUser?.name} - ${primaryUser?.phoneNumber}` : `${primaryUser?.name}`);

                        props.setData(prev => ({
                            ...prev,
                            buyer: {
                                ...prev.buyer,
                                id: data[0]?.id,
                                name: primaryUser?.name || "",
                                email: primaryUser?.email || "",
                                phoneNumber: primaryUser?.phoneNumber || "",
                            },
                        }));

                        props.setError(prev => ({ ...prev, buyer: undefined }));

                        setOpenCustomer(false);
                    }
                }}
            />
        </>
    );
};

export default InputAddCustomer;
