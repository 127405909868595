import { useState } from "react";
import { Card } from "@mui/material";
import { Search } from "@mui/icons-material";
import { Box, Button, FormField, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import RequestBookingService from "services/booking/requestBooking.service";

const bookingService = new RequestBookingService();

interface AmendBookingListScreenProps {
    onNavigate:(data?: { id: string }) => void;
}

const AmendBookingListScreen = (props: AmendBookingListScreenProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const handleSearch = async () => {
        if (searchText.trim().length === 0) {
            Helpers.showAlert("Vui lòng nhập mã đơn hàng hoặc mã đặt chỗ", "error");
        }
        try {
            setIsSearching(true);

            const bookingId = await bookingService.getIssuedByTargetCode(searchText);
            // TODO: Navigate to booking detail screen => show content by itineraryType
            props.onNavigate({ id: bookingId });
        } catch (error) {
            Helpers.showAlert("Không tìm thấy thông tin đặt chỗ", "error");
        } finally {
            setIsSearching(false);
        }
    };

    return (
            <Box px={1}>
                <Typography variant="h5">{Strings.BOOKING.TITLE_MENU_AMEND}</Typography>
                <Box my={3} display="flex" gap={2} flexDirection="column">
                    <Card>
                        <Box p={2} display="flex" flexDirection="column" gap={1}>
                            <Typography variant="button">Mã đơn hàng/đặt chỗ</Typography>
                            <Box gap={1} display="flex" flexDirection="row" flexWrap="wrap">
                                <FormField
                                    variant="outlined"
                                    placeholder="Tìm theo mã đơn hàng/đặt chỗ"
                                    defaultValue={searchText}
                                    onBlur={setSearchText}
                                    disabled={isSearching}
                                    style={{
                                        maxWidth: "500px",
                                    }}
                                />
                                <Button onClick={handleSearch} loading={isSearching}><Search fontSize="small" />&nbsp;Tìm kiếm</Button>
                            </Box>
                        </Box>
                    </Card>

                    <Card>
                        <Box display="flex" alignItems="center" justifyContent="center" p={8}>
                            <Typography variant="button">Nhập mã đơn hàng hoặc mã đặt chỗ để xử lý</Typography>
                        </Box>
                    </Card>

                </Box>
            </Box>
    );

}

export default AmendBookingListScreen;