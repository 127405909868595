import { Icon } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import { IRoom } from "services/booking/propertyContentService";



const BoxRefundable = ({ item }: { item: IRoom }) => {
    const currentRate = item.rates?.find((el) => el.referenceId === item.selectedRate);

    if (currentRate?.refunable === true) {
        return (
            <Box>
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                }}>
                    <Typography variant="button" color="success" fontWeight="bold" >{"Hoàn lại đầy đủ"}</Typography>
                    <Icon color="success">{"info_outlined"}</Icon>
                </Box>
                <Typography variant="button">
                    {"Trước "}&nbsp;{Helpers.formatDate(1712549427 * 1000)}
                </Typography>
            </Box>
        );
    }
    if (currentRate?.refunable === false) {
        return (
            <Box>
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                }}>
                    <Typography variant="button" color="error" fontWeight="bold" >{"Không hoàn lại"}</Typography>
                    <Icon color="error" >{"info_outlined"}</Icon>
                </Box>
                <Typography variant="button">{""}</Typography>
            </Box>
        );
    }
    // if (item?.BoxrefundableType === RefundableType.Partially)
    //     return (
    //         <Box>
    //             <Box sx={{
    //                 gap: 1,
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "flex-start",
    //             }}>
    //                 <Typography variant="button" color="secondary" fontWeight="bold" >{"Hoàn lại một phần"}</Typography>
    //                 <Icon color="secondary">{"info_outlined"}</Icon>
    //             </Box>
    //             <Typography variant="button">&nbsp;</Typography>
    //         </Box>
    //     );

    return <></>;
};

export default BoxRefundable;