import { Grid, IconButton } from "@mui/material";
import OutlinedSelectBox from "../components/OutlinedSelectBox";
import { FormField } from "@maysoft/common-component-react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { IService } from "../../interfaces";
import { ICodename } from "commons/interfaces";
import { useEffect, useRef } from "react";

interface AdditionalServiceItemProps {
    shouldFocus?: boolean;
    serviceCodenames: ICodename[];
    data: IService;
    error?: Partial<Record<string, string>>;
    currency: string;
    onRemove?: (value: IService) => void;
    onChange?: (value: IService, keyChanged: keyof IService) => void;
    deletable?: boolean;
}
const AdditionalServiceItem = ({
    shouldFocus,
    serviceCodenames,
    data,
    error = {},
    currency,
    deletable,
    onChange,
    onRemove,
}: AdditionalServiceItemProps) => {
    const handleDelete = () => {
        if (deletable && onRemove) {
            onRemove(data);
        }
    };

    const handleServiceChange = (value: any) => {
        onChange?.({ ...data, itemId: value as string }, "itemId");
    };
    const handleChangeServiceInformation = (value: string) => {
        onChange?.({ ...data, description: value ? value : undefined }, "description");
    };
    const handleChangeServicePrice = (value: string) => {
        onChange?.(
            { ...data, unitPrice: value ? parseInt(value) : undefined },
            "unitPrice"
        );
    };

    const descriptionRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (shouldFocus && descriptionRef.current) {
            descriptionRef.current.focus();
        }
    }, [shouldFocus]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={true}>
                <OutlinedSelectBox
                    fullWidth
                    data={serviceCodenames}
                    errorMessage={error[`${data._id}.type`]}
                    error={!!error[`${data._id}.type`]}
                    initialValue={data.itemId}
                    placeholder="Chọn dịch vụ"
                    variant="outlined"
                    onChangeValue={handleServiceChange}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
                <FormField
                    fullWidth
                    inputRef={descriptionRef}
                    placeholder="Nhập thông tin dịch vụ"
                    variant="outlined"
                    error={!!error[`${data._id}.description`]}
                    errorMessage={error[`${data._id}.description`]}
                    autoComplete="off"
                    defaultValue={data.description}
                    onBlur={handleChangeServiceInformation}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormField
                    isMoney
                    fullWidth
                    placeholder={`Nhập giá dịch vụ (${currency})`}
                    autoComplete="off"
                    variant="outlined"
                    value={data.unitPrice}
                    error={!!error[`${data._id}.unitPrice`]}
                    errorMessage={error[`${data._id}.unitPrice`]}
                    onBlur={handleChangeServicePrice}
                />
            </Grid>
            <Grid
                item
                xs={12}
                display="flex"
                md="auto"
                justifyContent="flex-end"
                alignItems="center"
            >
                <div>
                    <IconButton
                        disabled={!deletable}
                        onClick={handleDelete}
                        children={<RemoveCircleIcon fontSize="small" />}
                        color="error"
                        sx={{ opacity: 0.8 }}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default AdditionalServiceItem;