import { Box, Typography } from "@maysoft/common-component-react";
import { Chip } from "@mui/material";
import { BookingHelpers } from "commons/bookingHelpers";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import Strings from "constants/strings";

const FlightRefundDetail = ({ detail, isRefunded = false, userId }: { detail: IBookingDetail, isRefunded?: boolean, userId: string }) => {
    const { arrivalPlace, departPlace, ticketNumber } = BookingHelpers.getFlightExtraInfo(detail, userId);
    return (
        <Box
            display="flex"
            gap={1}
            alignItems={"center"}
            flexDirection="row"
        >
            <Typography variant="button">
                {Strings.BOOKING.FLIGHT} {departPlace}&nbsp;-&nbsp;{arrivalPlace}&nbsp;
                {ticketNumber && (
                    <Typography variant="button" ml={4}>
                        <b>{Strings.BOOKING.TICKET_NUMBER}</b>:&nbsp;{ticketNumber}
                    </Typography>
                )}
            </Typography>
            {isRefunded && (
                <Chip label={Strings.BOOKING.REFUND_POPUP_REFUNDED_CHIP_LABEL} color="primary" size={"small"} />
            )}
        </Box>
    );
}

export default FlightRefundDetail;