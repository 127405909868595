import { ICodename } from "commons/interfaces";
import Constants from "constants/index";
import { useEffect, useState } from "react";
import CodenameService from "services/common/codename.service";

const codenameService = new CodenameService();
const useAirlineCodenames = () => {
    const [airlines, setListAirlines] = useState<ICodename[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const AIRILNE_GROUP = "AIRLINE";

    useEffect(() => {
        const getDataCodeNameAirline = async () => {
            try {
                setLoading(true);
                const items: ICodename[] = [];

                const result = await codenameService.getByGroup(AIRILNE_GROUP);

                result?.[AIRILNE_GROUP]?.forEach((item: any) => {
                    items.push({
                        code: item.code,
                        name: item.name,
                    });
                });
                items.sort((a, b) => a.name.localeCompare(b.name));
                sessionStorage.setItem(Constants.StorageKeys.AIRLINES, JSON.stringify(items));

                setListAirlines(items);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        const airlines = sessionStorage.getItem(Constants.StorageKeys.AIRLINES);
        if (airlines) {
            setListAirlines(JSON.parse(airlines));
            setLoading(false);
        } else {
            getDataCodeNameAirline();
        }
    }, [])

    return { data: airlines, loading, error };
}

export default useAirlineCodenames;