import Helpers from "commons/helpers";
import Constants from "../../constants";
import SaleService from "./sale.service";
import { CashBookType, CashBookTypeV2, PaymentType, SlipStatus, SlipType } from "constants/enum";

export interface ICashBookExportRequest {
    endTime?: number;
    startTime?: number;
    searchText?: string;
    type?: CashBookTypeV2;
    paymentType?: PaymentType;
    organizationIds?: string[];
}

export interface ICashBookPagingRequest extends ICashBookExportRequest {
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
    orderby?: string;
}

class CashBookService extends SaleService {
    public getPaged = async (data?: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.GET_PAGED}` + (data ? `?${data}` : ""),
        });
        return result.data.result;
    }

    public getPagedV2 = async (data?: ICashBookPagingRequest) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.GET_PAGED_V2}?${query}`
        });
        return result.data.result;
    }


    public getDetailPayment = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.DETAIL_PAYMENT}/${id}`,
        });
        return result.data.result;
    }

    public createPayment = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CREATE_PAYMENT}`,
            data
        });
        return result.data;
    }

    public updatePayment = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.UPDATE_PAYMENT}`,
            data
        });
        return result.data;
    }

    public completePayment = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.COMPLETE_PAYMENT}/${id}`,
        });
        return result.data;
    }

    public cancelPayment = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CANCEL_PAYMENT}/${id}`,
        });
        return result.data;
    }

    public getDetailReceive = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.DETAIL_RECEIVE}/${id}`,
        });
        return result.data.result;
    }

    public createReceive = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CREATE_RECEIVE}`,
            data
        });
        return result.data;
    }

    public updateReceive = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.UPDATE_RECEIVE}`,
            data
        });
        return result.data;
    }

    public completeReceive = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.COMPLETE_RECEIVE}/${id}`,
        });
        return result.data;
    }

    public cancelReceive = async (id: string) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.CASH_BOOK.CANCEL_RECEIVE}/${id}`,
        });
        return result.data;
    }

    public export = async (data: any) => {
        const result = await this.api.getPDF({
            path: `${Constants.ApiPath.CASH_BOOK.EXPORT}?${data}`
        });
        return new Blob([result.data]);
    }

    public exportV2 = async (data: ICashBookExportRequest) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.getExcel({
            path: `${Constants.ApiPath.CASH_BOOK.EXPORT_V2}?${query}`
        });
        return new Blob([result.data]);
    }

    public detail = async (id: string) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.CASH_BOOK.DETAIL}/${id}`,
        });
        return result.data.result;
    }
}

export default CashBookService;