import moment from "moment";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Box, Button, DatePicker, FormField, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "constants/index";
import InputAddSeller from "./inputAddSeller";
import InputAddSupplier from "./inputAddSupplier";
import InputAddCustomer from "./inputAddCustomer";
import InputAddRoomSlot from "./inputAddRoomSlot";
import CardListItemRoom from "./cardListItemRoom";
import InputAddManufacturer from "./inputAddManufacturer";
import useDataRequestBooking, { IDataExtraInfoByBookingDetail, IRequestCreateBooking } from "../useDataRequestBooking.hook";
import RequestBookingService from "services/booking/requestBooking.service";

import { ConfirmStatus, ItineraryType, Mode, SupplierCode } from "constants/enum";
import { IRoom } from "services/booking/propertyContentService";


interface PersionalInfo {
    id?: string;
    name?: string;
    email?: string;
    phoneNumber?: string;
}

interface IBookingDetail {
    id?: string;
    orderId?: string;
    itemId?: string;
    quantity?: number;
    description: string;
}

export interface IDataDetail {
    id?: string;
    organizationId?: string;

    buyer?: PersionalInfo;
    seller?: PersionalInfo;
    brandId?: string;
    supplierId?: string;
    manufacturerId?: string;
    externalId?: string;

    endTime?: number;
    startTime?: number;
    adultSlot?: number;
    childrenSlot?: number;

    bookingCode?: string;
    bookingAmount?: number;
    bookingDetailRequests?: IBookingDetail[];

    note?: string;
    confirmStatus?: number;
}

export interface IErrorDetail {
    buyer?: string;
    seller?: string;
    supplierId?: string;
    manufacturerId?: string;
    endTime?: string;
    startTime?: string;
}

interface IProps {
    codeTitle?: string;
    customerTitle?: string;
    supplierTitle?: string;
    manufacturerTitle?: string;
    endTimeTitle?: string;
    startTimeTitle?: string;

    mode: Mode;
    idDetail?: string;
    onGoBack: () => void;
    onChangeMode: (mode: Mode) => void;
}


const requestbookingService = new RequestBookingService();

const BookingStayCreateUpdateContainer = (props: IProps) => {
    const {
        language,
        userInfo,
        onError,
        onSuccess,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const {
        handleConfirmData,
        getPriceTotalByKey,
        getPartnerBySelectId,
        getNumberOfAdultAndChild,
        getDataRoomByManufacturer,
        getFirstAndLastNameByFullName,
    } = useDataRequestBooking();

    const [dataDetail, setDataDetail] = useState<IDataDetail>({} as IDataDetail);
    const [errorDetail, setErrorDetail] = useState<IErrorDetail>({} as IErrorDetail);


    const [dataMap, setDataMap] = useState<Map<string, PersionalInfo>>(new Map());

    const [dataSelectRooms, setDataSelectRooms] = useState<IRoom[]>([]);

    const [dataOccupancyRoom, setDataOccupancyRoom] = useState<{
        adultSlot: number;
        childrenOld: number[];
    }[]>([{
        adultSlot: 1,
        childrenOld: [],
    }]);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(props.idDetail)) {
            getDetailResquestBooking(props.idDetail);
        } else {
            setErrorDetail({});
            setDataDetail({
                startTime: moment().unix(),
                endTime: moment().add(1, "day").unix(),
                seller: {
                    email: userInfo?.userProfile?.email,
                    id: userInfo?.userProfile?.identityId,
                    phoneNumber: userInfo?.userProfile?.phoneNumber,
                    name: userInfo?.userProfile?.fullName || userInfo?.userProfile?.userName,
                },
            });

            dataMap.set(
                userInfo?.userProfile?.identityId,
                {
                    email: userInfo?.userProfile?.email,
                    id: userInfo?.userProfile?.identityId,
                    phoneNumber: userInfo?.userProfile?.phoneNumber,
                    name: userInfo?.userProfile?.fullName || userInfo?.userProfile?.userName,
                }
            );
        }


    }, [props.idDetail]);

    // Get Data
    const getDetailResquestBooking = async (id: string) => {
        try {
            onShowLoading();

            let listIds: string[] = [];

            const resultDetail = await requestbookingService.getDetail(id);

            if (resultDetail.brandId) {
                listIds.push(resultDetail.brandId)
            }
            if (resultDetail.supplierId) {
                listIds.push(resultDetail.supplierId)
            }
            if (resultDetail.manufacturerId) {
                listIds.push(resultDetail.manufacturerId)
            }
            if (resultDetail.buyer) {
                listIds.push(resultDetail.buyer)
            }
            if (resultDetail.seller) {
                listIds.push(resultDetail.seller)
            }

            const valueExtraInfo: IDataExtraInfoByBookingDetail = Helpers.converStringToJson(resultDetail.bookingDetails?.[0]?.extraInfo);

            const listOccupancy = [...valueExtraInfo.Occupancy || []].map(el => ({
                adultSlot: el.AdultSlot,
                childrenOld: el.ChildrenOld,
            }));

            setDataOccupancyRoom(listOccupancy);

            setDataDetail({
                organizationId: resultDetail.organizationId,
                id,
                bookingCode: resultDetail.bookingCode,
                endTime: Number(resultDetail.endTime || 0),
                startTime: Number(resultDetail.startTime || 0),
                adultSlot: Number(resultDetail.adultSlot || 0),
                childrenSlot: Number(resultDetail.childrenSlot || 0),
                note: resultDetail.description,

                bookingAmount: resultDetail.quoteAmount,
                bookingDetailRequests: [],

                brandId: resultDetail.brandId,
                supplierId: resultDetail.supplierId,
                manufacturerId: resultDetail.manufacturerId,

                buyer: {
                    id: resultDetail.buyer,
                    name: resultDetail.name,
                    email: resultDetail.email,
                    phoneNumber: resultDetail.phoneNumber,
                },
                seller: {
                    id: resultDetail.seller,
                    name: resultDetail.sellerEmail,
                    email: resultDetail.sellerEmail,
                    phoneNumber: resultDetail.sellerPhoneNumber,
                },

                confirmStatus: resultDetail.confirmStatus,
                externalId: valueExtraInfo.PropertyId,
            });

            if ([ConfirmStatus.Confirmed, ConfirmStatus.Cancel].includes(resultDetail.confirmStatus)) {
                props.onChangeMode(Mode.View);
            }

            const newDataMap = await getPartnerBySelectId(listIds, resultDetail.organizationId);
            newDataMap.set(resultDetail.buyer, {
                id: resultDetail.buyer,
                name: resultDetail.name,
                email: resultDetail.email,
                phoneNumber: resultDetail.phoneNumber,
            });

            newDataMap.set(resultDetail.seller, {
                id: resultDetail.seller,
                name: resultDetail.sellerEmail,
                email: resultDetail.sellerEmail,
                phoneNumber: resultDetail.sellerPhoneNumber,
            });

            setDataMap(newDataMap);

            // 
            const resultDataRooms = await getDataRoomByManufacturer(
                {
                    language: (language === "vi") ? "vi-VN" : "en-EN",
                    occupancy: listOccupancy || [],
                    propertyId: valueExtraInfo.PropertyId,
                    checkinTime: Number(resultDetail.startTime || 0),
                    checkoutTime: Number(resultDetail.endTime || 0),
                },
                [valueExtraInfo.RoomId]
            );

            setDataSelectRooms(resultDataRooms.listRoomsSelected);

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            onHideLoading();
        }
    };

    // Handle

    const hadleSetDataOccupancyRoom = (newData: any) => {
        if (newData.subIndex === -999) {
            setDataOccupancyRoom(prev => {
                let temp = [...prev || []];
                temp.splice(newData.index, 1);
                return temp;
            });
        } else if (newData.index === -1) {
            setDataOccupancyRoom(prev => [
                ...prev,
                {
                    adultSlot: 1,
                    childrenOld: [],
                },
            ]);
        } else {
            if (newData.key === "childrenOld" && Helpers.isNullOrEmpty(newData.subIndex)) {
                const childrenAgeList = dataOccupancyRoom[newData.index].childrenOld;
                if (childrenAgeList.length === newData.value) return;
                if (childrenAgeList.length > newData.value) {
                    setDataOccupancyRoom(prev => {
                        let temp = [...prev || []];
                        let occupancy = prev[newData.index];
                        occupancy.childrenOld.splice(newData.value);
                        return temp;
                    });
                }
                if (childrenAgeList.length < newData.value) {
                    setDataOccupancyRoom(prev => {
                        let temp = [...prev || []];
                        let occupancy = prev[newData.index];
                        occupancy.childrenOld.push(1);
                        return temp;
                    });
                }
            };

            if (newData.key === "childrenOld" && !Helpers.isNullOrEmpty(newData.subIndex)) {
                setDataOccupancyRoom(prev => {
                    let temp = [...prev || []];
                    temp[newData.index]["childrenOld"][newData.subIndex] = newData.value;
                    return temp;
                });
            }

            if (newData.key !== "childrenOld") {
                setDataOccupancyRoom((prev) => {
                    let temp = [...prev || []];
                    temp[newData.index] = {
                        ...temp[newData.index],
                        [newData.key]: newData.value
                    };
                    return temp;
                });
            }
        }
    };

    const handleValidate = () => {
        let valid = true;
        const newError: any = { ...errorDetail };

        if (Helpers.isNullOrEmpty(dataDetail?.startTime)) {
            valid = false;
            newError["startTime"] = Strings.Common.FIELD_REQUIRED;
        }
        if (Helpers.isNullOrEmpty(dataDetail?.endTime)) {
            valid = false;
            newError["endTime"] = Strings.Common.FIELD_REQUIRED;
        }

        if (Helpers.isNullOrEmpty(dataDetail?.buyer?.id)) {
            valid = false;
            newError["buyer"] = Strings.Common.FIELD_REQUIRED;
        }

        if (Helpers.isNullOrEmpty(dataDetail?.seller?.id)) {
            valid = false;
            newError["seller"] = Strings.Common.FIELD_REQUIRED;
        }

        if (Helpers.isNullOrEmpty(dataDetail?.manufacturerId)
            && Helpers.isNullOrEmpty(dataDetail?.brandId)) {
            valid = false;
            newError["manufacturerId"] = Strings.Common.FIELD_REQUIRED;
        }

        if (dataDetail?.startTime > dataDetail?.endTime) {
            valid = false;
            newError["startTime"] = Strings.BOOKING.MESSAGE_END_TIME_INVALID;
        }

        if (dataSelectRooms?.length === 0) {
            valid = false;
            onError("Vui lòng thêm phòng để đặt", "error");
        }

        if (!valid) {
            setErrorDetail(newError);
        }

        return valid;
    };

    const handleCreateUpdateSubmit = async () => {
        if (!handleValidate()) return;
        try {
            onShowLoading();

            const currentRate = dataSelectRooms[0].rates?.[0];

            const inclusive = getPriceTotalByKey(currentRate.prices[0], "inclusive");

            const itemFullName = getFirstAndLastNameByFullName(dataDetail?.buyer?.name);
            const infoRoomGuest = dataOccupancyRoom.map(el => ({
                ...itemFullName
            }));

            const numberOfAdultAndChild = getNumberOfAdultAndChild(dataOccupancyRoom);

            const params: IRequestCreateBooking = {
                serviceCode: Constants.SERVICE_CODE,
                organizationId: dataDetail?.organizationId || userInfo?.userProfile?.organizationId,

                id: dataDetail?.id,
                type: ItineraryType.Hotel,

                buyer: dataDetail?.buyer?.id,
                lastName: itemFullName?.lastName,
                firstName: itemFullName?.firstName,
                email: userInfo?.userProfile?.email,
                phoneNumber: userInfo?.userProfile?.phoneNumber,
                countryPhonenumber: "84",

                seller: dataDetail?.seller?.id,

                supplierCode: SupplierCode.Expedia,
                supplierId: dataDetail?.supplierId,

                brandId: dataDetail?.brandId,
                manufacturerId: dataDetail?.manufacturerId,

                endTime: Number(dataDetail?.endTime || 0),
                startTime: Number(dataDetail?.startTime || 0),

                extraInfo: undefined,
                location: userInfo?.location,
                currency: inclusive.currency,
                description: dataDetail?.note,
                propertyId: dataDetail?.externalId,

                adultSlot: numberOfAdultAndChild.numberOfAdult,
                childrenSlot: numberOfAdultAndChild.numberOfChild,
                childrenSlotDetail: undefined,

                bookingCode: dataDetail?.bookingCode,
                bookingQuantity: undefined,
                bookingDetailRequests: [
                    {
                        roomId: dataSelectRooms?.[0]?.referenceId,
                        itemId: currentRate.referenceId,
                        bedGroupId: currentRate.bedId,
                        occupancy: dataOccupancyRoom,
                        quantity: 1,
                        bookingInfoRequest: infoRoomGuest,
                        type: ItineraryType.Hotel,
                        extraInfo: undefined,
                        bookingNo: undefined,
                        bookingClass: undefined,
                    }
                ],
            };

            if (dataDetail?.id) {
                await requestbookingService.update(params);

                onSuccess(Strings.Message.UPDATE_SUCCESS);

                props.onChangeMode(Mode.View);

            } else {
                await requestbookingService.create(params);

                onSuccess(Strings.Message.CREATE_SUCCESS);

                props.onGoBack();
            }

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e)
        } finally {
            onHideLoading();
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/* Action */}
                    <Box sx={{
                        gap: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "end",
                    }}>
                        <Button color={"secondary"} onClick={() => { props.onGoBack(); }}>
                            {Strings.Common.GO_BACK}
                        </Button>
                        {
                            (props.mode === Mode.View) &&
                            <Button
                                onClick={() => {
                                    if (props.mode === Mode.View) {
                                        props.onChangeMode(Mode.Update);
                                    }
                                }}>
                                {Strings.Common.EDIT}
                            </Button>
                        }
                        {
                            (props.mode !== Mode.View) &&
                            <Button onClick={() => { handleCreateUpdateSubmit() }}>
                                {Strings.Common.SAVE}
                            </Button>
                        }
                    </Box>
                </Grid>

                {/* BASIC INFO */}
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <Typography variant="h5">{Strings.BOOKING.GENERAL_INFO}</Typography>
                            <Box p={2}>
                                <Grid container spacing={2}>
                                    {/* CUSTOMER */}
                                    <Grid item xs={12} md={6}>
                                        <InputAddCustomer
                                            mode={props.mode}
                                            data={dataDetail}
                                            setData={setDataDetail}
                                            error={errorDetail}
                                            setError={setErrorDetail}
                                        />
                                    </Grid>
                                    {/* HOTEL */}
                                    <Grid item xs={12} md={6}>
                                        <InputAddManufacturer
                                            mode={props.mode}
                                            dataMap={dataMap}

                                            data={dataDetail}
                                            error={errorDetail}

                                            isWhenShow={dataSelectRooms.length > 0}

                                            onChangeData={(newData) => {
                                                setErrorDetail(prev => ({ ...prev, manufacturerId: undefined, }));
                                                setDataDetail(prev => ({
                                                    ...prev,
                                                    manufacturerId: newData?.[0]?.id,
                                                    externalId: newData?.[0]?.externalId,
                                                }));
                                            }}
                                        />
                                    </Grid>

                                    {/* Check in */}
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            required
                                            mode={props.mode}
                                            errorMessage={errorDetail?.startTime}
                                            value={Helpers.getDateValue(dataDetail?.startTime)}
                                            label={props.startTimeTitle || Strings.BOOKING.START_TIME}
                                            placeholder={`${Strings.Common.SELECT} ${props.startTimeTitle?.toLowerCase() || Strings.BOOKING.START_TIME.toLowerCase()}`}
                                            onChangeValue={(value: any) => {
                                                handleConfirmData({
                                                    isWhen: dataSelectRooms?.length > 0,
                                                    lable: `"${Strings.BOOKING.START_TIME}"`,
                                                    onCancle() {
                                                        setDataDetail(prev => ({ ...prev, startTime: prev.startTime }));
                                                    },
                                                    onOk() {
                                                        let valEndTime = dataDetail?.endTime ? moment(Number(dataDetail?.endTime) * 1000).unix() : 0;
                                                        const newValue = !Helpers.isNullOrEmpty(value) ? moment(value).unix() : undefined;

                                                        if (!Helpers.isNullOrEmpty(valEndTime) && !Helpers.isNullOrEmpty(newValue) && newValue > Number(valEndTime)) {
                                                            valEndTime = moment(value).add(1, "day").unix();
                                                        }

                                                        setDataDetail(prev => ({ ...prev, startTime: newValue, endTime: valEndTime }));
                                                        setErrorDetail(prev => ({ ...prev, startTime: undefined, endTime: undefined }));

                                                        setDataSelectRooms([]);
                                                    },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    {/* Check out */}
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            required
                                            mode={props.mode}
                                            errorMessage={errorDetail?.endTime}
                                            value={Helpers.getDateValue(dataDetail?.endTime)}
                                            label={props.endTimeTitle || Strings.BOOKING.END_TIME}
                                            placeholder={`${Strings.Common.SELECT} ${props.endTimeTitle?.toLowerCase() || Strings.BOOKING.END_TIME.toLowerCase()}`}
                                            onChangeValue={(value: any) => {
                                                handleConfirmData({
                                                    isWhen: dataSelectRooms?.length > 0,
                                                    lable: `"${Strings.BOOKING.END_TIME}"`,
                                                    onCancle() {
                                                        setDataDetail(prev => ({ ...prev }));
                                                    },
                                                    onOk() {
                                                        let valStartTime = dataDetail?.startTime ? moment(Number(dataDetail?.startTime) * 1000).unix() : 0;
                                                        const newValue = !Helpers.isNullOrEmpty(value) ? moment(value).unix() : undefined;

                                                        if (!Helpers.isNullOrEmpty(valStartTime) && !Helpers.isNullOrEmpty(newValue) && newValue < Number(valStartTime)) {
                                                            valStartTime = moment(value).add(-1, "day").unix();
                                                        }

                                                        setDataDetail(prev => ({ ...prev, endTime: newValue, startTime: valStartTime }));
                                                        setErrorDetail(prev => ({ ...prev, endTime: undefined, startTime: undefined }));

                                                        setDataSelectRooms([]);
                                                    },
                                                });
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormField
                                            mode={props.mode}
                                            value={dataDetail?.bookingCode}
                                            disabled={!Helpers.isNullOrEmpty(dataDetail?.id)}
                                            placeholder={Strings.BOOKING.ENTER_BOOKING_CODE}
                                            label={props.codeTitle || Strings.BOOKING.BOOKING_CODE}
                                            onChangeValue={(value) => {
                                                setDataDetail(prev => ({ ...prev, bookingCode: value, }));
                                                setErrorDetail(prev => ({ ...prev, bookingCode: undefined }));
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormField
                                            multiline
                                            mode={props.mode}
                                            value={dataDetail?.note}
                                            label={Strings.Common.NOTE}
                                            placeholder={Strings.Common.INPUT_NOTE}
                                            onChangeValue={(value) => {
                                                setDataDetail(prev => ({ ...prev, note: value, }));
                                                setErrorDetail(prev => ({ ...prev, note: undefined }));
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputAddRoomSlot
                                            data={dataOccupancyRoom}
                                            isWhenShow={dataSelectRooms.length > 0}
                                            onChangeValue={(newData) => {
                                                hadleSetDataOccupancyRoom(newData);
                                                setDataSelectRooms([]);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Card>
                </Grid>

                {/* EXTRA INFO */}
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <Typography variant="h5">{Strings.BOOKING.EXTRA_INFO}</Typography>
                            <Box p={2}>
                                <Grid container spacing={2}>
                                    {/* Seller */}
                                    <Grid item xs={12} md={6}>
                                        <InputAddSeller
                                            mode={props.mode}
                                            data={dataDetail}
                                            setData={setDataDetail}
                                            error={errorDetail}
                                            setError={setErrorDetail}
                                        />
                                    </Grid>
                                    {/* Supplier */}
                                    <Grid item xs={12} md={6}>
                                        <InputAddSupplier
                                            mode={props.mode}
                                            data={dataDetail}
                                            setData={setDataDetail}
                                            error={errorDetail}
                                            setError={setErrorDetail}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <CardListItemRoom
                        mode={props.mode}
                        dataDetail={dataDetail}
                        dataOccupancyRoom={dataOccupancyRoom}

                        dataSelectRooms={dataSelectRooms}
                        setDataSelectRooms={setDataSelectRooms}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default BookingStayCreateUpdateContainer;
