import { IOwnerOrganization } from "commons/interfaces";
import Constants from "../../constants";
import IdentityService from "./identity.service";

class OrganizationService extends IdentityService {
    public getPaged = async (data?: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ORGANIZATION.GET_PAGED,
            query: data,
        });
        return result.data.result;
    };

    public getAll = async (data?: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ORGANIZATION.GET_ALL,
            query: data,
        });
        return result.data.result;
    };

    public getOrganizationByIds = async (ids: string[]) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORGANIZATION.GET_ORGANIZATION_BY_IDS,
            data: { "ids": ids },
        });
        return result.data.result;
    };

    public getDetailById = async (id: any) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ORGANIZATION.GET_DETAIL_BY_ID + `/${id}`,
        });
        return result.data.result;
    };

    public deleteById = async (id: any) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.ORGANIZATION.DELETE + `?id=${id}`,
        });
        return result.data;
    };

    public create = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORGANIZATION.CREATE,
            data,
        });
        return result.data;
    };

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORGANIZATION.UPDATE,
            data,
        });
        return result.data;
    };

    public getOwnedOrganization = async (query: { exceptCurrentService: boolean }): Promise<IOwnerOrganization[]> => {
        const result = await this.api.get({
            path: Constants.ApiPath.ORGANIZATION.OWNED_ORGANIZATION,
            query,
        });
        return result.data.result;
    };
}

export default OrganizationService;
