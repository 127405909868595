import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ModuleCodename } from "@maysoft/common-component-react";

import Screens from "constants/screens";
import Constants from "../../constants";

import useModule from "hooks/useModule";
import { DashboardLayout } from "layout";

const CodenameScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        code: searchParams.get("code"),
        group: searchParams.get("group"),
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.CODENAME_LIST,
            detail: Screens.CODENAME_EDIT,
        },
        keyOfStrings: "Codename",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <ModuleCodename {...moduleProps} />
        </DashboardLayout>
    );
};

export default CodenameScreen;
