import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IPropModuleContainer, KeyModuleScreen } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { setListPathName } from "store/slice/titleRoute.slice";

type KeyOf<T extends object> = Extract<keyof T, string>;

const useModule = <T>({
    request,
    pathName,
    keyOfStrings,
}: {
    request: T;
    pathName: { [key in KeyModuleScreen]?: string };
    keyOfStrings: KeyOf<typeof Strings>;
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const state = location?.state as any;
    const locationMode = state?.mode || Mode.View;

    const idDetail: string = searchParams.get("id");

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);

    const screenNameList = useMemo(() => pathName?.list || "", [pathName?.list]);
    const screenNameEdit = useMemo(
        () => pathName?.detail || pathName?.update || pathName?.create || "",
        [pathName?.detail, pathName?.update, pathName?.create]
    );

    const itemPathName = listPathName.find((el) => el.pathName === screenNameList);
    const isListMode = screenNameList.includes(window.location.pathname);

    const [mode, setMode] = useState<Mode>(locationMode);

    const keyModuleContainer = useMemo(() => {
        const result: any = {};
        for (const [key, value] of Object.entries(pathName)) {
            Object.assign(result, { [key]: { pathName: value } });
        }
        return result;
    }, [pathName]);

    const detailTitle = useMemo(() => {
        if (Helpers.isNullOrEmpty(idDetail)) return Strings[keyOfStrings].TITLE_CREATE_VIEW;
        return mode === Mode.View ? Strings[keyOfStrings].TITLE_DETAIL_VIEW : Strings[keyOfStrings].TITLE_UPDATE_VIEW;
    }, [idDetail, mode]);

    const detailRoute = useMemo(() => {
        const temp = [{ title: Strings[keyOfStrings].TITLE_MENU, route: screenNameList }];
        if (Helpers.isNullOrEmpty(idDetail)) {
            temp.push({
                title: Strings.Common.CREATE_NEW,
                route: "",
            });
        } else {
            temp.push({
                title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [idDetail, mode, screenNameList]);

    const title = useMemo(() => (isListMode ? Strings[keyOfStrings].TITLE_MENU : detailTitle), [isListMode, detailTitle]);
    const route = useMemo(() => (isListMode ? [{ title: Strings[keyOfStrings].TITLE_MENU, route: "" }] : detailRoute), [isListMode, detailRoute]);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            setMode(Mode.Create);
        } else {
            setMode(locationMode);
        }
    }, [idDetail]);

    const handleGoBack = () => {
        navigate(screenNameList + (itemPathName ? itemPathName.query : ""));
    };

    const requestGetPaged = useMemo(
        () => ({
            ...request,
            totalCount: itemPathName?.totalCount || 0,
        }),
        [request]
    );

    const moduleProps: IPropModuleContainer<T> = {
        // pathname
        keyModuleContainer,
        // getPaged props
        requestGetPaged,
        onGetPaged: ({ query, totalCount }) => {
            dispatch(setListPathName({ pathname: screenNameList, query, totalCount: totalCount }));
            navigate(screenNameList + query, { replace: true });
        },
        onNavigate: (data) => {
            const id = data.id ? `?id=${data.id}` : "";
            navigate(screenNameEdit + id, { state: { mode: data.mode } });
        },
        // getDetail props
        mode: mode,
        idDetail: idDetail,
        onGoBack: () => handleGoBack(),
        onChangeMode: (mode: Mode) => setMode(mode),
    };

    return {
        moduleProps,
        title,
        route,
    };
};

export default useModule;
