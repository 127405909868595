import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Card, Grid, IconButton } from "@mui/material";
import { FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import {
    IPropListContainer,
    DatePicker, Autocomplete, Box, Button, Chip,
    CustomPagination, DataTable, DataTableStatusCell,
    FormField, Typography, useCommonComponentContext,
} from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import OrderService from "services/sale/order.service";
import useDataOrderBooking from "./useDataOrderBooking.hook";
import RequestBookingService from "services/booking/requestBooking.service";
import useDataRequestBooking from "components/Booking/useDataRequestBooking.hook";

import { Mode } from "constants/enum";
import { IRecordBookingV2, IReqGetPagedBookingV2 } from "services/booking/requestBooking.service";



const requestBookingService = new RequestBookingService();

export type IDataRequestGetPaged = IReqGetPagedBookingV2 & { totalCount?: number; }

const OrderBookingListContainer = (props: IPropListContainer<IDataRequestGetPaged>
    & { hidenActionExport?: boolean; }
) => {

    const {
        userInfo,
        onError,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const {
        itineraryTypeList,
        getDataMapOrgaByIds,
        getDataMapOrderPriceByIds,
        getDataRequestStatusByBooking,
    } = useDataRequestBooking();

    const {
        paymentStatusListExtend,
        getDataPaymentStatusByBooking,
    } = useDataOrderBooking();

    const [model, setModel] = useState<{
        requestData?: IDataRequestGetPaged;
        requestDataTemp?: IDataRequestGetPaged;
    }>({});

    const [dataMapOrg, setDataMapOrg] = useState<Map<string, any>>(new Map());
    const [dataOrderPrice, setDataOrderPrice] = useState<Map<string, any>>(new Map());

    const [dataGetPaged, setDataGetPaged] = useState<IRecordBookingV2[]>([]);
    const [loadingDataTable, setLoadingDataTable] = useState<boolean>(false);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile?.organizationId)) {

            const newReq: IDataRequestGetPaged = {
                type: props?.requestGetPaged?.type,
                code: props?.requestGetPaged?.code,
                searchText: props?.requestGetPaged?.searchText,
                paymentStatus: props?.requestGetPaged?.paymentStatus,
                bookingEndTime: props?.requestGetPaged?.bookingEndTime,
                bookingStartTime: props?.requestGetPaged?.bookingStartTime,

                pageSize: props?.requestGetPaged?.pageSize,
                pageNumber: props?.requestGetPaged?.pageNumber,
                totalCount: props?.requestGetPaged?.totalCount,
            };

            getPagedBooking(newReq);
        }
    }, [props?.requestGetPaged, userInfo?.userProfile?.organizationId]);

    const getPagedBooking = async (req?: IDataRequestGetPaged) => {
        try {
            // onShowLoading();
            setLoadingDataTable(true);

            const pageSize = req?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(req?.pageNumber || 1, pageSize, req?.totalCount || 0) || 1;

            let requestData: any = {
                type: req?.type,
                code: req?.code,
                searchText: req?.searchText,
                paymentStatus: req?.paymentStatus,
                bookingEndTime: req?.bookingEndTime ?? moment().endOf("month").unix(),
                bookingStartTime: req?.bookingStartTime ?? moment().startOf("month").unix(),

                hasOrderId: 1,
                supplierCode: "000002",

                pageSize,
                pageNumber,
            };

            const result = await requestBookingService.getPagedV2({
                ...requestData,
                organizationId: userInfo?.userProfile?.organizationId,
            });

            const orderIds = [...result.items || []].map(el => el.orderId);
            if (orderIds.length > 0) {
                const newDataOrderPrice = await getDataMapOrderPriceByIds(orderIds);
                setDataOrderPrice(newDataOrderPrice);
            }

            const idOrgs = [...result.items || []].map(el => el.organizationId);
            if (idOrgs.length > 0) {
                const newDataOrg = await getDataMapOrgaByIds(idOrgs);
                setDataMapOrg(newDataOrg);
            }

            setDataGetPaged(result.items);

            setModel({
                requestData: { ...requestData, totalCount: result.totalCount },
                requestDataTemp: { ...requestData, totalCount: result.totalCount },
            });

            const query = `?${Helpers.handleFormatParams(requestData)}`;

            props?.onGetPaged({ query, totalCount: result.totalCount });

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            // onHideLoading();
            setLoadingDataTable(false);
        }
    };

    const exportCSV = async () => {
        Helpers.showConfirmAlert("Bạn chắc chắn muốn tải file về", async () => {
            try {
                onShowLoading();

                const result = await new OrderService().exportCsv({ ...model?.requestData, totalCount: undefined });

                const date = moment().format("HH_mm_DD_MM_YYYY");

                var fileName = `Order_Booking_${date}.csv`;
                const downloadUrl = URL.createObjectURL(result);
                const a = document.createElement("a");

                a.href = downloadUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

            } catch (error) {
                const e = Helpers.renderExceptionError(error);
                onError(e);
            } finally {
                onHideLoading();
            }
        });
    };

    // #region Filter Form
    const RenderFilterForm = () => (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DatePicker
                        label={"Thời gian"}
                        views={["month", "year"]}
                        placeholder={"Chọn thời gian"}
                        value={Helpers.getDateValue(model?.requestDataTemp?.bookingStartTime)}
                        onChangeValue={(value: any) => {
                            const valStartTime = !Helpers.isNullOrEmpty(value) ? moment(value).startOf("month").unix() : undefined;

                            const valEndTime = !Helpers.isNullOrEmpty(value) ? moment(value).endOf("month").unix() : undefined;

                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    bookingEndTime: valEndTime,
                                    bookingStartTime: valStartTime,
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        label={"Tên công ty"}
                        placeholder={"Nhập tên công ty"}
                        defaultValue={model.requestDataTemp?.searchText}
                        onBlur={(value: any) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    searchText: value,
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormField
                        label={"Mã đơn hàng"}
                        placeholder={"Nhập mã đơn hàng"}
                        defaultValue={model.requestDataTemp?.code}
                        onBlur={(value: any) => {
                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    code: value,
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Dịch vụ"}
                        placeholder={"Chọn dịch vụ"}
                        data={itineraryTypeList || []}
                        defaultValue={model.requestDataTemp?.type}
                        onChange={(value) => {
                            const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    type: newValue,
                                }
                            }));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        label={"Trạng thái"}
                        placeholder={"Chọn trạng thái"}
                        data={paymentStatusListExtend || []}
                        defaultValue={model.requestDataTemp?.paymentStatus}
                        onChange={(value) => {
                            const newValue = !Helpers.isNullOrEmpty(value) ? Number(value) : undefined;

                            setModel(prev => ({
                                ...prev,
                                requestDataTemp: {
                                    ...prev.requestDataTemp,
                                    paymentStatus: newValue,
                                }
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );

    // #region Chip Value Filter
    const RenderChipValueFilter = () => (
        <>
            {(
                !Helpers.isNullOrEmpty(model?.requestData?.type) ||
                !Helpers.isNullOrEmpty(model?.requestData?.code) ||
                !Helpers.isNullOrEmpty(model?.requestData?.searchText) ||
                !Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) ||
                !Helpers.isNullOrEmpty(model?.requestData?.bookingEndTime) ||
                !Helpers.isNullOrEmpty(model?.requestData?.bookingStartTime)
            ) &&
                <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                    {!Helpers.isNullOrEmpty(model?.requestData?.bookingStartTime) &&
                        !Helpers.isNullOrEmpty(model?.requestData?.bookingEndTime) &&
                        <Chip
                            label={"Thời gian"}
                            value={[
                                moment(Helpers.getDateValue(model?.requestData?.bookingStartTime)).format("DD/MM/YYYY"),
                                moment(Helpers.getDateValue(model?.requestData?.bookingEndTime)).format("DD/MM/YYYY")
                            ].join(" - ")}
                            onDelete={() => {
                                getPagedBooking({
                                    ...model.requestData,
                                    bookingEndTime: moment().endOf("month").unix(),
                                    bookingStartTime: moment().startOf("month").unix(),
                                });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.searchText) &&
                        <Chip
                            label={"Tên Công ty"}
                            value={model?.requestData?.searchText}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, searchText: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.code) &&
                        <Chip
                            label={"Mã đơn hàng"}
                            value={model?.requestData?.code}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, code: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.type) &&
                        <Chip
                            label={"Dịch vụ"}
                            value={[...itineraryTypeList || []].find(el => el.code === model?.requestData?.type)?.name || ""}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, type: undefined });
                            }}
                        />
                    }

                    {!Helpers.isNullOrEmpty(model?.requestData?.paymentStatus) &&
                        <Chip
                            label={"Trạng thái"}
                            value={[...paymentStatusListExtend || []].find(el => el.code === model?.requestData?.paymentStatus)?.name || ""}
                            onDelete={() => {
                                getPagedBooking({ ...model.requestData, paymentStatus: undefined });
                            }}
                        />
                    }
                </Box>
            }
        </>
    );

    // #region Data Columns
    const getDataRow = (row: IRecordBookingV2) => {
        let reservation_code = "";

        const extraInfo = Helpers.converStringToJson(row?.extraInfo);

        const valueConver = extraInfo?.LastTicketDate ? Helpers.convertDDMM_To_MMDD(extraInfo?.LastTicketDate) : undefined;

        const valueUnix = valueConver ? moment.utc(valueConver).unix() : undefined;

        if (extraInfo && !Helpers.isNullOrEmpty(extraInfo?.IssueTicketInfo)) {
            const issueTicketInfo = Helpers.converStringToJson(extraInfo?.IssueTicketInfo);
            reservation_code = issueTicketInfo?.Booking?.Itineraries?.[0]?.reservation_code;
        };

        return ({
            lastTicketDate: valueUnix,
            reservationCode: reservation_code,
            confirmStatus: Number(row?.confirmStatus),
            paymentStatus: Number(row?.paymentStatus),
            itineraryDetailConfirmStatus: Number(row?.itineraryDetailConfirmStatus),
        });
    };

    const dataColumns = useMemo(() => ([
        {
            Header: Strings.Common.ACTION, accessor: "action", width: "60px",
            Cell: (row: any) => (
                <IconButton color="info" onClick={() => {
                    props?.onNavigate({ id: row?.row?.original?.orderId, mode: Mode.View });
                }}>
                    <InfoOutlined />
                </IconButton>
            ),
        },
        {
            Header: Strings.Common.TIME, accessor: "createTime", width: "120px",
            Cell: (row: any) => {
                const newValue = Helpers.getDateValue(row?.value);
                return (
                    <Box display="grid">
                        <Typography variant="caption" fontWeight="bold" color="secondary">
                            {newValue ? moment(newValue).format("HH:mm DD/MM/YYYY") : "-"}
                        </Typography>
                    </Box>
                )
            },
        },
        {
            Header: Strings.ORDER.ORDER_CODE, accessor: "bookingCode", width: "80px",
            Cell: (row: any) => {
                const orderCode = dataOrderPrice.get(row?.row?.original?.orderId)?.orderCode || "-";
                return <>{orderCode}</>
            }
        },
        {
            Header: "Dịch vụ", accessor: "bookingDetailType", width: "80px",
            Cell: (row: any) => (
                <>{itineraryTypeList.find(el => el.code === row.value)?.name || "-"}</>
            ),
        },
        {
            Header: "Booking Code", accessor: "externalBookingCode", width: "90px",
        },
        {
            Header: "Trạng thái đặt chỗ", accessor: "paymentStatus",
            Cell: (row: any) => {
                const newReq = getDataRow(row?.row?.original);

                const newdata = getDataRequestStatusByBooking(newReq);

                return (<DataTableStatusCell data={newdata} />)
            },
        },
        {
            Header: "Tên công ty", accessor: "organizationId", width: "200px",
            Cell: (row: any) => <>{dataMapOrg.get(row.value)?.name || "-"}</>
        },
        {
            Header: "Số tiền", accessor: "totalAmount", width: "120px",
            Cell: (row: any) => {
                const currency = row?.row?.original?.currency || userInfo?.userProfile?.currency;
                let totalAmount = (row?.value || 0) + (row?.row?.original?.serviceFee || 0) + (0);

                if (!Helpers.isNullOrEmpty(row?.row?.original?.orderId)) {
                    totalAmount = dataOrderPrice.get(row?.row?.original?.orderId)?.amount || 0;
                };

                const newReq = getDataRow(row?.row?.original);

                const valueColor = getDataPaymentStatusByBooking(newReq)?.color as any;

                return (
                    <Typography variant="caption" fontWeight="bold" color={valueColor}>
                        {`${(valueColor === "error") ? "-" : ""} ${Helpers.formatCurrency(totalAmount)} ${currency}`}
                    </Typography>
                );
            },
        },
        {
            Header: Strings.Common.STATUS, accessor: "status", width: "150px",
            Cell: (row: any) => {
                const newReq = getDataRow(row?.row?.original);

                const newdata = getDataPaymentStatusByBooking(newReq);

                return (<DataTableStatusCell data={newdata} />);
            }
        },
    ]), [
        dataMapOrg,
        dataOrderPrice,
        itineraryTypeList,
        userInfo?.userProfile?.currency,
        getDataMapOrderPriceByIds,
        getDataRequestStatusByBooking,
        getDataPaymentStatusByBooking,
    ]);

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <RenderChipValueFilter />

                    <Card>
                        <Box sx={{
                            padding: 2,
                            width: '100%',
                            overflow: "auto",
                        }}>
                            <DataTable
                                showFooter={false}
                                loading={loadingDataTable}
                                rowPerPageOptions={[20, 50, 100]}
                                rowPerPage={model.requestData?.pageSize}
                                totalCount={model.requestData?.totalCount || 0}
                                pageNumber={model.requestData?.pageNumber || 1}
                                onChangePageSize={(pageSize) => { getPagedBooking({ ...model.requestData, pageSize }) }}
                                onChangePageNumber={(pageNumber) => { getPagedBooking({ ...model.requestData, pageNumber }) }}

                                table={{
                                    rows: dataGetPaged,
                                    columns: dataColumns,
                                }}

                                filterForm={<RenderFilterForm />}

                                onFilter={() => {
                                    getPagedBooking({ ...model.requestDataTemp, pageNumber: 1, })
                                }}
                                onReset={() => {
                                    getPagedBooking({
                                        pageNumber: 1,
                                        pageSize: Constants.ROW_PER_PAGE,
                                    });
                                }}
                                onCloseFilter={() => {
                                    const newData = { ...model.requestData };
                                    setModel({
                                        ...model,
                                        requestDataTemp: newData
                                    });
                                }}

                                leftElement={
                                    <>
                                        {
                                            !props.hidenActionExport &&
                                            <Button
                                                color="success"
                                                disabled={loadingDataTable}
                                                onClick={() => { exportCSV(); }}
                                            >
                                                <FileDownloadOutlined /> &nbsp; {Strings.Common.DOWNLOAD}
                                            </Button>
                                        }
                                    </>
                                }
                            />
                            <CustomPagination
                                isList
                                rowsPerPageOptions={[20, 50, 100]}
                                page={model?.requestData?.pageNumber}
                                pageSize={model?.requestData?.pageSize}
                                total={model?.requestData?.totalCount}
                                onChangePage={(value) => {
                                    getPagedBooking({ ...model.requestData, pageNumber: value })
                                }}
                                onChangePageSize={(value) => {
                                    getPagedBooking({ ...model.requestData, pageSize: value })
                                }}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OrderBookingListContainer;