import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "constants/index";
import Screens from "constants/screens";
import RequestBookingModule from "components/Booking";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { setListPathName } from "store/slice/titleRoute.slice";
import { ItineraryType, Mode, SupplierCode } from "constants/enum";
import { IDataRequestGetPaged } from "components/Booking/listBooking";



const FlightBookingScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const idDetail: string = searchParams.get("id");
    const modeList = (Screens.FLIGHT_BOOKING_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const itemP = listPathName.find(el => el.pathName === Screens.FLIGHT_BOOKING_LIST);

    const [model, setModel] = useState<{ mode: Mode; title: string; route: any[] }>({
        mode: Mode.View,
        title: Strings.BOOKING.TITLE_DETAIL,
        route: [
            { title: Strings.BOOKING.TITLE_MENU_FLIGHT, route: Screens.FLIGHT_BOOKING_LIST },
            { title: Strings.Common.DETAIL, route: "" },
        ],
    });

    const converStringNumber = (value: string | null | undefined) => {
        return Helpers.isNullOrEmpty(value) ? undefined : Number(value)
    }

    const [requestData] = useState<IDataRequestGetPaged>({
        totalCount: itemP?.totalCount,
        code: searchParams.get("code"),
        buyer: searchParams.get("buyer"),
        seller: searchParams.get("seller"),
        searchText: searchParams.get("searchText"),
        supplierCode: searchParams.get("supplierCode"),
        type: converStringNumber(searchParams.get("type")),
        paymentStatus: converStringNumber(searchParams.get("paymentStatus")),
        requestStatus: converStringNumber(searchParams.get("requestStatus")),
        confirmStatus: converStringNumber(searchParams.get("confirmStatus")),

        bookingStartTime: !Helpers.isNullOrEmpty(searchParams.get("bookingStartTime"))
            ? searchParams.get("bookingStartTime") : moment().startOf("month").unix(),

        bookingEndTime: !Helpers.isNullOrEmpty(searchParams.get("bookingEndTime"))
            ? searchParams.get("bookingEndTime") : moment().endOf("month").unix(),

        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    useEffect(() => {
        if (modeList) {
            return;
        } else {
            if (Helpers.isNullOrEmpty(idDetail)) {
                handleChangeMode(Mode.Create);
            } else {
                handleChangeMode(pramsMode || Mode.Update);
            }
        }
    }, [modeList, pramsMode, idDetail]);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.FLIGHT_BOOKING_LIST);
        navigate(Screens.FLIGHT_BOOKING_LIST + (itemP?.query ? itemP?.query : ""));
    };

    const handleChangeMode = (valueMode: Mode) => {
        if (valueMode === Mode.Create) {
            handleGoBack();
        }
        if (valueMode === Mode.Update) {
            setModel({
                mode: Mode.View,
                title: Strings.BOOKING.TITLE_DETAIL,
                route: [
                    { title: Strings.BOOKING.TITLE_MENU_FLIGHT, route: Screens.FLIGHT_BOOKING_LIST },
                    { title: Strings.Common.DETAIL, route: "" },
                ],
            });
            navigate(Screens.FLIGHT_BOOKING_VIEW + `?id=${idDetail}&mode=${Mode.View}`, { replace: true });
        }
        if (valueMode === Mode.View) {
            setModel({
                mode: Mode.View,
                title: Strings.BOOKING.TITLE_DETAIL,
                route: [
                    { title: Strings.BOOKING.TITLE_MENU_FLIGHT, route: Screens.FLIGHT_BOOKING_LIST },
                    { title: Strings.Common.DETAIL, route: "" },
                ],
            });
            navigate(Screens.FLIGHT_BOOKING_VIEW + `?id=${idDetail}&mode=${Mode.View}`, { replace: true });
        }
    }

    return (
        <DashboardLayout
            isPermission={true}
            title={modeList ? Strings.BOOKING.TITLE_LIST : model.title}
            route={modeList ? [{ title: Strings.BOOKING.TITLE_MENU_FLIGHT, route: "" }] : model.route}
        >

            <RequestBookingModule
                keyModuleContainer={{
                    list: { pathName: Screens.FLIGHT_BOOKING_LIST },
                    detail: { pathName: Screens.FLIGHT_BOOKING_VIEW },
                }}

                hidenActionCreate
                hidenActionUpdate

                type={ItineraryType.Flight}
                supplierCode={SupplierCode.Deeptech}

                requestGetPaged={requestData}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.FLIGHT_BOOKING_LIST, query, totalCount }));
                    navigate(Screens.FLIGHT_BOOKING_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    if (data.id) {
                        if (data.mode === Mode.View) {
                            navigate(Screens.FLIGHT_BOOKING_VIEW + `?id=${data?.id}&mode=${data.mode}`);
                        } else {
                            navigate(Screens.FLIGHT_BOOKING_VIEW + `?id=${data?.id}&mode=${data.mode}`);
                        }
                    } else {
                    }
                }}

                mode={model.mode}
                idDetail={idDetail}
                onGoBack={() => handleGoBack()}
                onChangeMode={(mode: Mode) => { handleChangeMode(mode) }}
            />
        </DashboardLayout>
    );
};

export default FlightBookingScreen;
