import Helpers from "commons/helpers";
import Constants from "../../constants";
import BaseService from "./base.service";

class AdministrativeDivisionService extends BaseService {
    public getCountries = async (data?: {
        type?: number,
        name?: string,
        parentDivision?: string,
    }) => {
        const newdata = data ? Helpers.handleFormatParams(data) : "";
        const query = Helpers.isNullOrEmpty(newdata) ? "" : `?${newdata}`;
        const result = await this.api.get({
            path: Constants.ApiPath.ADMINISTRATIVE_DIVISION.GET_COUNTRIES + query,
        });
        return result.data.result;
    };

    public getCities = async () => {
        const result = await this.api.get({
            path: Constants.ApiPath.ADMINISTRATIVE_DIVISION.GET_CITY,
        });
        return result.data.result;
    };

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.ADMINISTRATIVE_DIVISION.GET_PAGED}?${data}`,
        });
        return result.data.result;
    };

    public getListByParentId = async (parentId: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ADMINISTRATIVE_DIVISION.GET_LIST_BY_PARENT_ID + `/${parentId}`,
        });
        return result.data.result;
    };

    public getDetail = async (id: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.ADMINISTRATIVE_DIVISION.GET_DETAIL + `/${id}`,
        });
        return result.data.result;
    };

    public delete = async (data: any) => {
        const result = await this.api.delete({
            path: `${Constants.ApiPath.ADMINISTRATIVE_DIVISION.DELETE}/${data}`,
        });

        return result.data;
    };

    public update = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.ADMINISTRATIVE_DIVISION.UPDATE}`,
            data,
        });
        return result.data;
    };

    public create = async (data: any) => {
        const result = await this.api.post({
            path: `${Constants.ApiPath.ADMINISTRATIVE_DIVISION.CREATE}`,
            data,
        });
        return result.data;
    };
}

export default AdministrativeDivisionService;
