
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';

import { Box, Typography } from "@maysoft/common-component-react";

import { ICodename } from "commons/interfaces";
import { IOrderHistorie } from "services/sale/order.service";



const CardHistoriesOrderBooking = ({
    orderCode,
    dataMapUser,
    listOrderStatus,
    dataOrderHistories,
}: {
    orderCode: string;
    listOrderStatus: ICodename[];
    dataMapUser: Map<string, string>;
    dataOrderHistories?: IOrderHistorie[];
}) => {

    return (
        <Box>
            <Box textAlign={"center"}>
                <Typography variant="button" fontWeight="bold">
                    {`ĐH: ${orderCode}`}
                </Typography>
            </Box>
            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}
            >
                {
                    dataOrderHistories.map((item, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                <TimelineDot color="info" />
                                {
                                    ((index + 1) < dataOrderHistories.length)
                                    && <TimelineConnector sx={{ backgroundColor: "#1A73E8" }} />
                                }
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box display="flex" flexDirection="column" width={"100%"}>
                                    <Typography variant="button" color="info">
                                        {listOrderStatus?.find(el => el.code === item.orderStatus)?.name || "-"}
                                    </Typography>
                                    <Typography variant="button">
                                        NV:&nbsp;{dataMapUser.get(item.createUser)}
                                    </Typography>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    ))
                }
            </Timeline>
        </Box>
    )
};

export default CardHistoriesOrderBooking;


