import { CabinClass } from "constants/enum"
import { useMemo } from "react"

const useCabinClassCodenames = (input?: { includes?: CabinClass[] }) => {
    return useMemo(() => {
        const includes = input?.includes ?? [CabinClass.EconomyClass,  CabinClass.BusinessClass];
        return [
            { code: CabinClass.EconomyClass, name: "Phổ thông" },
            { code: CabinClass.PremiumEconomyClass, name: "Phổ thông đặc biệt" },
            { code: CabinClass.BusinessClass, name: "Thương gia" },
            { code: CabinClass.FirstClass, name: "Hạng nhất" },
        ].filter(i => includes.includes(i.code))
    }, [input])
}

export default useCabinClassCodenames;