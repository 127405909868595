import Helpers from "commons/helpers";
import BookingService from "./booking.service";
import { IGetPaged, IMultiLanguageContent } from "commons/interfaces";


interface IRequestGetSearch {
    maxPrice?: number;
    minPrice?: number;
    amenityIds?: string[];
    orderby?: string;
    pageSize?: number;
    pageNumber?: number;

    language: string;
    propertyIds: string[];
    checkinTime: string | number;
    checkoutTime: string | number;
    occupancy: {
        adultSlot: number;
        childrenOld: number[];
    }[];
}

export interface IRequestGetDetail {
    language: string;
    propertyId: string;
    checkinTime: string | number;
    checkoutTime: string | number;
    occupancy: {
        adultSlot: number;
        childrenOld: number[];
    }[];
}

export interface IDetailHotel {
    propertyId: string;
    name: string;
    address: IAddress;
    phone: string;
    faxNumber: any;
    taxNumber: any;
    photos: IPhoto[];
    attributes: IAttribute[];
    rooms: IRoom[];
}

export interface IAddress {
    addressLine: string;
    addressLine2: any;
    cityName: string;
    provinceName: string;
    countryName: any;
    countryCode: string;
    postalCode: string;
    longtitude: number;
    latitude: number;
}

export interface IPhoto {
    photoUrl: string;
    photoId: string;
    displayOrder: number;
    pixels: string;
    tag: string;
}

export interface IAttribute {
    sourceType: number;
    valueType: number;
    attributeCode: string;
    attributeName: IMultiLanguageContent;
    attributeValue: string;
    referenceId?: string;
    tags?: string[];
}

export interface IRoom {
    name: string;
    referenceId: string;
    rates: IRate[];
    photos: IPhoto[];
    attributes: IAttribute[];

    //custom
    quantity: number;
    selectedRate: string;
}

export interface IRate {
    name: string;
    bedId: string;
    referenceId: string;
    refunable: boolean;
    available: number;
    cancelPenalties: ICancelPenalty[];
    nonRefunableRanges: any[];
    prices: IPrice[];
}

export interface ICancelPenalty {
    startTime: string;
    endTime: string;
    nights: string;
    currency: string;
    percent: any;
    amount: any;
}

export interface IPrice {
    occupancy: string;
    totals: ITotals;
}

export interface ITotals {
    inclusive: ITotalPrice; //- provides the total price including taxes and fees. This does not include property collected fees such as resort, mandatory taxes, and mandatory fees.
    exclusive: ITotalPrice; //- provides the total price excluding taxes and fees.
    propertyInclusive: ITotalPrice; // - provides the total price including taxes, fees, and property collected fees such as resort, mandatory taxes, and mandatory fees.
    inclusiveStrikethrough: ITotalPrice; // - provides the tax inclusive total price with any property funded discounts added back. Can be used to merchandise the savings due to a discount.
    strikethrough: ITotalPrice; // - provides the tax exclusive total price with any property funded discounts added back. Can be used to merchandise the savings due to a discount.

    propertyInclusiveStrikethrough: ITotalPrice; // - provides the tax, fees, and property collected fees inclusive total price with any property funded discounts added back. Can be used to merchandise the savings due to a discount.
    marketingFee: ITotalPrice; // - provides the potential owed earnings per transaction.
    grossProfit: ITotalPrice; // - provides the estimated gross profit per transaction.
    minimumSellingPrice: ITotalPrice; // - provides the minimum selling price.
    propertyFees: ITotalPrice; // - provides the total of the fees collected by the property.
}

export interface ITotalPrice {
    requestCurrency: ICurrencyValue;
    billingCurrency: ICurrencyValue;
}

export interface ICurrencyValue {
    value: number;
    currency: string;
}

class PropertyContentService extends BookingService {
    public search = async (req: IRequestGetSearch): Promise<IGetPaged<IDetailHotel>> => {
        let amenityIds: string[] = [];
        req.amenityIds?.forEach((val, index) => {
            amenityIds.push(`amenityIds[${index}]=${val}`);
        });

        let propertyIds: string[] = [];
        req.propertyIds.forEach((val, index) => {
            propertyIds.push(`propertyIds[${index}]=${val}`);
        });

        let occupancys: string[] = [];
        req.occupancy.forEach((item, index) => {
            occupancys.push(`occupancy[${index}].adultSlot=${item.adultSlot}`);
            item.childrenOld?.forEach((el, i) => {
                occupancys.push(`occupancy[${index}].childrenOld[${i}]=${el}`);
            });
        });

        let query = "";

        query = query + `language=${req.language}`;

        if (!Helpers.isNullOrEmpty(req.minPrice)) {
            query = query + `&minPrice=${req.minPrice}`;
        }
        if (!Helpers.isNullOrEmpty(req.maxPrice)) {
            query = query + `&maxPrice=${req.maxPrice}`;
        }
        if (!Helpers.isNullOrEmpty(req.pageNumber)) {
            query = query + `&pageNumber=${req.pageNumber}`;
        }
        if (!Helpers.isNullOrEmpty(req.pageSize)) {
            query = query + `&pageSize=${req.pageSize}`;
        }
        if (!Helpers.isNullOrEmpty(req.orderby)) {
            query = query + `&orderby=${req.orderby}`;
        }

        query = query + `&checkinTime=${req.checkinTime}`;
        query = query + `&checkoutTime=${req.checkoutTime}`;

        query = query + `&${propertyIds.join("&")}`;
        query = query + `&${occupancys.join("&")}`;

        if (amenityIds.length > 0) {
            query = query + `&${amenityIds.join("&")}`;
        }

        const result = await this.api.get({
            path: `/PropertyContent/Search?${query}`
        });
        return result.data.result;
    };

    public detail = async (req: IRequestGetDetail): Promise<IDetailHotel> => {
        let occupancys: string[] = [];
        req.occupancy.forEach((item, index) => {
            occupancys.push(`occupancy[${index}].adultSlot=${item.adultSlot}`);
            item.childrenOld?.forEach((el, i) => {
                occupancys.push(`occupancy[${index}].childrenOld[${i}]=${el}`);
            });
        });

        let query = "";

        query = query + `language=${req.language}`;
        query = query + `&propertyId=${req.propertyId}`;
        query = query + `&checkinTime=${req.checkinTime}`;
        query = query + `&checkoutTime=${req.checkoutTime}`;
        query = query + `&${occupancys.join("&")}`;

        const result = await this.api.get({
            path: `/PropertyContent/Detail?${query}`
        });
        return result.data.result;
    };
};

export default PropertyContentService;
