import { IBookingDetailAmend, IBookingDetailPopupUser, IService } from "../../interfaces";
import DeeptechAccordion from "../components/DeeptechAccordion";
import ContentBox from "../components/ContentBox";
import { Chip } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";
import AdditionalServiceItem from "./AdditionalServiceItem";
import { ICodename } from "commons/interfaces";
import AddIcon from "@mui/icons-material/Add";
import FlightIcon from "@mui/icons-material/Flight";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { BookingHelpers } from "commons/bookingHelpers";
import { useRef } from "react";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";

const UserAccordionForAdditionalServices = <D extends IBookingDetailPopupUser>(props: {
    data: D;
    serviceCodenames: ICodename[];
    services?: IService[];
    errors?: Partial<Record<string, string>>;
    onAddService: (user: IBookingDetailPopupUser, detail: IBookingDetail) => void;
    onUpdateService?: (
        newService: IService,
        keyChanged: keyof IService
    ) => void;
    onRemoveService?: (service: IService, user: IBookingDetailPopupUser, detail: IBookingDetail) => void;
}) => {
    const { isUserBookingDetailRefunded, calculateRemainingAdditionalServices, currency, getBookingDetailsByUserId } = useAddMoreBookingServiceContext();
    const { onUpdateService, data: user, errors = {}, services = [], serviceCodenames, onAddService, onRemoveService } = props;
    const getServicesByDetail = (detail: IBookingDetail) => {
        return services.filter((service) => service.bookingDetailId === detail.id);
    }
    const getErrorsByServiceItem = (service:IService) => {
        return Object.entries(errors).reduce((prev, [key, value]) => {
            if (key.startsWith(service._id)) {
                prev[key] = value;
            }

            return prev;
        }, { } as typeof errors);
    }

    const countByDetail = useRef<Record<string, number>>({});

    const renderReachLimitActions = () => {
        return (
            <Typography variant="caption" color="error">
                {Strings.BOOKING.ADDITION_SERVICE_REACH_LIMIT}
            </Typography>
        )
    }
    const renderAddMoreServiceItemActions = ({ detail }: { detail: IBookingDetailAmend }) => {
        return onAddService && (
            <div>
                <Chip
                    color="primary"
                    variant="outlined"
                    sx={{ border: "none" }}
                    label={
                        <Typography
                            variant="button"
                            color="primary"
                            fontWeight="bold"
                        >
                            {Strings.BOOKING.ADDITION_SERVICE_ADD_SERVICE_TEXT}
                        </Typography>
                    }
                    icon={<AddIcon sx={{ fontSize: 14 }} />}
                    onClick={() => onAddService(user, detail)}
                />
            </div>
        )
    }
    return (
        <DeeptechAccordion
            data={user}
            renderContent={(data) => 
                getBookingDetailsByUserId(data.id).map(detail => {
                    const { arrivalPlace, departPlace, carrierMarketing } = BookingHelpers.getFlightExtraInfo(detail, data.id);
                    const servicesByDetail = getServicesByDetail(detail);

                    const newCount = servicesByDetail.length;
                    const oldCount = countByDetail.current[detail.id] || 0;
                    countByDetail.current[detail.id] = newCount;
                    const isAddNewAdditionService = newCount > oldCount;

                    const remainServices = calculateRemainingAdditionalServices(data, detail) - newCount;
                    const isReachLimit = remainServices <= 0;

                    const isRefunded = isUserBookingDetailRefunded(data.id, detail.id);

                    return (
                        <ContentBox
                            key={detail.id}
                            title={
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                    px={2}
                                    py={1}
                                    alignItems="center"
                                >
                                    <FlightIcon fontSize="small" />
                                    <Typography variant="button" fontWeight="bold">
                                        {Strings.BOOKING.FLIGHT} {departPlace}&nbsp;&rarr;&nbsp;{arrivalPlace}
                                    </Typography>
                                    {carrierMarketing?.logoUrl && <img style={{ opacity: 0.8 }} src={carrierMarketing.logoUrl} alt={carrierMarketing?.name} width="30px" />}
                                    {isRefunded && (
                                        <Box ml={3}>
                                            <Chip label={Strings.BOOKING.REFUND_POPUP_REFUNDED_CHIP_LABEL} color="primary" size={"small"} />
                                        </Box>
                                    )}
                                </Box>
                            }
                        >
                            {!isRefunded ? (
                                <>
                                    <Typography variant="button" fontWeight="bold">
                                        {Strings.BOOKING.ADDITION_SERVICE_SERVICE_TEXT}
                                    </Typography>
                                    <Box display="flex" flexDirection="column" gap={1}>
                                        {servicesByDetail.map((service, index) => {
                                            const errorsByServiceItem = getErrorsByServiceItem(service);
                                            const isLatestItem = index === newCount - 1;
                                            return (
                                                <AdditionalServiceItem
                                                    key={service._id}
                                                    serviceCodenames={serviceCodenames}
                                                    data={service}
                                                    deletable={true}
                                                    error={errorsByServiceItem}
                                                    onChange={onUpdateService}
                                                    shouldFocus={isLatestItem && isAddNewAdditionService}
                                                    onRemove={onRemoveService ? () => onRemoveService(service, data, detail) : undefined}
                                                    currency={currency}
                                                />
                                            )
                                        })}
                                    </Box>
                                    {isReachLimit && renderReachLimitActions()}
                                    {!isReachLimit && renderAddMoreServiceItemActions({ detail })}
                                </>
                            ) : null}
                        </ContentBox>
                    )
                })
            }
        />
    );
};

export default UserAccordionForAdditionalServices;
