import { Box, Typography } from "@maysoft/common-component-react";
import OutlinedSelectBox from "./OutlinedSelectBox";
import { PaymentType } from "constants/enum";
import usePaymentMethodCodenames from "hooks/usePaymentMethodCodenames";


const PaymentMethodSelector = ({
    title,
    value,
    onChange,
    renderChilrenByPaymentMethod,
}: {
    title: string;
    value?: PaymentType;
    onChange?: (newValue: any) => void;
    renderChilrenByPaymentMethod?: (value: PaymentType) => React.ReactNode;
}) => {
    const paymentMethods = usePaymentMethodCodenames();
    return (
        <Box display="flex" gap={1} flexDirection="column">
            <Typography variant="button" fontWeight="medium">
                {title}
            </Typography>
            <OutlinedSelectBox
                data={paymentMethods}
                placeholder="Chọn phương thức thanh toán"
                initialValue={value ?? ""}
                onChangeValue={onChange}
            />
            {renderChilrenByPaymentMethod?.(value)}
        </Box>
    );
};

export default PaymentMethodSelector;