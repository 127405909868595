import { Typography, Box } from "@maysoft/common-component-react";
import moment from "moment";

const OperationHistorySectionTitle = (props: { title: string, index?: number, updateTime?: number; fullName: string }) => {
    const { title, index, updateTime, fullName } = props;

    const renderUpdaterText = () => {
        if (updateTime && fullName) {
            return <Typography variant="caption">({fullName} đã cập nhật booking {updateTime ? `lúc ${moment(updateTime * 1000).format("HH:mm DD/MM/YYYY")}` : undefined})</Typography>
        }
        if (updateTime && !fullName) {
            return <Typography variant="caption">(Cập nhật booking {updateTime ? `lúc ${moment(updateTime * 1000).format("HH:mm DD/MM/YYYY")}` : undefined})</Typography>
        }
        if (!updateTime && fullName) {
            return <Typography variant="caption">({fullName} đã cập nhật)</Typography>
        }

        return null;
    }

    return <Box display="flex" flexDirection="row" gap={2} alignItems="center">
        <Typography variant="button" fontWeight="bold" color="primary">{index ? `${index}. ` : ""}{title}</Typography>
        {renderUpdaterText()}
    </Box>
}

export default OperationHistorySectionTitle;