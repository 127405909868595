import { Box, SelectBox } from "@maysoft/common-component-react";
import { SelectProps } from "@mui/material";
import { ICodename } from "commons/interfaces";
import { Mode } from "constants/enum";
import React from "react";

interface OutlinedSelectBoxProps extends SelectProps {
    mode?: Mode;
    data: ICodename[];
    initialValue?: any;
    errorMessage?: string;
    onChangeValue?: (value: ICodename["code"]) => void;
}

const OutlinedSelectBox: React.FC<OutlinedSelectBoxProps> = (props) => {
    return (
        <Box
            sx={{
                "& .MuiInputLabel-root": {
                    top: "-5px",
                    left: "14px",
                    lineHeight: "1.3",
                },
            }}
        >
            {/* @ts-ignore */}
            <SelectBox
                style={{ padding: "1.4rem 0" }}
                variant="outlined"
                placeholder="Chọn"
                {...props}
            />
        </Box>
    );
};

export default OutlinedSelectBox;
