import Constants from "constants/index";
import BaseService from "./base.service";
import Helpers from "commons/helpers";
import { IGetPaged } from "commons/interfaces";

export interface ITransportHubServiceItem {
    id: string
    type: number
    code: string
    name: string
    addressId: string
    countryCode: string
    selected: boolean
    extraInfo: string
    extraInfoParsed?: ITransportHubServiceItemExtraInfo;
}
export interface ITransportHubServiceItemExtraInfo {
    $id: string
    Id: string
    Code: string
    Name: string
    City: string
    Timezone: string
    NameEn: string
    Country: string
    CityCode: string
    CityName: string
    CityCountry: string
    CityNameEn: string
    CountryName: string
    CountryCode: string
    CountryPhoneCode: string
    NameVn: string
}

class TransportHubService extends BaseService {
    async getPaged(data: {
        type?: number;
        countryCode?: string;
        selectedCodes?: string[];
        searchText?: string;
        pageNumber?: number;
        pageSize?: number;
    }): Promise<IGetPaged<ITransportHubServiceItem>> {
        const result = await this.api.get({
            path: Constants.ApiPath.TRANSPORT_HUB.GET_PAGED.concat(`?${Helpers.handleFormatParams(data)}`),
        });
        return result.data.result;
    }
};

export default TransportHubService;