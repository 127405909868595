import Helpers from "commons/helpers";
import { IBookingDetail, IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { useCallback, useState, useRef, useMemo } from "react";
import ServiceFeeSetting, { ICalculateServiceFeeBookingDetailRequest, ICalculateServiceFeeResponse } from "services/sale/serviceFeeSetting";
import { IBookingDetailPopupUser, ITransferBookingDetailViewModel } from "../../interfaces";
import { BookingHelpers } from "commons/bookingHelpers";
import { PaymentType } from "constants/enum";
interface Temp {
    detailId: string;
    userId: string;
    quantity: number;
}

const serviceFeeSetting = new ServiceFeeSetting();
const useLoadBookingDetailServiceFee = (data: IDetailBooking) => {
    const [serviceFeeByBookingDetailId, setServiceFeeByBookingDetailId] = useState<Record<string, ICalculateServiceFeeResponse>>({});
    const totalServiceFee = useMemo(() => Object.values(serviceFeeByBookingDetailId).reduce((prev, c) => prev + c.fee, 0), [serviceFeeByBookingDetailId]);
    const [isLoadingServiceFee, setIsLoadingServiceFee] = useState(false);
    const { organizationId } = data;

    const latestServiceFeeIdRef = useRef<null | string>(null);
    const onLoadServiceFee = useCallback(
        async (temp: Temp[], paymentMethod: PaymentType) => {
            const currentId = Helpers.generateRandomId();
            latestServiceFeeIdRef.current = currentId;
            try {
                setIsLoadingServiceFee(true);
                const newServiceFees =
                    await serviceFeeSetting.calculateServiceFeeExtra({
                        bookingDetailRequest: getNewBookingDetailRequests(temp),
                        organizationId: organizationId,
                        paymentMethod,
                    });
                if (latestServiceFeeIdRef.current !== currentId) {
                    // prevent update old service setting fee
                    return;
                }
                setServiceFeeByBookingDetailId(
                    newServiceFees.reduce((c, n) => {
                        c[n.bookingDetailId] = n;
                        return c;
                    }, {} as Record<string, ICalculateServiceFeeResponse>)
                );
                setIsLoadingServiceFee(false);
            } catch (e) {
                setIsLoadingServiceFee(false);
                // TODO: Do something when failed to load service fee
                if (latestServiceFeeIdRef.current !== currentId) {
                    // prevent update old service setting fee
                    return;
                }
                Helpers.handleException(e);
            }

            function getNewBookingDetailRequests(newChecked: Temp[]) {
                const bookingDetailMapByBookingDetailId = data.bookingDetails.reduce((prev, c) => {
                    prev[c.id] = c;
                    return prev;
                }, {} as Record<string, IBookingDetail>);
                const detailRequestMapByDetailId = newChecked.reduce((prev, c) => {
                    const bookingDetailItem = bookingDetailMapByBookingDetailId[c.detailId];
                    const detailId = bookingDetailItem.id;
                    const { isInternational = true } = BookingHelpers.getFlightExtraInfo(bookingDetailItem, c.userId);
                    if (!prev[detailId]) {
                        prev[detailId] = {
                            bookingDetailId: bookingDetailItem.id,
                            bookingId: bookingDetailItem.bookingId,
                            quantity: 0,
                            amount: bookingDetailItem.amount,
                            unitPrice: bookingDetailItem.amount,
                            extraInformation: bookingDetailItem.extraInfo,
                            feeCode: undefined,
                            itemId: undefined,
                            isInternational,
                        }
                    }
                    prev[c.detailId].quantity += c.quantity;
                    return prev;
                }, { } as Record<string, ICalculateServiceFeeBookingDetailRequest>);
                return Object.values(detailRequestMapByDetailId);
            }
        },
        [organizationId, data.bookingDetails]
    );

    const [checked, setChecked] = useState<Temp[]>([]);
    const onCalculateServiceFeeForRefund = useCallback(
        async (
            _: boolean,
            user: IBookingDetailPopupUser,
            bookingDetailItem: IBookingDetail,
            paymentType: PaymentType,
        ) => {
            const bookingDetailItemId = bookingDetailItem.id
            let needCalculateNewServiceFee = false;
            let newChecked = [] as typeof checked;
            setChecked((prev) => {
                newChecked = prev;

                const existed = prev.find(
                    (c) => c.detailId === bookingDetailItemId && c.userId === user.id
                );
                if (existed) {
                    needCalculateNewServiceFee = true;
                    newChecked = prev.filter((i) => i !== existed);
                } else {
                    needCalculateNewServiceFee = true;
                    newChecked = [
                        ...prev,
                        { detailId: bookingDetailItemId, userId: user.id, quantity: 1 },
                    ];
                }
                return newChecked;
            });

            if (needCalculateNewServiceFee) {
                onLoadServiceFee(newChecked, paymentType);
            }
        },
        [onLoadServiceFee]
    );
    const onCalculateServiceFeeForAddAdditionalService = useCallback(
        async (
            user: IBookingDetailPopupUser,
            bookingDetailItem: IBookingDetail,
            itemIdsSizeByBookingDetailId: number,
            paymentType: PaymentType,
        ) => {
            const bookingId = bookingDetailItem.id;
            let newChecked = [] as typeof checked;
            const found = checked.find((c) => c.detailId === bookingId && c.userId === user.id);
            if (found) {
                if (itemIdsSizeByBookingDetailId === 0) {
                    newChecked = checked.filter((c) => c !== found);
                } else {
                    newChecked = checked.map((c) => {
                        if (c.detailId === bookingId && c.userId === user.id) {
                            return { ...c, quantity: itemIdsSizeByBookingDetailId };
                        }
                        return c;
                    });
                }
            } else {
                newChecked = [
                    ...checked,
                    { detailId: bookingId, userId: user.id, quantity: itemIdsSizeByBookingDetailId },
                ];
            }
            if (newChecked.length === 0) {
                setServiceFeeByBookingDetailId({});
                setChecked(newChecked);
                return;
            }
            setChecked(newChecked);
            await onLoadServiceFee(newChecked, paymentType);
        },
        [checked, onLoadServiceFee]
    );
    const onCalculateServiceFeeForTransfer = useCallback(
        async (
            updatedTransferRequest: ITransferBookingDetailViewModel,
            paymentType: PaymentType,
        ) => {
            let newChecked = checked;
            const { bookingDetailId: detailId, userId } = updatedTransferRequest;
            const found = checked.find((c) => c.detailId === detailId && c.userId === userId);
            if (found) {
                newChecked = newChecked.filter(f => f !== found);
            } else {
                newChecked = [
                    ...checked,
                    { detailId: detailId, userId, quantity: 1 },
                ];
            }
            setChecked(newChecked);
            if (newChecked.length === 0) {
                setServiceFeeByBookingDetailId({ });
            } else {
                onLoadServiceFee(newChecked, paymentType);
            }
        },
        [checked, onLoadServiceFee]
    );

    const onRecalculateServiceFeeByPaymentMethod = async (newPaymentMethod: PaymentType) => {
        await onLoadServiceFee(checked, newPaymentMethod);
    }

    return {
        loading: isLoadingServiceFee,
        totalFee: totalServiceFee,
        serviceFeeByBookingDetailId,

        checked,
        onCalculateServiceFeeForRefund,
        onCalculateServiceFeeForAddAdditionalService,
        onCalculateServiceFeeForTransfer,
        onRecalculateServiceFeeByPaymentMethod,
    };
};

export default useLoadBookingDetailServiceFee;
