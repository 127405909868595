import { Box, Typography } from "@maysoft/common-component-react";
import { ExpandMore } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useId, useState } from "react";
import { IBookingDetailPopupUser } from "../../interfaces";

const DeeptechAccordion = <D extends IBookingDetailPopupUser>({
    data,
    renderContent,
    renderTitle,
    expanded: expandedProp,
    expandIcon,
    px = 2,
    noBorder = false,
}: {
    data: D;
    expanded?: boolean;
    noBorder?: boolean;
    px?: number;
    expandIcon?: React.ReactNode;
    renderContent: (data: D) => React.ReactNode;
    renderTitle?: (data: D) => React.ReactNode;
}) => {
    const id = useId();
    const [expanded, setExpanded] = useState<boolean>(false);
    const hasExpandedProp = expandedProp !== undefined;

    return (
        <Accordion
            disableGutters
            sx={{
                "&:before": {
                    display: "none",
                },
                border: noBorder ? undefined : "1px solid #d3d3d3",
                boxShadow: "none",
            }}
            expanded={hasExpandedProp ? expandedProp : expanded}
            onChange={hasExpandedProp ? undefined : () => setExpanded(!expanded)}
        >
            <AccordionSummary
                expandIcon={expandIcon === undefined ? <ExpandMore /> : expandIcon}
                id={`panel-summary-${id}`}
                sx={{
                    minHeight: "40px",
                    borderBottom: expanded ? (noBorder ? undefined : "1px solid #d3d3d3") : undefined,
                    px: px,
                    "&.Mui-expanded": {
                        minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content": {
                        margin: 0,
                    },
                }}
            >
                    {renderTitle ? renderTitle(data) : (
                        <Box display="flex" gap={1} flexDirection="row" width="100%">
                            <PersonIcon fontSize="small" />
                            <Typography variant="button">{data.fullName}</Typography>
                        </Box>
                    )}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    py: 2,
                    px: px,
                }}
            >
                {renderContent(data)}
            </AccordionDetails>
        </Accordion>
    );
}


export default DeeptechAccordion;