import { Divider, Grid } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { Fragment, useMemo, useState } from "react";
import { Box, Button, FormField, Modal, Typography } from "@maysoft/common-component-react";

import { Mode } from "constants/enum";
import InputNumber from "components/InputNumber";
import useDataRequestBooking from "../useDataRequestBooking.hook";



const MAX_ROOM = 10;
const MAX_ADULTS = 14;
const MAX_CHILD = 6;

interface IOccupancy {
    adultSlot: number;
    childrenOld: number[];
}

interface IProps {
    data: IOccupancy[];
    isWhenShow: boolean;
    onChangeValue: (data: {
        key: keyof IOccupancy,
        value: number,
        index: number,
        subIndex?: number,
    }) => void;

}

const InputAddRoomSlot: React.FC<IProps> = ({ data, onChangeValue, isWhenShow }) => {

    const { getNumberOfAdultAndChild, handleConfirmData } = useDataRequestBooking();

    const [visibled, setVisibled] = useState(false);

    const { numberOfAdult, numberOfChild } = useMemo(() => {
        return getNumberOfAdultAndChild(data)
    }, [data]);

    return (
        <>
            <Box
                onClick={() => {
                    handleConfirmData({
                        isWhen: isWhenShow,
                        lable: `"Số lượng phòng"`,
                        onOk() {
                            setVisibled(true);
                        },
                        onCancle() {
                            setVisibled(false);
                        },
                    });
                }}
                sx={{
                    py: 1,
                    px: 3,
                    gap: 1,
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid #c3c3c3",
                    justifyContent: "space-between",
                    transitionProperty: "background-color, box-shadow",
                    transitionDelay: 0.5,
                }}
            >
                <Fragment>
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="button" fontWeight="bold">
                            {`${data.length} phòng`}
                        </Typography>
                        <Typography variant="caption">
                            {`${numberOfAdult} người lớn, ${numberOfChild} trẻ em`}
                        </Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        sx={{ borderRadius: 20 }}
                        onClick={(e) => {
                            e.stopPropagation();

                            handleConfirmData({
                                isWhen: isWhenShow,
                                lable: `"Số lượng phòng"`,
                                onOk() {

                                    onChangeValue({ value: 1, key: "adultSlot", index: -1 });

                                    setVisibled(true);
                                },
                                onCancle() {
                                    setVisibled(false);
                                },
                            });
                        }}
                    >
                        {"Thêm phòng"}
                    </Button>
                </Fragment>
            </Box>
            {
                <Modal
                    fullWidth
                    maxWidth="md"
                    visible={visibled}
                    title={"Thêm phòng"}
                    onClose={() => { setVisibled(false) }}
                    onClickCloseIcon={() => { setVisibled(false) }}
                >
                    <>
                        <Box sx={{
                            p: 2,
                            gap: 1,
                            display: "flex",
                            marginBottom: "80px",
                            flexDirection: "column",
                        }} >
                            {data.map((item, index) => (
                                <Box key={index} display="flex" flexDirection="column" gap={0.5}>
                                    <Typography variant="button" fontWeight="bold">
                                        {"Phòng"}&nbsp;{index + 1}
                                    </Typography>
                                    {/*  */}
                                    <AdditionalItem title={"Người lớn"} description={"Từ 18 tuổi trở lên"}>
                                        <InputNumber
                                            min={1}
                                            max={MAX_ADULTS}
                                            mode={Mode.Create}
                                            value={item.adultSlot}
                                            onChangeValue={(value) => {
                                                onChangeValue({ value, key: "adultSlot", index });
                                            }}
                                        />
                                    </AdditionalItem>
                                    {/*  */}
                                    <AdditionalItem title={"Trẻ em"} description={"Từ 0-17 tuổi"}>
                                        <InputNumber
                                            min={0}
                                            max={MAX_CHILD}
                                            mode={Mode.Create}
                                            value={item.childrenOld.length}
                                            onChangeValue={(value) => {
                                                onChangeValue({ value, key: "childrenOld", index });

                                            }}
                                        />
                                    </AdditionalItem>
                                    {/*  */}
                                    <Grid container spacing={2} my={0.5}>
                                        {item.childrenOld.map((child, indexChild) => (
                                            <Grid key={indexChild} item xs={4}>
                                                <FormField
                                                    type="number"
                                                    variant="outlined"
                                                    defaultValue={child}
                                                    label={"Tuổi của trẻ " + (indexChild + 1)}
                                                    onBlur={(value) => {
                                                        const newValue = (Number(value) > 17) ? 17 : (Number(value) < 1) ? 1 : Number(value);
                                                        onChangeValue({ value: newValue, key: "childrenOld", index, subIndex: indexChild });
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {/*  */}
                                    {data.length > 1 && (
                                        <Box display="flex" justifyContent="right">
                                            <Button
                                                color="error"
                                                variant="outlined"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onChangeValue({ value: 1, key: "adultSlot", index, subIndex: -999 });
                                                }}
                                            >
                                                <Remove /> &nbsp; {"Xóa"}
                                            </Button>
                                        </Box>
                                    )}
                                    <Divider />
                                </Box>
                            ))}
                        </Box>
                        <Box sx={{
                            left: 0,
                            right: 0,
                            bottom: 0,
                            padding: 2,
                            position: "absolute",
                            backgroundColor: "#fff",
                        }} >
                            <Button
                                variant="outlined"
                                sx={{ width: "100%" }}
                                disabled={data.length === MAX_ROOM}
                                color={data.length === MAX_ROOM ? "secondary" : "info"}
                                onClick={() => {
                                    onChangeValue({ value: 1, key: "adultSlot", index: -1 });
                                }}
                            >
                                <Add /> &nbsp; {"Thêm phòng mới"}
                            </Button>
                        </Box>
                    </>
                </Modal>
            }
        </>
    );
};

export default InputAddRoomSlot;

const AdditionalItem = (props: {
    title: string;
    description?: string;
    children: JSX.Element;
}) => {
    return (
        <Box
            sx={{
                gap: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Box display="flex" flexDirection="column">
                <Typography variant="caption" fontWeight="medium">
                    {props.title}
                </Typography>
                {props.description && (
                    <Typography variant="caption" color="secondary">
                        {props.description}
                    </Typography>
                )}
            </Box>
            {props.children}
        </Box>
    );
};