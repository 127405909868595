import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import Screens from "constants/screens";
import Constants from "constants/index";

import { LoadingLayout } from "layout";
import { useAuth } from "providers/authProvider";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Helpers from "commons/helpers";

interface IProps {
    children: React.ReactElement;
}

const ProtectedRoute: React.FC<IProps> = (props: IProps) => {
    const auth = useAuth();
    const location = useLocation();
    const userInfo = useSelector((state: RootState) => state.userInfoSlice);

    if (auth?.loading) {
        return <LoadingLayout />;
    }

    if (auth.user === null) {
        sessionStorage.setItem(Constants.StorageKeys.FROM, location.pathname);
        return <Navigate to={Screens.LOGIN_REDIRECT} replace />
    }

    return !Helpers.isNullOrEmpty(userInfo?.userProfile?.id) ? props.children : <LoadingLayout />;
};

export default ProtectedRoute;
