import { Box } from "@maysoft/common-component-react";
import { CircularProgress } from "@mui/material";
import Helpers from "commons/helpers";
import { BookingDetailAmendType, ItineraryType, OrderPricingType, PaymentType } from "constants/enum";
import { useState } from "react";
import RequestBookingService, { ICreateSubOrderRequest } from "services/booking/requestBooking.service";
import PaymentMethodSelector from "../components/PaymentMethodSelector";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { ICalculateServiceFeeResponse } from "services/sale/serviceFeeSetting";
import { IBookingDetailFlightAmendServicePopupExtraInfo, IService, ITransferBookingDetailSubOrderViewModel, ITransferBookingDetailViewModel } from "../../interfaces";
import Strings from "constants/strings";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IMultiLang } from "commons/interfaces";
import moment from "moment";
import { ITransportHubServiceItemExtraInfo } from "services/common/transportHub.service";
import { BookingHelpers } from "commons/bookingHelpers";

const bookingService = new RequestBookingService();
export interface IUseBookingDetailPopupSubmitProps {
    onCompleted?: () => Promise<void>;
    data: IDetailBooking;
}
const useBookingDetailSubmit = ({ onCompleted, data }: IUseBookingDetailPopupSubmitProps) => {
    const { userProfile, listOrganization } = useSelector((state: RootState) => state.userInfo);
    const organizationId = userProfile?.organizationId;
    const currentOrganizationData = listOrganization.find(o => o.code === organizationId);
    const organizationLocation = currentOrganizationData?.detail?.location;
    const timezone = moment.tz.guess();

    const contact = BookingHelpers.getBookingContactInfo(data.bookingDetails);

    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [commonProperties, setCommonProperties] = useState<{
        documents: { documentId: string; documentUrl: string }[];
        paymentType: PaymentType;
    }>({
        documents: [],
        paymentType: PaymentType.Debt,
    });

    const handleRefundSubmit = async ({ selectedItems, serviceFeeByBookingDetailId, amount, refundAmount }: { selectedItems: any[], serviceFeeByBookingDetailId: Record<string, ICalculateServiceFeeResponse>, refundAmount: number, amount: number }) => {
        const { documents, paymentType } = commonProperties;

        try {
            setIsSubmitLoading(true);

            const documentsRequest = paymentType === PaymentType.Cash ? documents : [];
            const totalBookingDetail = selectedItems.length;
            const originalAmountPerBookingDetail = amount / totalBookingDetail;
            await bookingService.createSubOrder({
                bookingId: data.id,
                amendType: BookingDetailAmendType.PartialRefund,
                bookingDetails: Object.values(serviceFeeByBookingDetailId).map(
                    (serviceFee) => {
                        const bookingDetail = data.bookingDetails.find(
                            (d) => d.id === serviceFee.bookingDetailId
                        );
                        const userIds = selectedItems
                            .filter(
                                (predicate) =>
                                    predicate.detailId ===
                                    serviceFee.bookingDetailId
                            )
                            .map((o) => o.userId);
                        const unitPrice = originalAmountPerBookingDetail * serviceFee.quantity;
                        const bookingDetailAmount = unitPrice - serviceFee.fee;

                        return {
                            amount: bookingDetailAmount,
                            description: undefined,
                            discount: 0,
                            promotion: 0,
                            extraFee: 0,
                            extraInformation: JSON.stringify({
                                users: userIds.map(id => ({ id, flight: { ticketNumber: BookingHelpers.getBookingTicketNumber(bookingDetail, id) } })),
                                contact: contact,
                                timezone,
                            } as IBookingDetailFlightAmendServicePopupExtraInfo),
                            feeCode: serviceFee.feeCode,
                            itemId: bookingDetail.itemId,
                            quantity: serviceFee.quantity,
                            serviceFee: serviceFee.fee,
                            serviceFeeTax: serviceFee.feeTax,
                            specificTax: 0,
                            tax: 0,
                            type: bookingDetail.type,
                            unitPrice,
                            bookingDetailId : bookingDetail.id,
                        };
                    },
                    [] as ICreateSubOrderRequest["bookingDetails"]
                ),

                paymentMethod: paymentType,
                documents:
                    documentsRequest.length > 0 ? documentsRequest : undefined,

                pricingType: OrderPricingType.External,
                currency: data.currency,
                amount: refundAmount,
                discount: 0,
                tax: 0,
                note: undefined,
            });
            Helpers.showAlert(Strings.BOOKING.REFUND_POPUP_SUCCESS, "success");
            onCompleted && await onCompleted();
        } catch (error) {
            Helpers.handleException(error);
        } finally {
            setIsSubmitLoading(false);
        }
    };
    const handleAdditionalServicesSubmit = async ({ services, serviceFeeByBookingDetailId, amount, getItemNameByItemId }: { services: IService[], serviceFeeByBookingDetailId: Record<string, ICalculateServiceFeeResponse>, amount: number, getItemNameByItemId: (id: string) => IMultiLang }) => {
        const { documents, paymentType } = commonProperties;

        try {
            setIsSubmitLoading(true);

            const documentsRequest = paymentType === PaymentType.Cash ? documents : [];
            const bookingDetails = services.map((service) => {
                const bookingDetailId = service.bookingDetailId;
                const bookingDetail = data.bookingDetails.find(d => d.id === bookingDetailId);

                const itemId = service.itemId;
                const currentServiceFee = serviceFeeByBookingDetailId[service.bookingDetailId];
                const serviceFeePerItem = currentServiceFee.fee / currentServiceFee.quantity;
                const serviceFeeTaxPerItem = currentServiceFee.feeTax / currentServiceFee.quantity;

                return {
                    discount: 0,
                    promotion: 0,
                    extraFee: 0,
                    specificTax: 0,
                    tax: 0,
                    extraInformation: JSON.stringify({
                        users: [ { id: service.userId, flight: { ticketNumber: BookingHelpers.getBookingTicketNumber(bookingDetail, service.userId ) } } ],
                        contact,
                        itemName: getItemNameByItemId(service.itemId)?.value,
                        timezone,
                    } as IBookingDetailFlightAmendServicePopupExtraInfo),

                    amount: service.unitPrice + serviceFeePerItem,
                    serviceFee: serviceFeePerItem,
                    serviceFeeTax: serviceFeeTaxPerItem,
                    
                    unitPrice: service.unitPrice,
                    description: service.description,
                    feeCode: currentServiceFee.feeCode,
                    itemId: itemId,
                    quantity: 1,
                    type: ItineraryType.Flight,
                    bookingDetailId : bookingDetailId,
                }
            });
            await bookingService.createSubOrder({
                bookingId: data.id,
                amendType: BookingDetailAmendType.AdditionService,
                bookingDetails: bookingDetails,

                paymentMethod: paymentType,
                documents:
                    documentsRequest.length > 0 ? documentsRequest : undefined,

                pricingType: OrderPricingType.External,
                currency: data.currency,
                amount: amount,
                discount: 0,
                tax: 0,
                note: undefined,
            });
            Helpers.showAlert(Strings.BOOKING.ADDITION_SERVICE_POPUP_SUCCESS, "success");
            onCompleted && await onCompleted();
        } catch (error) {
            console.log(error);
            Helpers.handleException(error);
        } finally {
            setIsSubmitLoading(false);
        }
    };
    const handleTransferSubmit = async ({ transferItems, serviceFeeByBookingDetailId, amount, subOrderData }: { transferItems: ITransferBookingDetailViewModel[], serviceFeeByBookingDetailId: Record<string, ICalculateServiceFeeResponse>, amount: number , subOrderData: ITransferBookingDetailSubOrderViewModel}) => {
        const totalExtraFee = subOrderData.transferFee + (subOrderData.noshowFee || 0);
        const extraFeePerTransferFlight = totalExtraFee / transferItems.length;
        const unitPricePerTransfer = subOrderData.differentAmount / transferItems.length;

        const bookingDetails: ICreateSubOrderRequest["bookingDetails"] = transferItems.map((item) => {
            const { bookingDetailId } = item;
            const serviceFeeResponse = serviceFeeByBookingDetailId[bookingDetailId];
            const serviceFeePerItem = serviceFeeResponse.fee / serviceFeeResponse.quantity;
            const serviceFeeTaxPerItem = serviceFeeResponse.feeTax / serviceFeeResponse.quantity;

            const arrivalPlaceLocation = item.arrivalPlaceObj.detail.countryCode;
            const departPlaceLocation = item.departPlaceObj.detail.countryCode;

            const departPlaceExtraInfo = Helpers.converStringToJson(item.departPlaceObj.detail.extraInfo) as ITransportHubServiceItemExtraInfo;
            const arrivalPlaceExtraInfo = Helpers.converStringToJson(item.arrivalPlaceObj.detail.extraInfo) as ITransportHubServiceItemExtraInfo;

            const extraInfoData = {
                users: [
                    {
                        id: item.userId,
                        flight: {
                            airlineObj: item.airlineObj,
                            arrivalPlaceObj: {
                                code: item.arrivalPlaceObj.code,
                                name: item.arrivalPlaceObj.name,
                                cityName: arrivalPlaceExtraInfo.CityName,
                                countryName: arrivalPlaceExtraInfo.CountryName,
                            },
                            arrivalTime: moment(item.arrivalTime * 1000).utc(true).unix(),
                            baggage: item.baggage,
                            cabinClass: item.cabinClass,
                            departPlaceObj: {
                                code: item.departPlaceObj.code,
                                name: item.departPlaceObj.name,
                                cityName: departPlaceExtraInfo.CityName,
                                countryName: departPlaceExtraInfo.CountryName,
                            },
                            departTime: moment(item.departTime * 1000).utc(true).unix(), // item.departTime is unix timestamp (+0)
                            seatNumber: item.seatNumber,
                            ticketNumber: item.ticketNumber,
                            reservationCode: item.reservationCode,
                            isInternational: !(arrivalPlaceLocation === departPlaceLocation && organizationLocation === arrivalPlaceLocation),
                        },
                }],
                timezone,
                contact,
            } as IBookingDetailFlightAmendServicePopupExtraInfo;

            return {
                discount: 0,
                promotion: 0,
                extraFee : extraFeePerTransferFlight,
                specificTax: 0,
                tax: 0,
                extraInformation: JSON.stringify({
                    ...extraInfoData,
                    rawExtraFee: {
                        transferFee: subOrderData.transferFee,
                        noshowFee: subOrderData.noshowFee,
                    },
                }),

                amount: extraFeePerTransferFlight + serviceFeePerItem + unitPricePerTransfer,
                serviceFee: serviceFeePerItem,
                serviceFeeTax: serviceFeeTaxPerItem,
                    
                unitPrice: unitPricePerTransfer,
                description: undefined,
                feeCode: serviceFeeResponse.feeCode,
                itemId: undefined,
                quantity: 1,
                type: ItineraryType.Flight,
                bookingDetailId : bookingDetailId,
            }
        })
        try {
            setIsSubmitLoading(true);
            await bookingService.createSubOrder({
                bookingId: data.id,
                amendType: BookingDetailAmendType.Transfer,
                bookingDetails: bookingDetails,

                paymentMethod: commonProperties.paymentType,
                documents:
                    commonProperties.documents.length > 0 ? commonProperties.documents : undefined,

                pricingType: OrderPricingType.External,
                currency: data.currency,
                amount: amount,
                discount: 0,
                tax: 0,
                note: undefined,
            });
            Helpers.showAlert(Strings.BOOKING.TRANSFER_POPUP_SUCCESS, "success");
            onCompleted && await onCompleted();
        } catch (error) {
            console.log(error)
        } finally {
            setIsSubmitLoading(false);
        }
    }
    
    const renderPaymentContent = ({ title, onChangePaymentType }: {title: string, onChangePaymentType?: (paymentType: PaymentType) => void}) => {
        return <PaymentMethodSelector
                title={title}
                value={commonProperties.paymentType}
                onChange={(value) => {
                    setCommonProperties({ ...commonProperties, paymentType: value })
                    onChangePaymentType?.(value);
                }}
                // renderChilrenByPaymentMethod={(paymentType) => {
                //     return paymentType === PaymentType.Cash && (
                //         <FileUploader
                //             // ="Tài liệu đính kèm"
                //             defaultValue={commonProperties.documents.map(d => ({ fileId: d.documentId, fileUrl: d.documentUrl }))}
                //             multiple
                //             onChange={(fileUploadRequest) => setCommonProperties({ ...commonProperties, documents: fileUploadRequest.map(f => ({ documentId: f.fileId, documentUrl: f.fileAccessUrl || f.fileUrl })) })}
                //         />
                //     )
                // }}
            />
    }

    return {
        renderLoadingContent: () => <SubmitLoadingCircular />,
        renderPaymentContent,
        paymentMethod: commonProperties.paymentType,
        loading: isSubmitLoading,
        onRefundSubmit: handleRefundSubmit,
        onAddAdditionServicesSubmit: handleAdditionalServicesSubmit,
        onTransferSubmit: handleTransferSubmit,
        commonProperties,
    };
}

export default useBookingDetailSubmit;

const SubmitLoadingCircular = () => {
    return (
        <Box display="flex" alignItems={"center"} justifyContent="center" p={3}>
            <CircularProgress size={32} color="primary" />
        </Box>
    );
};