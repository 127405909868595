import { CheckBox, Box } from "@maysoft/common-component-react";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { ICalculateServiceFeeResponse } from "services/sale/serviceFeeSetting";
import DeeptechAccordion from "../../components/DeeptechAccordion";
import TextWithPrice from "../../components/TextWithPrice";
import { IBookingDetailPopupUser } from "../../../interfaces";
import FlightRefundDetail from "../../components/FlightRefundDetail";
import TextWithInputPrice from "../../components/TextWithInputPrice";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";

const EditModeFlightBookingDetailRefundPopupContent = ({
    onChangeAmount,
    users,
    checked,
    onCheck,
    amount,
    refundAmount,
    serviceFeeByBookingDetailId,
    isUserBookingRefunded,
    isLoadingServiceFee = false,
    renderPaymentContent,
    currency,
}: {
    users: IBookingDetailPopupUser[];
    isUserBookingRefunded: (userId: string, bookingDetailId: string) => boolean;

    checked?: { detailId: string; userId: string }[];
    onCheck?: (
        checked: boolean,
        user: IBookingDetailPopupUser,
        detail: IBookingDetail
    ) => void;

    amount: number;
    refundAmount: number;
    onChangeAmount: (value: number) => void;
    serviceFeeByBookingDetailId: Record<string, ICalculateServiceFeeResponse>;
    isLoadingServiceFee?: boolean;
    renderPaymentContent: ({ title }: { title: string }) => React.ReactNode;

    currency: string;
}) => {
    const { getBookingDetailsByUserId, isFlightBooking } = useAddMoreBookingServiceContext();
    const totalServiceFee = Object.values(serviceFeeByBookingDetailId).reduce(
        (prev, c) => prev + c.fee,
        0
    );
    if (isFlightBooking) {
        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {users.map((user) => (
                    <DeeptechAccordion
                        key={user.id}
                        data={user}
                        renderContent={() => {
                            return (
                                <Box display="flex" flexDirection="column">
                                    {getBookingDetailsByUserId(user.id).map(
                                        (bookingDetailItem) => {
                                            const isRefunded = isUserBookingRefunded(user.id, bookingDetailItem.id);
                                            const isChecked = !!checked.find(
                                                (c) =>
                                                    c.userId === user.id &&
                                                    c.detailId ===
                                                        bookingDetailItem.id
                                            );
                                            return (
                                                <Box
                                                    key={bookingDetailItem.id}
                                                    display="flex"
                                                    flexDirection="row"
                                                    flexWrap="wrap"
                                                    alignItems="center"
                                                >
                                                    <CheckBox
                                                        value={""}
                                                        checked={isChecked}
                                                        disabled={isRefunded || isLoadingServiceFee}
                                                        onChange={
                                                            onCheck && !isRefunded
                                                                ? ( _, checked) => onCheck(checked, user, bookingDetailItem)
                                                                : undefined
                                                        }
                                                    />
                                                    &nbsp;
                                                    <FlightRefundDetail detail={bookingDetailItem} isRefunded={isRefunded} userId={user.id} />
                                                </Box>
                                            );
                                        }
                                    )}
                                </Box>
                            );
                        }}
                    />
                ))}
                {renderPaymentContent({ title: Strings.Common.PAYMENT_TYPE })}
                <Box display="flex" flexDirection="column">
                    <TextWithInputPrice
                        label={Strings.BOOKING.REFUND_POPUP_REFUND_AMOUNT}
                        currency={currency}
                        onChangeValue={onChangeAmount}
                        value={amount} 
                        // loading={isLoadingServiceFee}
                    />
                    <TextWithPrice
                        text={Strings.Common.SERVICE_FEE}
                        currency={currency}
                        price={totalServiceFee}
                        loading={isLoadingServiceFee}
                    />
                    <TextWithPrice
                        fontWeight="medium"
                        text={Strings.BOOKING.REFUND_POPUP_CUSTOMER_REFUND}
                        currency={currency}
                        color="warning"
                        price={refundAmount}
                        loading={isLoadingServiceFee}
                    />
                </Box>
            </Box>
        );
    }

    return null;
};


export default EditModeFlightBookingDetailRefundPopupContent;