import { Box, Typography } from "@maysoft/common-component-react";
import moment from "moment";

const OperationHistoryBookingText = (props: { departTime?: number, departPlace: string, arrivalPlace: string, reservationCode?: string }) => {
    const departTimeMoment = props.departTime ? moment.utc(props.departTime * 1000) : undefined;
    const time = departTimeMoment?.format("HH:mm");
    const weekday = departTimeMoment?.format("dddd").charAt(0).toUpperCase() + departTimeMoment?.format("dddd").slice(1);
    const date = departTimeMoment?.format("DD/MM/YYYY");

    return (
        <Box display="flex" flexDirection="row"alignItems="center" gap={0.5} flexWrap="wrap">
            {props.reservationCode && <Typography variant="button" textTransform="none">Mã đặt chỗ:&nbsp;{props.reservationCode}</Typography>}
            {props.reservationCode && (props.departPlace || props.arrivalPlace) && <span>-</span>}
            <Typography variant="button" textTransform="none">Chuyến bay {props.departPlace}&nbsp;-&nbsp;{props.arrivalPlace},</Typography>
            <Typography variant="button" textTransform="none">khởi hành {time} {weekday}, ngày {date}</Typography>
        </Box>
    )
}

export default OperationHistoryBookingText;