

import { useEffect, useState } from "react";
import { FormField, ModalSearchUser } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";


import { Mode } from "constants/enum";
import { IErrorDetail, IDataDetail } from ".";



interface IProps {
    mode: Mode;
    dataMap?: Map<string, any>;

    data: IDataDetail;
    setData: React.Dispatch<React.SetStateAction<IDataDetail>>

    error: IErrorDetail;
    setError: React.Dispatch<React.SetStateAction<IErrorDetail>>
}

const InputAddSeller = (props: IProps) => {

    const [openSeller, setOpenSeller] = useState(false);
    const [sellerSearchText, setSellerSearchText] = useState("");

    useEffect(() => {
        if (props.data?.seller) {
            const newValue = props.data?.seller?.phoneNumber
                ? `${props.data?.seller?.name} - ${props.data?.seller?.phoneNumber}`
                : `${props.data?.seller?.name}`;
            setSellerSearchText(newValue);
        }
    }, [props.data?.seller]);

    return (
        <>
            <FormField
                required
                mode={props.mode}
                contentEditable={false}
                value={sellerSearchText}
                // disabled={!isAdminService}
                label={Strings.BOOKING.PERSON_IN_CHARGE}
                placeholder={Strings.BOOKING.SELECT_OR_ADD_PERSON_IN_CHARGE}
                onClick={() => { setOpenSeller(true); }}
            />

            <ModalSearchUser
                isSingle
                open={openSeller}
                onClose={() => { setOpenSeller(false) }}
                titleModal={Strings.BOOKING.PERSON_IN_CHARGE}
                userIds={props.data?.seller?.id ? [props.data?.seller?.id] : []}
                onAction={(data) => {
                    if (!data || !data[0]) {
                        return;
                    } else {
                        const newValue = Helpers.isNullOrEmpty(data[0]?.phoneNumber)
                            ? `${data[0]?.fullName} - ${data[0]?.phoneNumber}`
                            : `${data[0]?.fullName}`;

                        setSellerSearchText(newValue);

                        props.setData(prev => ({
                            ...prev,
                            seller: {
                                ...prev?.seller,
                                id: data[0]?.id,
                                email: data[0]?.email || "",
                                name: data[0]?.fullName || "",
                                phoneNumber: data[0]?.phoneNumber || "",
                            },
                        }));

                        props.setError(prev => ({ ...prev, seller: undefined }));

                        setOpenSeller(false);
                    }
                }}
            />
        </>
    );
};

export default InputAddSeller;
