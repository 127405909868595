import Helpers from "commons/helpers";
import Constants from "constants/index";
import SaleService from "./sale.service";

import { IRequestGetPagedTicketFund } from "@maysoft/sale-common-react";

class TicketFundService extends SaleService {
    public export = async (data: IRequestGetPagedTicketFund) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.getExcel({
            path: Constants.ApiPath.TICKET_FUND.EXPORT + `?${query}`,
        });

        return new Blob([result.data]);
    };
}

export default TicketFundService;
