import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ModuleRole, RoleHelpers, RoleTab, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { setListPathName } from "store/slice/titleRoute.slice";

interface RequestData {
    organizationId?: string;
    clientId?: string;
    serviceCode?: string;
    roleLevel?: number;
    status?: number;
    type?: number;

    searchText?: string;
    pageNumber: number;
    pageSize?: number;
    orderBy?: string;
    totalCount?: number;
}

const RoleScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const idDetail: string = searchParams.get("id");
    const modeList = Screens.ROLE_LIST.includes(window.location.pathname);
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const resourcePermissions = getResourcePermissions(Constants.ResourceUri.ROLE);
    const userProfile = useSelector((state: RootState) => state.userInfo?.userProfile);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const item = listPathName.find((el) => el.pathName === Screens.ROLE_LIST);

    const [mode, setMode] = useState<Mode>(pramsMode || Mode.View);

    const tab = searchParams.get("tab") || RoleTab.Role;

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(idDetail)) return Strings.ROLE.TITLE_CREATE_VIEW;
        return mode === Mode.Update ? Strings.ROLE.TITLE_UPDATE_VIEW : Strings.ROLE.TITLE_DETAIL_VIEW;
    }, [mode, idDetail]);

    const route = useMemo(() => {
        let temp = [{ title: Strings.ROLE.TITLE_MENU, route: Screens.ROLE_LIST }];
        if (Helpers.isNullOrEmpty(idDetail)) return [...temp, { title: Strings.Common.CREATE_NEW, route: "" }];
        return [...temp, { title: mode === Mode.Update ? Strings.Common.UPDATE : Strings.Common.DETAIL, route: "" }];
    }, [mode, idDetail]);

    const [requestData] = useState<RequestData>({
        searchText: searchParams.get("searchText"),
        tab: searchParams.get("tab") || RoleTab.Role,
        serviceCode: searchParams.get("serviceCode") || Constants.SERVICE_CODE,
        type: Helpers.isNullOrEmpty(searchParams.get("type")) ? undefined : Number(searchParams.get("type")),
        status: Helpers.isNullOrEmpty(searchParams.get("status")) ? undefined : Number(searchParams.getAll("status")),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
        totalCount: item?.totalCount,
    } as RequestData);

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            setMode(Mode.Create);
        } else {
            setMode(pramsMode);
            navigate(Screens.ROLE_EDIT + `?id=${idDetail}&mode=${pramsMode}&tab=${tab}`, { replace: true });
        }
    }, [idDetail, pramsMode, tab]);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.ROLE_LIST);
        navigate(Screens.ROLE_LIST + (itemP ? itemP.query : ""));
    };

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={modeList ? Strings.ROLE.TITLE_LIST_VIEW : title}
            route={modeList ? [{ title: Strings.ROLE.TITLE_MENU, route: "" }] : route}
            isRequiredOrganization={!(
                RoleHelpers.isServiceAdmin(userProfile?.roleType) ||
                RoleHelpers.isSuperAdmin(userProfile?.roleType, Constants.SERVICE_CODE)
            )}
        >
            <ModuleRole
                hiddenTab

                hidenActionCreate={!resourcePermissions.canCreate}
                hidenActionDelete={!resourcePermissions.canDelete}
                hidenActionUpdate={!resourcePermissions.canUpdate}

                keyModuleContainer={{
                    list: { pathName: Screens.ROLE_LIST },
                    detail: { pathName: Screens.ROLE_EDIT },
                    create: { pathName: Screens.ROLE_CREATE },
                }}

                requestGetPaged={requestData}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.ROLE_LIST, query, totalCount: totalCount }));
                    navigate(Screens.ROLE_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    if (data.id) {
                        navigate(Screens.ROLE_EDIT + `?id=${data.id}&mode=${data.mode}&tab=${tab}`);
                    } else {
                        navigate(Screens.ROLE_CREATE + `?tab=${tab}`);
                    }
                }}

                mode={mode}
                idDetail={idDetail}
                tab={tab as RoleTab}
                onGoBack={() => handleGoBack()}
                onChangeMode={(mode: Mode) => {
                    setMode(mode);
                    navigate(Screens.ROLE_EDIT + `?id=${idDetail}&mode=${mode}&tab=${tab}`, { replace: true });
                }}
            />
        </DashboardLayout>
    );
};

export default RoleScreen;
