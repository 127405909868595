import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ServiceFeeListContainer } from "@maysoft/sale-common-react";

import Constants from "constants/index";
import Screens from "constants/screens";
import useModule from "hooks/useModule";

import { DashboardLayout } from "layout";



const ServiceFeeScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        searchText: searchParams.get("searchText"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.SERVICE_FEE_LIST,
        },
        keyOfStrings: "SERVICE_FEE",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <ServiceFeeListContainer {...moduleProps} />
        </DashboardLayout>
    );
};

export default ServiceFeeScreen;
