import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Screens from "constants/screens";
import useModule from "hooks/useModule";
import Constants from "../../constants";

import { ServiceInfoEditContainer, useCommonComponentContext } from "@maysoft/common-component-react";
import { DashboardLayout } from "layout";
import { Mode } from "constants/enum";

const ServiceInfoScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        startTime: searchParams.get("startTime"),
        endTime: searchParams.get("endTime"),
        confirmStatus: searchParams.get("confirmStatus"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.SERVICE_INFO,
        },
        keyOfStrings: "SERVICE_INFO",
    });

    const [mode, setMode] = useState<Mode>(Mode.View);
    
    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <ServiceInfoEditContainer mode={mode} onChangeMode={setMode} onGoBack={null} resourceUri="identity/serviceInfo" />
        </DashboardLayout>
    );
};

export default ServiceInfoScreen;
