import { Card } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

const CardCustomerOrderBooking = (props: {
    id?: string,
    name?: string,
    code?: string,
    email?: string,
    createUser?: string,
    phoneNumber?: string,
}) => {
    return (
        <Card>
            <Box padding={2}>
                <Typography variant="h6">
                    {"Thông tin khách hàng"}
                </Typography>
                <Box
                    paddingTop={1}
                    paddingLeft={2}
                    paddingRight={2}
                    sx={{
                        gap: 1,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="button">
                        {"Tên công ty"}:&ensp;
                        {props?.name || ""}
                    </Typography>
                    <Typography variant="button">
                        {"Mã công ty"}:&ensp;
                        {props?.code || ""}
                    </Typography>
                    <Typography variant="button">
                        {"Người tạo"}:&ensp;
                        {props?.createUser || ""}
                    </Typography>
                    <Typography variant="button">
                        {"Email"}:&ensp;
                        {props?.email || ""}
                    </Typography>
                    <Typography variant="button">
                        {"Số điện thoại"}:&ensp;
                        {props?.phoneNumber || ""}
                    </Typography>
                </Box>
            </Box>
        </Card>
    )
};

export default CardCustomerOrderBooking;