

import { useEffect, useState } from "react";
import { FormField } from "@maysoft/common-component-react";
import { ModalSearchPartner, PartnerType } from "@maysoft/sale-common-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";


import { Mode } from "constants/enum";
import { IErrorDetail, IDataDetail } from ".";
import useDataRequestBooking from "../useDataRequestBooking.hook";



interface IProps {
    mode: Mode;
    dataMap?: Map<string, any>;

    data: IDataDetail;
    error: IErrorDetail;
    isWhenShow: boolean;

    onChangeData: (data: any) => void;
}


const InputAddManufacturer = (props: IProps) => {

    const { ORGANIZATION_ID_DEFAULT, handleConfirmData } = useDataRequestBooking();

    const [openManufacturer, setOpenManufacturer] = useState(false);
    const [manufacturerSearchText, setManufacturerSearchText] = useState("");

    useEffect(() => {
        if (props.data?.id) {
            const primaryUser = props.dataMap?.get(props.data?.manufacturerId);
            if (!Helpers.isNullOrEmpty(primaryUser)) {
                const newValue = primaryUser?.phoneNumber ? `${primaryUser?.name} - ${primaryUser?.phoneNumber}` : `${primaryUser?.name}`;
                setManufacturerSearchText(newValue);
            }
        }
    }, [props.data?.id]);

    return (
        <>
            <FormField
                required
                mode={props.mode}
                contentEditable={false}
                label={Strings.BOOKING.HOTEL}
                value={manufacturerSearchText}
                errorMessage={props.error?.manufacturerId}
                disabled={!Helpers.isNullOrEmpty(props.data?.id)}
                placeholder={Strings.BOOKING.SELECT_OR_ADD_HOTEL}
                onClick={(e) => {
                    e.stopPropagation();

                    handleConfirmData({
                        isWhen: props.isWhenShow,
                        lable: `"${Strings.BOOKING.HOTEL}"`,
                        onOk() {
                            setOpenManufacturer(true);
                        },
                        onCancle() {
                            setOpenManufacturer(false);
                        },
                    });
                }}
            />

            <ModalSearchPartner
                isSingle
                isAddNewPartner
                open={openManufacturer}
                titlePartner={Strings.BOOKING.HOTEL}
                partnerType={PartnerType.Manufacturer}
                organizationId={ORGANIZATION_ID_DEFAULT}
                partnerIds={props.data?.manufacturerId
                    ? [props.data?.manufacturerId]
                    : (props.data?.brandId ? [props.data?.brandId] : [])
                }

                onClose={() => { setOpenManufacturer(false); }}
                onAction={(data) => {
                    if (!data || !data[0]) {
                        return;
                    } else {
                        handleConfirmData({
                            isWhen: props.isWhenShow,
                            lable: `"${Strings.BOOKING.HOTEL}"`,
                            onOk() {
                                const primaryUser = data[0]?.partnerUsers?.find((pu) => pu.primaryUser === 1) || {};
                                setManufacturerSearchText(primaryUser?.phoneNumber ? `${primaryUser?.name} - ${primaryUser?.phoneNumber}` : `${primaryUser?.name}`);

                                props.onChangeData(data);

                                setOpenManufacturer(false);
                            },
                            onCancle() { },
                        });
                    }
                }}
            />
        </>
    );
};

export default InputAddManufacturer;
