import { Box, Typography } from "@maysoft/common-component-react";
import { CircularProgress, Divider } from "@mui/material";
import Helpers from "commons/helpers";

const TextWithPrice = ({
    text,
    price = 0,
    loading = false,
    currency,
    color,
    fontWeight = "regular",
    titleFontWeight,
    showDivider,
}: {
    text: string;
    price?: number;
    loading?: boolean;
    currency: string;
    color?: "secondary" | "warning";
    fontWeight?: "regular" | "medium" | "bold";
    titleFontWeight?: "regular" | "medium" | "bold";
    showDivider?: boolean;
}) => {
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    variant="button"
                    fontWeight={titleFontWeight ?? fontWeight ?? "regular"}
                    color={color}
                    component="span"
                >
                    {text}
                </Typography>
                <Box
                    display="flex" 
                    alignItems="center"
                >
                    {loading
                    ? <CircularProgress size={16} color="warning" />
                    : (
                        <Typography
                            variant="button"
                            fontWeight={fontWeight ?? "regular"}
                            color={color}
                            component="span"
                        >
                            {typeof price === "number" ? Helpers.formatCurrency(price) : price}
                        </Typography>
                    )}
                    &nbsp;
                    <Typography
                        variant="button"
                        color={color}
                        fontWeight={fontWeight ?? "regular"}
                        component="span"
                    >
                        {currency}
                    </Typography>
                </Box>
            </Box>
            {showDivider && (<div>
                <Divider />
            </div>)}
        </>
    );
};

export default TextWithPrice;
