import { CheckBox, SearchInputAPI, DateTimePicker, FormField, Box, Typography, Autocomplete  } from "@maysoft/common-component-react";
import { BookingHelpers } from "commons/bookingHelpers";
import Helpers from "commons/helpers";
import { ICodename } from "commons/interfaces";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { CabinClass } from "constants/enum";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import TransportHubService, { ITransportHubServiceItem } from "services/common/transportHub.service";
import { ITransferBookingDetailViewModel, IBookingDetailPopupUser } from "../../interfaces";
import DeeptechAccordion from "./DeeptechAccordion";
import OutlinedSelectBox from "./OutlinedSelectBox";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import { Chip } from "@mui/material";

const transportHubService = new TransportHubService();
const TransferFlightItem = memo(({
    data,
    error,
    user,
    detail,
    isLoadingServiceFee = false,
    onChange,
    airlines = [],
    cabinClasses = [],
}: {
    data?: ITransferBookingDetailViewModel;
    error: { [key: string]: string | undefined };
    detail: IBookingDetail;
    user: IBookingDetailPopupUser;
    isLoadingServiceFee?: boolean;

    onChange: (newData: ITransferBookingDetailViewModel, key: keyof ITransferBookingDetailViewModel) => void;
    cabinClasses?: ICodename[];
    airlines?: ICodename[];
}) => {
    const { isUserBookingDetailRefunded } = useAddMoreBookingServiceContext();
    const isRefunded = isUserBookingDetailRefunded(user.id, detail.id);
    const getErrorByFieldName = (field: keyof ITransferBookingDetailViewModel | "arrivalPlace" | "departPlace" | "airline") => {
        return error?.[`${data._id}.${field}`];
    }
    const airlineCodenamesByCode = useMemo(() => {
        return airlines.reduce((current, airline) => {
            current[airline.code] = airline;
            return current;
        }, { } as Record<string, ICodename>);
    }, [airlines])
    const { checked } = data ?? { checked: false};
    const { departPlace, arrivalPlace } = BookingHelpers.getFlightExtraInfo(detail, user.id);
    const handleChecked = (value: boolean) => {
        if (data) {
            // update
            onChange({
                ...data,
                checked: value
            }, "checked");
        } else {
            // init
            onChange({
                _id: data?._id ?? Helpers.generateRandomId(),
                checked: true,
                userId: user.id,
                bookingDetailId: detail.id,
                bookingId: detail.bookingId,
                baggage: undefined,
                cabinClass: cabinClasses[0]?.code as CabinClass,
                arrivalPlaceObj: undefined,
                airlineObj: undefined,
                departPlaceObj: undefined,
                bookingDetail: detail,
                departTime: undefined,
                arrivalTime: undefined,
                reservationCode: undefined,
                seatNumber: undefined,
                ticketNumber: undefined,
            }, "checked");
        }
    }

    const [depart, setDepart] = useState("");
    const [arrival, setArrival] = useState("");
    const getDataApi = useCallback(async (request: any) => {
        request.pageSize = 10;
        const result = await transportHubService.getPaged(request);
        const departPlaceObj = data?.departPlaceObj;
        const arrivalPlaceObj = data?.arrivalPlaceObj;

        const items = [...result?.items || []]?.map((item: ITransportHubServiceItem) => ({
            code: item.code,
            name: item.name,
            detail: item,
        } as ICodename));
        const distinctItems = items.filter(i => {
            return i.code !== arrivalPlaceObj?.code && i.code !== departPlaceObj?.code;
        })

        return {
            currentPage: result?.currentPage || 0,
            hasNext: false,
            data: distinctItems,
        };
    }, [data?.arrivalPlaceObj, data?.departPlaceObj]) 

    const placeWrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (placeWrapperRef.current) {
            const inputs = placeWrapperRef.current.querySelectorAll(".place-input input");
            inputs.forEach(input => {
                input.setAttribute("autocomplete", "off");
            });
        }
    }, [])

    return (
        <DeeptechAccordion
            data={user}
            expanded={checked}
            expandIcon={null}
            noBorder
            px={0}
            renderTitle={() => {
                return (
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        position="relative"
                        sx={{
                            cursor: isRefunded ? "default" : undefined,
                        }}
                    >
                        {/* dummy checkbox */}
                        <CheckBox checked={checked} disabled={isRefunded || isLoadingServiceFee} />
                        {!(isRefunded || isLoadingServiceFee) && (
                            <CheckBox
                                checked={checked}
                                disabled={isRefunded || isLoadingServiceFee}
                                onChange={(e) => handleChecked(e.target.checked)}
                                style={{
                                    width: "100%",
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    opacity: 0,
                                    height: "100%",
                                    zIndex: 1,
                                }}
                            />
                        )}
                        &nbsp;
                        <Typography variant="button" fontWeight="bold">
                            {Strings.BOOKING.FLIGHT} {departPlace}&nbsp;&rarr;&nbsp;{arrivalPlace}
                        </Typography>
                        {isRefunded && (
                            <Box ml={3}>
                                <Chip label={Strings.BOOKING.REFUND_POPUP_REFUNDED_CHIP_LABEL} color="primary" size={"small"} />
                            </Box>
                        )}
                    </Box>
                );
            }}
            renderContent={() => {
                return (
                    <Box
                        display="grid"
                        sx={{
                            gridTemplateColumns: "repeat(2, 1fr)",
                        }}
                        gap={3}
                        ml={5}
                        ref={placeWrapperRef}
                    >
                        <div className="place-input">
                            <SearchInputAPI
                                label={Strings.BOOKING.DEPARTURE}
                                maxLength={255}
                                isAutocompleteMode
                                value={depart || ""}
                                dataOptionInit={[]}
                                errorMessage={getErrorByFieldName("departPlace")}
                                placeholder={Strings.BOOKING.TRANSFER_POPUP_SELECT_DEPARTURE}
                                requestGetApiDefault={{
                                    searchText: depart,
                                    type: 1,
                                    pageNumber: 1,
                                }}
                                debounceTime={500}
                                getApi={getDataApi}
                                onClick={(value: ICodename) => {
                                    onChange({
                                        ...data,
                                        departPlaceObj: {
                                            code: value.code,
                                            name: value.name,
                                            detail: value.detail,
                                        }
                                    }, "departPlaceObj")
                                    setDepart(value.name);
                                }}
                                onChangeValue={(value: string) => {
                                    if (data?.departPlaceObj?.code) {
                                        onChange({ ...data, departPlaceObj: {
                                            code: undefined,
                                            name: undefined,
                                            detail: undefined,
                                        } }, "arrivalPlaceObj");
                                    }
                                    setDepart(value);
                                }}
                            />
                        </div>
                        <div className="place-input">
                            <SearchInputAPI
                                label={Strings.BOOKING.ARRIVAL}
                                maxLength={255}
                                isAutocompleteMode
                                value={arrival || ""}
                                dataOptionInit={[]}
                                errorMessage={getErrorByFieldName("arrivalPlace")}
                                placeholder={Strings.BOOKING.TRANSFER_POPUP_SELECT_ARRIVAL}
                                requestGetApiDefault={{
                                    searchText: arrival,
                                    type: 1,
                                    pageNumber: 1,
                                }}
                                debounceTime={500}
                                getApi={getDataApi}
                                onClick={(value: ICodename) => {
                                    onChange({
                                        ...data,
                                        arrivalPlaceObj: {
                                            code: value.code,
                                            name: value.name,
                                            detail: value.detail,
                                        }
                                    }, "arrivalPlaceObj")
                                    setArrival(value.name);
                                }}
                                onChangeValue={(value: string) => {
                                    if (data?.arrivalPlaceObj?.code) {
                                        onChange({ ...data, arrivalPlaceObj: {
                                            code: undefined,
                                            name: undefined,
                                        } }, "arrivalPlaceObj");
                                    }
                                    setArrival(value);
                                }}
                            />
                        </div>
                        <DateTimePicker
                            required
                            label={Strings.BOOKING.TRANSFER_POPUP_DEPARTURE_TIME}
                            value={Helpers.getValueDateTime(data?.departTime, true)}
                            placeholder="hh:mm DD/MM/YYYY"
                            onChangeValue={(value: any) => {
                                let departTimeUnixValue = moment(value).unix();
                                if (value && data.arrivalTime && value > data.arrivalTime) {
                                    onChange({ ...data, departTime: departTimeUnixValue || undefined, arrivalTime: moment(value).add(30, "minutes").unix() || undefined }, "departTime")
                                } else {
                                    onChange({ ...data, departTime: !!value ? departTimeUnixValue : 0 }, "departTime")
                                }
                            }}
                            errorMessage={getErrorByFieldName("departTime")}
                            options={{
                                allowInput: true,
                                minDate: "today",
                                dateFormat: "H:i d/m/Y",
                            }}
                        />
                        <DateTimePicker
                            required
                            value={Helpers.getValueDateTime(data?.arrivalTime, true)}
                            label={Strings.BOOKING.TRANSFER_POPUP_ARRIVAL_TIME}
                            placeholder="hh:mm DD/MM/YYYY"
                            onChangeValue={(value: any) => onChange({ ...data, arrivalTime: !!value ? moment(value).unix() : 0 }, "arrivalTime")}
                            errorMessage={getErrorByFieldName("arrivalTime")}
                            options={{
                                allowInput: true,
                                minDate: data?.departTime ? moment(data?.departTime * 1000).toDate() : "today",
                                dateFormat: "H:i d/m/Y",
                            }}
                        />
                        <Box sx={{
                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                height: 45,
                            },
                            "& .MuiInputBase-root.MuiOutlinedInput-root input": {
                                paddingTop: "5px",
                            },
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                borderColor: getErrorByFieldName("airline") ? "error.main" : undefined,
                            },
                        }}>
                            <Autocomplete
                                data={airlines}
                                label={Strings.BOOKING.TRANSFER_POPUP_CARRIER_FULL_TEXT}
                                placeholder={Strings.BOOKING.TRANSFER_POPUP_CARRIER_FULL_TEXT_PLACEHOLDER}
                                variant="outlined"
                                required
                                defaultValue={data?.airlineObj?.code || ""}
                                errorMessage={getErrorByFieldName("airline")}
                                onChange={(value: string) => {
                                    onChange({ ...data, airlineObj: airlineCodenamesByCode[value] }, "airlineObj");
                                }}
                            />
                        </Box>
                        <FormField
                            autoComplete="off"
                            required
                            sx={{
                                "& fieldset.MuiOutlinedInput-notchedOutline": {
                                    borderColor: getErrorByFieldName("reservationCode") ? "error.main" : undefined,
                                }
                            }}
                            label={Strings.BOOKING.RESERVATION_CODE}
                            placeholder={Strings.BOOKING.TRANSFER_POPUP_RESERVATION_CODE_PLACEHOLDER}
                            variant="outlined"
                            error={!!getErrorByFieldName("reservationCode")}
                            errorMessage={getErrorByFieldName("reservationCode")}
                            onBlur={(value: string) => onChange({ ...data, reservationCode: value || undefined}, "reservationCode")}
                            defaultValue={data?.reservationCode}
                        />
                        <OutlinedSelectBox
                            label={Strings.BOOKING.TRANSFER_POPUP_CABIN_CLASS}
                            variant="outlined"
                            data={cabinClasses}
                            placeholder={Strings.BOOKING.TRANSFER_POPUP_CABIN_CLASS_PLACEHOLDER}
                            error={!!getErrorByFieldName("cabinClass")}
                            errorMessage={getErrorByFieldName("cabinClass")}
                            onChangeValue={(value: any) => onChange({ ...data, cabinClass: value as CabinClass }, "cabinClass")}
                            initialValue={data?.cabinClass}
                        />
                        <FormField
                            autoComplete="off"
                            label={Strings.BOOKING.TRANSFER_POPUP_TICKET_NUMBER}
                            variant="outlined"
                            defaultValue={data?.ticketNumber}
                            placeholder={Strings.BOOKING.TRANSFER_POPUP_TICKET_NUMBER_PLACEHOLDER}
                            error={!!getErrorByFieldName("ticketNumber")}
                            errorMessage={getErrorByFieldName("ticketNumber")}
                            onBlur={(value: string) => onChange({ ...data, ticketNumber: value || undefined }, "ticketNumber")}
                        />
                        <FormField
                            autoComplete="off"
                            label={Strings.BOOKING.BAGGAGE}
                            required
                            sx={{
                                "& fieldset.MuiOutlinedInput-notchedOutline": {
                                    borderColor: getErrorByFieldName("baggage") ? "error.main" : undefined,
                                }
                            }}
                            placeholder={Strings.BOOKING.TRANSFER_POPUP_INPUT_FIELD_PLACEHOLDER}
                            variant="outlined"
                            defaultValue={data?.baggage}
                            error={!!getErrorByFieldName("baggage")}
                            errorMessage={getErrorByFieldName("baggage")}
                            onBlur={(value: any) => onChange({ ...data, baggage: value || undefined }, "baggage")}
                            multiline
                        />
                        <FormField
                            autoComplete="off"
                            label={Strings.BOOKING.SEAT_NUMBER}
                            variant="outlined"
                            error={!!getErrorByFieldName("seatNumber")}
                            errorMessage={getErrorByFieldName("seatNumber")}
                            defaultValue={data?.seatNumber}
                            placeholder={Strings.BOOKING.TRANSFER_POPUP_INPUT_FIELD_PLACEHOLDER}
                            onBlur={(value: any) => onChange({ ...data, seatNumber: value || undefined }, "seatNumber")}
                        />
                    </Box>
                );
            }}
        />
    );
});

export default TransferFlightItem;